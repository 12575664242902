import type { GetCompetitionsQuery, GetCompetitionsResponse } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

const GET_COMPETITIONS_LIMIT = 50

function getCompetitions(query: GetCompetitionsQuery): Promise<GetCompetitionsResponse> {
  const tokens = getTokens()
  const url = new URL(`${import.meta.env.VITE_API_URL}admin/competitions`)

  if (query) {
    Object.entries(query).forEach(([key, value]) => url.searchParams.append(key, value.toString()))
  }

  return fetch(url.toString(), {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const competitionsQuery = (queryParams: GetCompetitionsQuery) => ({
  queryKey: ['competitions', queryParams],
  queryFn: ({ pageParam = 0 }: { pageParam?: number }) =>
    getCompetitions({
      limit: GET_COMPETITIONS_LIMIT.toString(),
      offset: pageParam.toString(),
      ...queryParams,
    }),
  getNextPageParam: (lastPage: GetCompetitionsResponse, pages: GetCompetitionsResponse[]) => {
    if (lastPage.data.length < GET_COMPETITIONS_LIMIT) return
    return pages.length * GET_COMPETITIONS_LIMIT
  },
})
