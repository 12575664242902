import { Box, Flex, Spinner, Text } from '@chakra-ui/react'

export const Authenticating = () => {
  return (
    <Flex direction="column" alignItems="center" justifyContent="center" h="100vh" bg="primary.800">
      <Spinner thickness="4px" speed="0.75s" emptyColor="primary.700" color="blue.500" size="xl" />
      <Box mt={4}>
        <Text color="white" fontWeight="bold" fontSize="2xl" textAlign="center">
          Authenticating
        </Text>
        <Text color="white" fontSize="lg" textAlign="center">
          User Credentials...
        </Text>
      </Box>
    </Flex>
  )
}
