import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Code,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { QueryClient } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { isNil, reverse, upperFirst } from 'lodash'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { useLoaderData, useParams, useRevalidator, useSearchParams } from 'react-router-dom'
import useInterval from 'use-interval'

import { ballInningsQuery } from '../../api/get-ball-innings'
import { matchCentreMatchQuery } from '../../api/get-match-centre-match'
import { BallPanel } from '../../components/ball-panel'
import { DownloadsPanel } from '../../components/downloads'
import { LivePanel } from '../../components/live-panel'
import MatchConditionsPanel from '../../components/match-conditions'
import { MatchConnections } from '../../components/match-connections'
import { MatchFeeds } from '../../components/match-feeds'
import { MatchNotes } from '../../components/match-notes'
import { ModeHeading } from '../../components/mode-heading'
import { ScoreBox } from '../../components/score-box'
import { Scorebook } from '../../components/scorebook'
import { getTokens } from '../../helpers/auth'
import { confirmedBallsGrouped } from '../../helpers/ball'
import { idCleaner } from '../../helpers/data'
import { getInningsBattingTeamShortName } from '../../helpers/inning'
import {
  getActiveInning,
  getAllInningsInOrder,
  getAwayTeam,
  getHomeTeam,
  getInningByMatchOrder,
} from '../../helpers/match'
import { CoverageLevels } from '../../reference'
import { theme } from '../../theme'
import type { FeedSubscription, FeedType, MatchConnection, SubscriberInfo } from '../../types'
import type { Ball } from '../../types/ball'
import type { MatchDls } from '../../types/match'
import { NonIdealState } from '../../ui/non-ideal-state'

const tokens = getTokens()

export const loader =
  (queryClient: QueryClient) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    const bypassLookup = new URL(request.url).searchParams.get('bypassLookup') === 'true'

    const [mainModeData, bettingModeData, mainModeBalls, bettingModeBalls] = await Promise.all([
      queryClient.ensureQueryData(matchCentreMatchQuery({ id: params.id || '', mode: 'main', bypassLookup })),
      queryClient.ensureQueryData(matchCentreMatchQuery({ id: params.id || '', mode: 'betting', bypassLookup })),
      queryClient.ensureQueryData(ballInningsQuery({ id: params.id || '', mode: 'main' })),
      queryClient.ensureQueryData(ballInningsQuery({ id: params.id || '', mode: 'betting' })),
    ])

    return {
      mainModeData,
      bettingModeData,
      mainModeBalls,
      bettingModeBalls,
    }
  }

export type MatchLoaderData = Awaited<ReturnType<ReturnType<typeof loader>>>

const Game = observer(() => {
  const { id } = useParams()
  const [connectionData, setConnectionData] = useState<MatchConnection>()
  const [feedData, setFeedData] = useState<SubscriberInfo[]>([])
  const toast = useToast()
  const [isSending, setIsSending] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [activeTab, setActiveTab] = useState(1)
  const [ballJSON, setBallJSON] = useState('')
  const [counter, setCounter] = useState(0)
  const [autoUpdate, setAutoUpdate] = useState(
    !!(import.meta.env.VITE_ENV_AUTO_UPDATE && import.meta.env.VITE_ENV_AUTO_UPDATE !== 'false')
  )
  const [showBettingData, setShowBettingData] = useState(false)
  const { mainModeData, bettingModeData, mainModeBalls, bettingModeBalls } = useLoaderData() as MatchLoaderData

  const [searchParams, setSearchParams] = useSearchParams()
  const params = Object.fromEntries(searchParams)
  const bypassLookup = params.bypassLookup === 'true'

  const revalidator = useRevalidator()

  const { data: game } = useQuery({
    ...matchCentreMatchQuery({ id: id || '', mode: 'main', bypassLookup }),
    initialData: mainModeData,
    refetchInterval: autoUpdate ? 10000 : false,
    refetchIntervalInBackground: true,
  })
  const { data: gameBetting } = useQuery({
    ...matchCentreMatchQuery({ id: id || '', mode: 'betting', bypassLookup }),
    initialData: bettingModeData,
    refetchInterval: autoUpdate ? 10000 : false,
    refetchIntervalInBackground: true,
  })
  const { data: ballInnings } = useQuery({
    ...ballInningsQuery({ id: id || '', mode: 'main' }),
    initialData: mainModeBalls,
    enabled: mainModeData.matchConfigs?.coverageLevelId !== 0,
    refetchInterval: autoUpdate ? 10000 : false,
    refetchIntervalInBackground: true,
  })
  const { data: ballInningsBetting } = useQuery({
    ...ballInningsQuery({ id: id || '', mode: 'betting' }),
    initialData: bettingModeBalls,
    enabled: mainModeData.matchConfigs?.coverageLevelId !== 0,
    refetchInterval: autoUpdate ? 10000 : false,
    refetchIntervalInBackground: true,
  })

  const selectedInning = getInningByMatchOrder(game.matchTeams, activeTab)
  const selectedInningBetting = getInningByMatchOrder(gameBetting.matchTeams, activeTab)

  const liveInning = getActiveInning(game)
  const liveInningBetting = getActiveInning(gameBetting)

  const balls =
    ballInnings && ballInnings.find
      ? confirmedBallsGrouped(ballInnings.find(inning => inning.inningsId === selectedInning?.id)?.balls)
      : undefined

  const ballsBetting =
    ballInningsBetting && ballInningsBetting.find
      ? confirmedBallsGrouped(ballInningsBetting.find(inning => inning.inningsId === selectedInningBetting?.id)?.balls)
      : undefined

  const activeInningBalls =
    ballInnings && ballInnings.find
      ? confirmedBallsGrouped(ballInnings.find(inning => inning.inningsId === liveInning?.id)?.balls)
      : undefined

  const activeInningBallsBetting =
    ballInningsBetting && ballInningsBetting.find
      ? confirmedBallsGrouped(ballInningsBetting.find(inning => inning.inningsId === liveInningBetting?.id)?.balls)
      : undefined

  const isCoreCoverageLevel = game.matchConfigs?.coverageLevelId === 1

  useEffect(() => {
    // check coverage level
    if (!isNil(game.matchConfigs?.coverageLevelId) && game.matchConfigs?.coverageLevelId === 1) {
      setShowBettingData(true)
    }

    // update page title
    const activeInning = isCoreCoverageLevel && gameBetting ? getActiveInning(gameBetting) : getActiveInning(game)
    if (activeInning) {
      const battingTeam = game.matchTeams?.find(team => team.id === activeInning.battingTeamId)
      document.title = `${battingTeam?.shortName || 'Unknown team'}
       ${activeInning.progressiveScores.runs}/${activeInning.progressiveScores.wickets}
        (${activeInning.progressiveScores.oversBowled})`
    } else {
      document.title = `${getHomeTeam(game.matchTeams)?.name} vs ${getAwayTeam(game.matchTeams)?.name}`
    }
  }, [game, gameBetting, isCoreCoverageLevel])

  useInterval(() => {
    async function fetchConnectionData(id: string) {
      const url = `${import.meta.env.VITE_API_URL}matchConnections/${id}`
      const response = await fetch(url, { headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
      if (response && response.ok) {
        const data = await response.json()
        setConnectionData(data)
      }
    }
    async function fetchFeedData(gameId: string, compId: string) {
      const compUrl = `${import.meta.env.VITE_API_URL}feedsubscription/${compId}`
      const gameUrl = `${import.meta.env.VITE_API_URL}feedsubscription/${gameId}`
      let feedDataShell: SubscriberInfo[] = []
      try {
        const compResponse = await fetch(compUrl, { headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
        if (compResponse && compResponse.ok) {
          const data: FeedSubscription[] = await compResponse.json()
          if (data.length) {
            feedDataShell = data.map(item => {
              return {
                subscriberId: item.subscriberId,
                subscriberName: item.subscriberName || 'No name',
                feedTypes: item.supportFeedTypes || ['No Feeds'],
                compSubscribed: true,
                matchSubscribed: false,
              }
            })
          }
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
      }
      try {
        const gameResponse = await fetch(gameUrl, { headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
        if (gameResponse && gameResponse.ok) {
          const data: FeedSubscription[] = await gameResponse.json()
          if (data.length) {
            data.forEach(item => {
              // check if comp already added subscriber
              const subscriber = feedDataShell.findIndex(sub => sub.subscriberId === item.subscriberId)
              if (subscriber !== -1) {
                feedDataShell[subscriber] = { ...feedDataShell[subscriber], matchSubscribed: true }
              } else {
                feedDataShell.push({
                  subscriberId: item.subscriberId,
                  subscriberName: item.subscriberName || 'No name',
                  feedTypes: item.supportFeedTypes || ['No Feeds'],
                  compSubscribed: false,
                  matchSubscribed: true,
                })
              }
            })
          }
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
      }
      setFeedData(feedDataShell)
    }

    if (autoUpdate && id) {
      fetchConnectionData(id)
      if (game.competitionStage?.competition?.id) {
        fetchFeedData(id, game.competitionStage.competition.id)
      }
    }

    revalidator.revalidate()
  }, 10000)

  useInterval(() => {
    if (autoUpdate) {
      if (counter !== 100) {
        setCounter(counter + 25)
      } else {
        setCounter(0)
      }
    }
  }, 2000)

  const ballKeys = balls ? reverse(Object.keys(balls).map(key => parseInt(key))) : []
  const ballKeysBetting = ballsBetting ? reverse(Object.keys(ballsBetting).map(key => parseInt(key))) : []

  const activeInningBallKeys = activeInningBalls
    ? reverse(Object.keys(activeInningBalls).map(key => parseInt(key)))
    : []
  const activeInningBallKeysBetting = activeInningBallsBetting
    ? reverse(Object.keys(activeInningBallsBetting).map(key => parseInt(key)))
    : []

  const setModalAndBall = (data: Ball | MatchDls | null) => {
    const ball = toJS(idCleaner([data], 'BALL'))
    setBallJSON(JSON.stringify(ball[0], null, 2))
    onOpen()
  }

  const handleAutoUpdateSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    setAutoUpdate(event.target.checked)
    if (!event.target.checked) {
      setCounter(0)
    }
  }

  const setBypassLookup = (enabled: boolean) => {
    const params = Object.fromEntries(searchParams)
    if (enabled) {
      params.bypassLookup = 'true'
    } else {
      delete params.bypassLookup
    }
    setSearchParams(params)
  }

  const handleSkipCacheSwitch = (event: ChangeEvent<HTMLInputElement>) => setBypassLookup(event.target.checked)

  const handleBettingDataSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    setShowBettingData(event.target.checked)
    if (event.target.checked && bypassLookup) setBypassLookup(false)
  }

  const sendFeedToast = (type: FeedType, status: 'success' | 'error') => {
    toast({
      title: `${upperFirst(type)} feed ${status === 'success' ? 'sent successfully' : 'send failed'}`,
      status: status,
      duration: 3000,
    })
  }
  const sendFeed = async (type: FeedType, ballId?: string) => {
    setIsSending(true)
    const tokens = getTokens()
    const gameId = game.id.split('_')[0]
    let url = `${import.meta.env.VITE_API_URL}feed/match/${gameId}`
    if (type === 'match' && bypassLookup) {
      url = `${url}?bypasscache=true`
    }
    if (type === 'allBall') {
      url = `${url}/ball`
    }
    if (type === 'ball' && ballId) {
      url = `${url}/ball/${ballId}`
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { Authorization: `Bearer ${tokens?.accessToken}` },
      })
      if (response) {
        setIsSending(false)
        sendFeedToast(type, response.ok ? 'success' : 'error')
      }
    } catch (error) {
      console.warn('Error sending Feed', error) // eslint-disable-line no-console
      setIsSending(false)
      sendFeedToast(type, 'error')
    }
  }

  if (!id) {
    return (
      <NonIdealState
        title="No match ID provided"
        description="Please provide a match ID in the URL"
        iconColor="red.600"
        icon={['fad', 'triangle-exclamation']}
      />
    )
  }

  if (!game) {
    return (
      <NonIdealState
        title="No match found"
        description="We couldn't find a match with this ID:"
        iconColor="red.600"
        icon={['fad', 'triangle-exclamation']}
      >
        <Code mt={4} px={6} py={4} borderRadius="6px" bg="primary.600" color="white">
          {id}
        </Code>
      </NonIdealState>
    )
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>JSON Payload</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box background="primary.600" my={4} p={4} borderRadius="6px">
              <Text as="pre" fontSize="12px" whiteSpace="pre-wrap">
                {ballJSON}
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex direction="column" w="100%">
        <Stack direction="row" w="100%" spacing={3}>
          {!isNil(game.matchConfigs) && !isNil(game.matchConfigs.coverageLevelId) && (
            <Flex
              direction={{ base: 'column', xl: 'row' }}
              alignItems={{ base: 'flex-start', xl: 'center' }}
              justifyContent={{ base: 'initial', xl: 'space-between' }}
              borderRadius="6px"
              px={4}
              py={3}
              bg="primary.600"
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Icon as={FontAwesomeIcon} icon={['fas', 'layer-group']} />
                <Text fontSize="xs" fontWeight="bold" color="white">
                  {Object.keys(CoverageLevels)[game.matchConfigs.coverageLevelId]}
                </Text>
              </Stack>
            </Flex>
          )}
          <Flex
            flex={1}
            direction={{ base: 'column', xl: 'row' }}
            alignItems={{ base: 'flex-start', xl: 'center' }}
            justifyContent={{ base: 'initial', xl: 'space-between' }}
            borderRadius="6px"
            px={4}
            py={3}
            bg="primary.600"
          >
            <Stack direction="row" spacing={8} mr={{ base: 0, xl: 8 }} mb={{ base: 4, xl: 0 }}>
              <Stack direction="row" flex={1} alignItems="center" spacing={3}>
                <Text color="white">Auto Update</Text>
                <Switch variant="primary" onChange={handleAutoUpdateSwitch} isChecked={autoUpdate} />
                <CircularProgress value={counter} color="green.400" size="40px" thickness="15px" />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Text color="white">Data direct from DB</Text>
                <Switch variant="primary" onChange={handleSkipCacheSwitch} isChecked={bypassLookup} />
              </Stack>
              <Stack direction="column" justify="center" align="flex-start" spacing={1}>
                <Stack direction="row" alignItems="center" spacing={3}>
                  <Text
                    color={
                      !isNil(game.matchConfigs) &&
                      !isNil(game.matchConfigs.coverageLevelId) &&
                      game.matchConfigs.coverageLevelId <= 1
                        ? theme.colors.primary['500']
                        : 'white'
                    }
                  >
                    Show betting data
                  </Text>
                  <Switch
                    variant="primary"
                    onChange={handleBettingDataSwitch}
                    isChecked={showBettingData}
                    isDisabled={
                      !isNil(game.matchConfigs) &&
                      !isNil(game.matchConfigs.coverageLevelId) &&
                      game.matchConfigs?.coverageLevelId <= 1
                    }
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Button variant="primary" onClick={() => sendFeed('match')} isDisabled={isSending}>
                Send Match Feed
              </Button>
              <Button variant="primary" onClick={() => sendFeed('allBall')} isDisabled={isSending}>
                Send All Ball Feed
              </Button>
            </Stack>
          </Flex>
        </Stack>
        <Stack direction="row" spacing={6} mt={6}>
          <Flex flex={1} bg="primary.600" px={4} py={3} borderRadius="6px">
            <MatchConnections connections={connectionData} />
          </Flex>
          <Flex flex={1} bg="primary.600" px={4} py={3} borderRadius="6px">
            <MatchFeeds subscribers={feedData} />
          </Flex>
        </Stack>
        <ScoreBox game={isCoreCoverageLevel && gameBetting ? gameBetting : game} />
        <Tabs isFitted variant="enclosed" mt={6}>
          <TabList background="primary.500" borderTopLeftRadius="6px" borderTopRightRadius="6px">
            {liveInning && <Tab _selected={{ bg: 'green.400' }}>Live</Tab>}
            <Tab _selected={{ bg: 'green.400' }}>Scorecard</Tab>
            <Tab _selected={{ bg: 'green.400' }}>Ball-by-ball</Tab>
            <Tab _selected={{ bg: 'green.400' }}>Conditions</Tab>
            <Tab _selected={{ bg: 'green.400' }}>Downloads</Tab>
          </TabList>
          <TabPanels
            p={4}
            border="1px solid"
            borderColor="primary.500"
            borderBottomLeftRadius="6px"
            borderBottomRightRadius="6px"
          >
            {/** live */}
            {liveInning && (
              <TabPanel p={0}>
                <Stack direction={{ base: 'column', lg: 'row' }} spacing={4}>
                  {!isCoreCoverageLevel && (
                    <Flex
                      w={{ base: '100%', lg: showBettingData ? 'calc(50% - 20px)' : '100%' }}
                      flex={1}
                      direction="column"
                    >
                      {showBettingData && <ModeHeading mode="main" />}
                      <LivePanel
                        activeInning={liveInning}
                        balls={activeInningBalls}
                        ballKeys={activeInningBallKeys}
                        condensed={showBettingData && !isCoreCoverageLevel}
                      />
                    </Flex>
                  )}
                  {showBettingData && liveInningBetting && (
                    <Flex
                      w={{ base: '100%', lg: showBettingData ? 'calc(50% - 20px)' : '100%' }}
                      flex={1}
                      direction="column"
                    >
                      {!isCoreCoverageLevel && <ModeHeading mode="betting" />}
                      <LivePanel
                        activeInning={liveInningBetting}
                        balls={activeInningBallsBetting}
                        ballKeys={activeInningBallKeysBetting}
                        condensed={showBettingData && !isCoreCoverageLevel}
                      />
                    </Flex>
                  )}
                </Stack>
              </TabPanel>
            )}
            {/** scorecard */}
            <TabPanel p={0}>
              <Flex w="100%" justifyContent="center" alignItems="center">
                {/** Overs preview */}
                <ButtonGroup size="sm" isAttached>
                  {getAllInningsInOrder(isCoreCoverageLevel ? gameBetting : game)?.map(inning => {
                    return (
                      <Button
                        key={inning.inningsMatchOrder}
                        mr="-px"
                        fontSize="16px"
                        paddingX="30px"
                        isActive={inning.inningsMatchOrder === activeTab}
                        onClick={() => setActiveTab(inning.inningsMatchOrder)}
                        _active={{
                          bg: 'green.400',
                          color: 'white',
                        }}
                      >
                        {getInningsBattingTeamShortName(
                          inning,
                          isCoreCoverageLevel ? gameBetting.matchTeams : game.matchTeams
                        )}{' '}
                        {inning.progressiveScores.runs}/{inning.progressiveScores.wickets}
                      </Button>
                    )
                  })}
                </ButtonGroup>
              </Flex>
              <Flex direction="column" mt={4}>
                <Stack direction="row" spacing={6}>
                  {!isCoreCoverageLevel && (
                    <Flex flex={1} direction="column">
                      {showBettingData && <ModeHeading mode="main" />}
                      <Scorebook
                        game={game}
                        selectedInning={selectedInning}
                        setModalAndBall={setModalAndBall}
                        condensed={showBettingData && !isCoreCoverageLevel}
                      />
                    </Flex>
                  )}
                  {showBettingData && gameBetting && (
                    <Flex flex={1} w="calc(50% - 10px)" direction="column" mx="5px">
                      {!isCoreCoverageLevel && <ModeHeading mode="betting" />}
                      <Scorebook
                        game={gameBetting}
                        selectedInning={selectedInningBetting}
                        setModalAndBall={setModalAndBall}
                        condensed={showBettingData && !isCoreCoverageLevel}
                        showBettingData={showBettingData}
                      />
                    </Flex>
                  )}
                </Stack>
                {game.matchNotes && <MatchNotes notes={game.matchNotes} />}
              </Flex>
            </TabPanel>
            {/** Ball-by-ball */}
            <TabPanel p={0}>
              <BallPanel
                activeInning={selectedInning}
                activeTab={activeTab}
                balls={balls}
                ballKeys={ballKeys}
                ballsBetting={ballsBetting}
                ballKeysBetting={ballKeysBetting}
                game={game}
                setActiveTab={setActiveTab}
                setModalAndBall={setModalAndBall}
                sendFeed={sendFeed}
                isSending={isSending}
                showBettingData={showBettingData}
                isCoreCoverageLevel={isCoreCoverageLevel}
              />
            </TabPanel>
            <TabPanel p={0}>
              <Stack direction="row" spacing={4}>
                {!isCoreCoverageLevel && (
                  <Flex flex={1} direction="column">
                    {showBettingData && <ModeHeading mode="main" />}
                    <MatchConditionsPanel game={game} />
                  </Flex>
                )}
                {showBettingData && gameBetting && (
                  <Flex flex={1} direction="column">
                    {!isCoreCoverageLevel && <ModeHeading mode="betting" />}
                    <MatchConditionsPanel game={gameBetting} />
                  </Flex>
                )}
              </Stack>
            </TabPanel>
            <TabPanel p={0}>
              <DownloadsPanel
                gameId={id}
                compId={game.competitionStage?.competition?.id}
                isCoreCoverageLevel={isCoreCoverageLevel}
                showBettingData={showBettingData}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </>
  )
})

export default Game
