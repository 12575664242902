import { Flex, Heading, Stack } from '@chakra-ui/react'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  BoundaryOptions,
  OutfieldSpeedOptions,
  PitchGrassOptions,
  PitchMoistureOptions,
  PitchQualityOptions,
  WeatherAtmosOptions,
  WeatherRainOptions,
  WeatherTemperatureOptions,
  WeatherWindOptions,
} from '../../reference'
import type { MatchConditions } from '../../types/match'

type MatchConditionProps = {
  icon: IconProp
  label: string
  condition: string | undefined
}

const MatchCondition = ({ icon, label, condition }: MatchConditionProps) => {
  return !condition ? (
    <></>
  ) : (
    <Flex alignItems="center" justifyContent="center">
      {icon && <FontAwesomeIcon icon={icon} size="1x" style={{ marginLeft: '7px', marginRight: '7px' }} />}
      <Heading as="h4" size="sm" pr="2" fontWeight="bold" letterSpacing="-0.5px">
        {`${label}:`}
      </Heading>
      <Heading as="h4" size="sm" fontWeight="400" style={{ textTransform: 'capitalize' }}>
        {condition}
      </Heading>
    </Flex>
  )
}

type MatchConditionCardProps = {
  conditions: MatchConditions
}

export const MatchConditionCard = ({ conditions }: MatchConditionCardProps) => {
  return (
    <Stack direction="row" spacing={4}>
      <Stack spacing={6} flex={1} h="240px" pt={4} border="1px solid" borderColor="primary.600" borderRadius="6px">
        <Heading textAlign="center" px="2" pt="2" size="md">
          Weather Conditions
        </Heading>
        <Stack spacing={2} mt={6}>
          {conditions.rainTypeId !== null && (
            <MatchCondition
              condition={WeatherRainOptions[conditions.rainTypeId]}
              icon={['far', 'cloud-showers']}
              label="Rain"
            />
          )}
          {conditions.atmosTypeId !== null && (
            <MatchCondition
              label="Sky"
              condition={WeatherAtmosOptions[conditions.atmosTypeId]}
              icon={['far', 'sun-cloud']}
            />
          )}
          {conditions.temperatureTypeId !== null && (
            <MatchCondition
              label="Temperature"
              condition={WeatherTemperatureOptions[conditions.temperatureTypeId]}
              icon={['far', 'temperature-hot']}
            />
          )}
          {conditions.windTypeId !== null && (
            <MatchCondition condition={WeatherWindOptions[conditions.windTypeId]} label="Wind" icon={['far', 'wind']} />
          )}
        </Stack>
      </Stack>
      <Stack spacing={6} flex={1} h="240px" pt={4} border="1px solid" borderColor="primary.600" borderRadius="6px">
        <Heading textAlign="center" px="2" pt="2" size="md">
          Pitch Conditions
        </Heading>
        <Stack spacing={2}>
          {conditions.pitchQualityTypeId !== null && (
            <MatchCondition
              condition={PitchQualityOptions[conditions.pitchQualityTypeId]}
              icon={['far', 'ruler-triangle']}
              label="Quality / Bounce"
            />
          )}
          {conditions.boundaryTypeId !== null && (
            <MatchCondition
              condition={BoundaryOptions[conditions.boundaryTypeId]}
              icon={['far', 'expand-arrows-alt']}
              label="Boundary Set"
            />
          )}
          {conditions.outfieldSpeedTypeId !== null && (
            <MatchCondition
              condition={OutfieldSpeedOptions[conditions.outfieldSpeedTypeId]}
              label="Outfield Speed"
              icon={['far', 'tachometer-alt-fast']}
            />
          )}
          {conditions.pitchGrassTypeId !== null && (
            <MatchCondition
              condition={PitchGrassOptions[conditions.pitchGrassTypeId]}
              label="Grass Cover"
              icon={['far', 'seedling']}
            />
          )}
          {conditions.pitchMoistureTypeId !== null && (
            <MatchCondition
              label="Moisture"
              condition={PitchMoistureOptions[conditions.pitchMoistureTypeId]}
              icon={['far', 'tint']}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
