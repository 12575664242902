import { Box, Flex, Heading, Tag, TagLabel, Text, Tooltip } from '@chakra-ui/react'
import moment from 'moment-timezone'

import type { ConnectionData, MatchConnectionsProps } from '../../types'

const connectionToolTip = (connection: ConnectionData) => {
  const connectTime = new Date(connection.connectedAt)
  return `
    ${connectTime.toLocaleString('en-GB', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    })}
  `
}

const connectionBackground = (connection: ConnectionData) => {
  // calculate how far along this socket connection is being closed (2hrs from connected time)
  const totalDiff = 7200000 // 2 hrs in milliseconds
  const connectedTime = moment(connection.connectedAt)
  const disconnectTime = connectedTime.add(2, 'hours')
  const diff = disconnectTime.diff(moment())
  const percentageDiff = (diff / totalDiff) * 100
  // eslint-disable-next-line
  return `linear-gradient(90deg, rgb(56, 161, 105) 0%, rgba(56, 161, 105) ${100 - percentageDiff}%, rgb(47, 133, 90) ${
    100 - percentageDiff
  }%, rgba(47, 133, 90) 100%)`
}

export const MatchConnections = ({ connections }: MatchConnectionsProps) => {
  return (
    <Box width="100%">
      <Heading as="h3" size="md" textAlign="center">
        Connections
      </Heading>
      {connections?.connections?.main && connections?.connections?.main?.length > 0 && (
        <Flex align="center" mt={3}>
          <Text fontSize="md" mr={2}>
            Main:
          </Text>
          {connections?.connections?.main?.map(conn => (
            <Tag
              key={conn.connectionId}
              size="lg"
              background={conn.isPrimary ? connectionBackground(conn) : 'orange'}
              borderRadius="full"
              mr={2}
            >
              <Tooltip label={connectionToolTip(conn)} placement="top">
                <TagLabel fontSize="sm">{conn.email}</TagLabel>
              </Tooltip>
            </Tag>
          ))}
        </Flex>
      )}
      {connections?.connections?.fielding && connections?.connections?.fielding?.length > 0 && (
        <Flex align="center" mt={3}>
          <Text fontSize="md" mr={2}>
            Fielding:
          </Text>
          {connections?.connections?.fielding?.map(conn => (
            <Tag
              key={conn.connectionId}
              size="lg"
              background={conn.isPrimary ? connectionBackground(conn) : 'orange'}
              borderRadius="full"
              mr={2}
            >
              <TagLabel fontSize="sm">{conn.email}</TagLabel>
            </Tag>
          ))}
        </Flex>
      )}
      {connections?.connections?.betting && connections?.connections?.betting?.length > 0 && (
        <Flex align="center" mt={3}>
          <Text fontSize="md" mr={2}>
            Betting:
          </Text>
          {connections?.connections?.betting?.map(conn => (
            <Tag
              key={conn.connectionId}
              size="lg"
              background={conn.isPrimary ? connectionBackground(conn) : 'orange'}
              borderRadius="full"
              mr={2}
            >
              <TagLabel fontSize="sm">{conn.email}</TagLabel>
            </Tag>
          ))}
        </Flex>
      )}
      {connections?.connections?.trader && connections?.connections?.trader?.length > 0 && (
        <Flex align="center" mt={3}>
          <Text fontSize="md" mr={2}>
            Trader:
          </Text>
          {connections?.connections?.trader?.map(conn => (
            <Tag
              key={conn.connectionId}
              size="lg"
              background={conn.isPrimary ? connectionBackground(conn) : 'orange'}
              borderRadius="full"
              mr={2}
            >
              <TagLabel fontSize="sm">{conn.email}</TagLabel>
            </Tag>
          ))}
        </Flex>
      )}
    </Box>
  )
}
