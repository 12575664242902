import { Button, Flex, Heading, Icon, Link, List, ListIcon, ListItem, Stack } from '@chakra-ui/react'
import type { EntityReference, MatchReference } from '@clsplus/api-types/api-admin'
import { faLink } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { QueryClient } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Link as RouterLink, useLoaderData, useParams } from 'react-router-dom'

import { competitionStageDetailsQuery } from '../../../../api/get-competition-stage-details'
import { DetailValue } from '../../../../components/detail-value'
import { HasMCAccess, MCRequiredLevelEnum } from '../../../../helpers/auth'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    return await queryClient.ensureQueryData(
      competitionStageDetailsQuery(params.competitionId || '', params.stageId || '')
    )
  }

export default function CompetitionStageDetails() {
  const params = useParams()
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const { data: competitionStage } = useQuery({
    ...competitionStageDetailsQuery(params.competitionId || '', params.stageId || ''),
    initialData,
  })

  const generateStageSubLinks = (
    items: MatchReference[] | EntityReference[] | undefined,
    subLink: 'pool' | 'matches'
  ) => {
    const listItems = items?.map(item => {
      const subItem =
        subLink === 'matches'
          ? ({
              id: item.id,
              name: (item as MatchReference).title ?? item.id,
            } as EntityReference)
          : ({
              id: item.id,
              name: (item as EntityReference).name,
            } as EntityReference)

      const url =
        subLink === 'matches'
          ? `/matches/${subItem.id}`
          : `/competitions/${competitionStage.competition.id}/stage/${competitionStage.id}/${subLink}/${subItem.id}`

      const itemContents = (
        <Link fontWeight={'normal'} as={RouterLink} to={url} target={subLink === 'matches' ? '_blank' : undefined}>
          {subItem.name}
        </Link>
      )
      return (
        <ListItem key={subItem.id}>
          <ListIcon as={FontAwesomeIcon} icon={faLink} color="gray.400" />
          {itemContents}
        </ListItem>
      )
    })
    return <List>{listItems}</List>
  }

  return (
    <>
      <Flex w="100%" justifyContent="space-between" alignItems="baseline">
        <Heading as="h2" size="lg">
          {competitionStage.name}
        </Heading>

        <HasMCAccess minLevel={MCRequiredLevelEnum['clsp-superadmin']}>
          <Button
            as={RouterLink}
            to={`/competitions/${competitionStage.competition.id}/stage/${competitionStage.id}/edit`}
            variant="primary"
            minW={32}
            leftIcon={<Icon as={FontAwesomeIcon} icon={['fad', 'pen-to-square']} />}
          >
            Edit
          </Button>
        </HasMCAccess>
      </Flex>
      <Stack w="100%" mt={6} spacing={6}>
        <DetailValue label="CLS+ ID" value={competitionStage.competition.id} />
        <DetailValue label="Matches" value={generateStageSubLinks(competitionStage.matches, 'matches')} />
        <DetailValue label="Pools" value={generateStageSubLinks(competitionStage.competitionPools, 'pool')} />
      </Stack>
    </>
  )
}
