import type { EntityReference } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

function getOrganisationsRefData(): Promise<EntityReference[]> {
  const tokens = getTokens()

  return fetch(`${import.meta.env.VITE_API_URL}admin/reference-data/organisations`, {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const organisationsRefDataQuery = {
  queryKey: ['organisations-ref-data'],
  queryFn: () => getOrganisationsRefData(),
}
