import type { CompetitionPoolDetailed, UpdateCompetitionPoolRequest } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

export function updateCompetitionPoolDetails(
  competitionId: string,
  stageId: string,
  poolId: string,
  formData: UpdateCompetitionPoolRequest
): Promise<CompetitionPoolDetailed> {
  const tokens = getTokens()

  return fetch(
    `${
      import.meta.env.VITE_API_URL
    }admin/competitions/${competitionId}/competition-stages/${stageId}/competition-pools/${poolId}`,
    {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${tokens?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    }
  )
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}
