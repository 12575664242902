import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import type { MatchDetailed } from '@clsplus/api-types/api-admin'

import { applyTimeZoneOffset, formatFullDate, subtractUserOffset } from '../../helpers/datetime'

type MatchDatesTableProps = {
  dates: NonNullable<MatchDetailed['dates']>
  timeZone: string | undefined
}

export function MatchDatesTable({ dates, timeZone }: MatchDatesTableProps) {
  const matchLocalDates = dates.map(date => ({
    ...date,
    startDateTime: applyTimeZoneOffset({
      date: subtractUserOffset(date.startDateTime),
      currentTimeZone: undefined,
      newTimeZone: timeZone,
    }),
    endDateTime: date.endDateTime
      ? applyTimeZoneOffset({
          date: subtractUserOffset(date.endDateTime),
          currentTimeZone: undefined,
          newTimeZone: timeZone,
        })
      : null,
  }))

  return (
    <Box display="inline-block" py={2} border="1px solid" borderColor="primary.600" borderRadius="6px">
      <Table variant="unstyled">
        <Thead>
          <Tr>
            <Th>Day number</Th>
            <Th>Start date/time</Th>
            <Th>End date/time</Th>
          </Tr>
        </Thead>
        <Tbody>
          {matchLocalDates
            .sort((a, b) => {
              if (a.startDateTime < b.startDateTime) return -1
              if (a.startDateTime > b.startDateTime) return 1
              return 0
            })
            .map(date => (
              <Tr key={date.id}>
                <Td>{date.matchDayNum}</Td>
                <Td>{formatFullDate(date.startDateTime)}</Td>
                <Td>{date.endDateTime && formatFullDate(date.endDateTime)}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Box>
  )
}
