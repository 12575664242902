import type { CreateOrganisationRequest, OrganisationDetailed } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

export function createOrganisationDetails(formData: CreateOrganisationRequest): Promise<OrganisationDetailed> {
  const tokens = getTokens()
  return fetch(`${import.meta.env.VITE_API_URL}admin/organisations`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${tokens?.accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}
