import { Checkbox, Flex, Table as ChakraTable, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import type { ColumnDef, RowData, RowSelectionState } from '@tanstack/react-table'
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { useMemo, useState } from 'react'

type TableProps<TData extends RowData> = {
  columns: ColumnDef<TData>[]
  data: TData[]
  enableRowSelection?: boolean
}

export const Table = <TData extends RowData>({ columns, data, enableRowSelection }: TableProps<TData>) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const selectionColumn: ColumnDef<TData> = useMemo(
    () => ({
      id: 'selection',
      header: 'Select',
      cell: ({ row }) => (
        <Flex align="center" justify="center">
          <Checkbox
            isChecked={row.getIsSelected()}
            isDisabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
          />
        </Flex>
      ),
    }),
    []
  )

  const table = useReactTable({
    columns: [...(enableRowSelection ? [selectionColumn] : []), ...columns],
    data,
    state: {
      rowSelection,
    },
    enableRowSelection,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <ChakraTable>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} borderColor="primary.500" color="primary.300">
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map(row => {
          return (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => {
                return (
                  <Td key={cell.id} borderColor="primary.600" fontSize="sm" py={4}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                )
              })}
            </Tr>
          )
        })}
      </Tbody>
    </ChakraTable>
  )
}
