import { convertOversToBallsTotal } from '@ias-shared/cricket-logic'
import { each } from 'lodash'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { useRouteLoaderData } from 'react-router-dom'

import { DismissalMethods } from '../reference'
import type { MatchLoaderData } from '../screens/match'
import type {
  BattingPartnership,
  BattingPerformance,
  BowlingPerformance,
  BowlingPerformanceSpell,
  Dismissal,
  Match,
  MatchDate,
  MatchTeam,
} from '../types/match'
import { getPlayerDisplayName } from './player'

export function useMatch(bettingMode?: boolean) {
  const { mainModeData, bettingModeData } = useRouteLoaderData('match') as MatchLoaderData
  return bettingMode ? bettingModeData : mainModeData
}

export function formatMatchDates(dates: Partial<MatchDate>[]) {
  const noDate = '1900-01-01T00:00:00Z'

  // First, figure out how many dates we have
  const matchDates: (string | null)[] = []
  each(dates, date => {
    if (date.startDateTime !== noDate) matchDates.push(date.startDateTime || null)
  })

  if (matchDates.length === 1) {
    return `${moment(matchDates[0]).format('DD/MM/YYYY hh:mm a')}`
  }

  // multi day game
  let combinedDateString = ''
  let currentMonth = ''
  let currentYear = ''

  const stringBreakdown: {
    days: string[]
    month: string
  }[] = []

  each(matchDates, date => {
    const day = moment(date)
    if (day) {
      if (day.format('MMM') !== currentMonth) {
        stringBreakdown.push({
          days: [day.format('D')],
          month: day.format('MMM'),
        })
        currentMonth = day.format('MMM')
      } else {
        stringBreakdown[stringBreakdown.length - 1].days.push(day.format('D'))
      }
      currentYear = day.format('YYYY')
    }
  })

  // put the breakdown together
  each(stringBreakdown, range => {
    const days = range.days.join(', ')
    combinedDateString = `${combinedDateString}${days} ${range.month} & `
  })
  combinedDateString = combinedDateString.slice(0, -2)
  combinedDateString = `${combinedDateString} ${currentYear} (${moment(matchDates[0]).format('hh:mm a')})`

  return combinedDateString
}

export function getActiveInning(match: Match) {
  if (!match.matchTeams) return

  let activeInning

  match.matchTeams?.forEach(team => {
    const active = team.innings.find(inning => inning.inningsStatusId === 1)
    if (active) activeInning = active
  })

  if (!activeInning) {
    // check if there is an inning NOT_YET_STARTED
    match.matchTeams?.forEach(team => {
      const active = team.innings.find(inning => inning.inningsStatusId === 0)
      if (active) activeInning = active
    })
  }

  // if we still don't have an active innings, check if the match is over, if it is return the most recent inning
  if (match.matchStatusId === 3) {
    const allInnings = getAllInningsInOrder(match)
    activeInning = allInnings[allInnings.length - 1]
  }

  return activeInning
}

export function getActiveMatchBreak(match: Match) {
  if (!match.matchBreaks) return null

  if (match.matchBreaks.length > 0 && !match.matchBreaks[match.matchBreaks.length - 1].endTime) {
    return match.matchBreaks[match.matchBreaks.length - 1]
  }

  return null
}

export function getInningByMatchOrder(matchTeams: MatchTeam[], order: number) {
  return matchTeams
    ?.map(team => team.innings)
    .flat()
    .find(inning => inning.inningsMatchOrder === order)
}

export function getAllInningsInOrder(match: Match) {
  return match.matchTeams
    ?.map(team => team.innings)
    .flat()
    .sort((a, b) => a.inningsMatchOrder - b.inningsMatchOrder)
}

export function getHomeTeam(matchTeams: MatchTeam[] | undefined) {
  return matchTeams?.find(team => team.isHome)
}

export function getAwayTeam(matchTeams: MatchTeam[] | undefined) {
  return matchTeams?.find(team => !team.isHome)
}

export function getTeamById(teamId: string | null, match: Match) {
  if (!teamId) return null
  return match.matchTeams?.find(t => t.id === teamId)
}

export function getActiveInningBattingTeam(match: Match) {
  const activeInning = getActiveInning(match)
  if (!activeInning) return null
  return getTeamById(activeInning.battingTeamId, match)
}

export function getBattingScorecardStatus(match: Match, notOut?: boolean | null, dismissal?: Dismissal | null) {
  if (!notOut && !dismissal) return ''
  if (!dismissal && notOut) return 'not out'
  if (dismissal) {
    const dismissalType = DismissalMethods[dismissal.dismissalTypeId]
    if (dismissalType.startsWith('RETIRED')) {
      return dismissalType.replace(/_/g, ' ').toLowerCase()
    }
    if (dismissalType === 'CAUGHT') {
      if (dismissal.fielders?.length) {
        const primaryFielder = dismissal.fielders.find(f => f.order === 1)
        if (primaryFielder && primaryFielder.player.id === dismissal.bowler) {
          return `c&b: ${getPlayerDisplayName(match.matchTeams, dismissal.bowler)}`
        }
        if (primaryFielder) {
          return `c: ${getPlayerDisplayName(
            match.matchTeams,
            primaryFielder?.player.id,
            true
          )} b: ${getPlayerDisplayName(match.matchTeams, dismissal.bowler)}`
        }
      }
      return `c: ? b: ${getPlayerDisplayName(match.matchTeams, dismissal.bowler)}`
    }
    if (dismissalType === 'STUMPED') {
      if (dismissal.fielders?.length) {
        const primaryFielder = dismissal.fielders.find(f => f.order === 1)
        if (primaryFielder) {
          return `st: ${getPlayerDisplayName(
            match.matchTeams,
            primaryFielder?.player.id,
            true
          )} b: ${getPlayerDisplayName(match.matchTeams, dismissal.bowler)}`
        }
      }
      return `st: ? b: ${getPlayerDisplayName(match.matchTeams, dismissal.bowler)}`
    }
    if (dismissalType === 'RUN_OUT') {
      if (dismissal.fielders?.length) {
        const primaryFielder = dismissal.fielders.find(f => f.order === 1)
        if (primaryFielder) {
          return `run out: ${getPlayerDisplayName(match.matchTeams, primaryFielder?.player.id, true)}`
        }
      }
      return `run out: ?`
    }
    if (dismissalType === 'LBW') {
      return `lbw: ${getPlayerDisplayName(match.matchTeams, dismissal.bowler)}`
    }
    if (dismissalType === 'BOWLED') {
      return `b: ${getPlayerDisplayName(match.matchTeams, dismissal.bowler)}`
    }
    if (dismissalType === 'HIT_WICKET') {
      return `hit wicket b: ${getPlayerDisplayName(match.matchTeams, dismissal.bowler)}`
    }
    if (dismissalType === 'HIT_TWICE') {
      return `hit twice`
    }
    if (dismissalType === 'TIMED_OUT') {
      return `timed out`
    }
    if (dismissalType === 'HANDLED_BALL') {
      return `handled the ball`
    }
    if (dismissalType === 'OBSTRUCTING_FIELD') {
      return `obstructing the field`
    }
    if (dismissalType === 'ABSENT') {
      return `absent`
    }
  }
  return ''
}

export function getStrikeRate(battingPerformance: BattingPerformance | undefined) {
  if (!battingPerformance) return 0
  const strikeRate = ((battingPerformance.runs || 0) / (battingPerformance.balls || 1)) * 100
  return Math.round(strikeRate * 100) / 100
}

export function getBattingMinutes(battingPerformance: BattingPerformance | undefined) {
  if (!battingPerformance) return 0
  const currentInstance = battingPerformance.battingPerformanceInstances.sort(
    (a, b) => new Date(b.start).getTime() - new Date(a.start).getTime()
  )[0]

  if (!currentInstance?.battingMinutes || currentInstance?.battingMinutes.length === 0) return 0
  return currentInstance.battingMinutes
    .map((mins: { startTimestamp: moment.MomentInput; endTimestamp: momentTimezone.MomentInput }) => {
      const sMoment = momentTimezone.utc(mins.startTimestamp)
      let eMoment = momentTimezone.utc(new Date().toISOString())
      if (mins.endTimestamp) eMoment = momentTimezone.utc(mins.endTimestamp)
      return eMoment.diff(sMoment, 'minutes')
    })
    .reduce((acc, cur) => acc + cur)
}

export function getBattingPartnershipMinutes(partnership: BattingPartnership, match: Match) {
  // calculate the length of breaks that took place during this partnership
  let breakLength = 0
  match.matchBreaks.forEach(matchBreak => {
    if (!matchBreak.startTime) return
    // if break started after this partnership started, and the partnership is either still in progress
    // or the break ended during this partnership
    if (
      new Date(matchBreak.startTime).getTime() >= new Date(partnership.startTimestamp).getTime() &&
      (!partnership.endTimestamp ||
        (partnership.endTimestamp && matchBreak.endTime && matchBreak.endTime <= partnership.endTimestamp))
    ) {
      breakLength += momentTimezone
        .utc(matchBreak.endTime || new Date().toISOString())
        .diff(momentTimezone.utc(matchBreak.startTime), 'minutes')
    }
  })
  // calculate the length of the partnership in minutes, minus breaks
  const sMoment = momentTimezone.utc(partnership.startTimestamp)
  let eMoment = momentTimezone.utc(new Date().toISOString())
  if (partnership.endTimestamp) eMoment = momentTimezone.utc(partnership.endTimestamp)
  return eMoment.diff(sMoment, 'minutes') - breakLength
}

export function getBowlingOvers(bowlingPerformance: BowlingPerformance | undefined) {
  return bowlingPerformance?.overs ? Number(Number(bowlingPerformance.overs).toFixed(1)) : 0
}

export function getBowlingPerformanceEconomy(bowlingPerformance: BowlingPerformance | undefined, ballsPerOver: number) {
  if (bowlingPerformance?.runs === undefined || bowlingPerformance?.overs === undefined) return 0

  const oversAsBalls = convertOversToBallsTotal({
    overs: getBowlingOvers(bowlingPerformance),
    ballsPerOver: ballsPerOver,
  })
  if (!oversAsBalls) return 0

  const economy = ((bowlingPerformance.runs || 0) / oversAsBalls) * ballsPerOver
  return Math.round(economy * 100) / 100
}

export function getBowlingSpellEconomy(spell: BowlingPerformanceSpell, ballsPerOver: number) {
  if (spell.runs !== undefined && spell.overs !== undefined) {
    const oversAsBalls: number = convertOversToBallsTotal({ overs: spell.overs || 0, ballsPerOver: ballsPerOver })
    if (!oversAsBalls) return 0
    const economy = ((spell.runs || 0) / oversAsBalls) * ballsPerOver
    return Math.round(economy * 100) / 100
  }
  return 0
}

export function getDatesInOrder(match: Match) {
  return match.matchDates?.filter(d => d.startDateTime !== '1900-01-01T00:00:00Z').sort()
}

export function getLatestConditions(matchDate: MatchDate) {
  return matchDate.conditions?.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())[0]
}
