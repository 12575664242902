import { Button, Code, FormControl, FormLabel, Heading, Icon, Link, Select, Stack } from '@chakra-ui/react'
import type { GetVenuesResponse, VenueBase } from '@clsplus/api-types/api-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { InfiniteData, QueryClient } from '@tanstack/react-query'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import type { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Link as RouterLink, useLoaderData, useSearchParams } from 'react-router-dom'

import { countriesRefDataQuery } from '../../../api/get-countries-ref-data'
import { venuesQuery } from '../../../api/get-venues'
import { ListPageFilters } from '../../../components/list-page-filters'
import { ComboBox } from '../../../ui/combo-box'
import { Input } from '../../../ui/input'
import { NonIdealState } from '../../../ui/non-ideal-state'
import { Table } from '../../../ui/table'

type VenuesFilters = {
  city: string
  country: string
  deleted: string
  id: string
  intId: string
  name: string
}

export const loader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const urlParams = Object.fromEntries(new URL(request.url).searchParams)
    const venuesQueryOpts = venuesQuery(urlParams)

    const [initialCountriesRefData, initialVenuesData] = await Promise.all([
      queryClient.ensureQueryData(countriesRefDataQuery),
      queryClient.getQueryData<InfiniteData<GetVenuesResponse>>(venuesQueryOpts.queryKey) ??
        (await queryClient.fetchInfiniteQuery(venuesQueryOpts)),
    ])

    return {
      initialCountriesRefData,
      initialVenuesData,
    }
  }

export default function Venues() {
  const [searchParams, setSearchParams] = useSearchParams()

  const params = Object.fromEntries(searchParams)
  const paramsCount = Object.keys(params).length

  const { initialCountriesRefData, initialVenuesData } = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >

  const { data: venues, ...venuesQueryResult } = useInfiniteQuery({
    ...venuesQuery(params),
    initialData: initialVenuesData,
  })

  const { data: countriesRefData } = useQuery({
    ...countriesRefDataQuery,
    initialData: initialCountriesRefData,
  })

  const filtersFormDefaultValues = {
    city: params.city ?? '',
    country: params.country ?? '',
    deleted: params.deleted ?? '',
    id: params.id ?? '',
    intId: params.intId ?? '',
    name: params.name ?? '',
  }

  const { control, register, handleSubmit, reset } = useForm<VenuesFilters>({
    values: filtersFormDefaultValues,
  })

  const handleDismissFilters = () => reset(filtersFormDefaultValues)

  const handleSubmitClick = (formData: VenuesFilters) => {
    const newSearchParams = Object.entries(formData).reduce<Record<string, string>>(
      (acc, [key, val]) => ({
        ...(val && { [key]: val }),
        ...acc,
      }),
      {}
    )

    setSearchParams(newSearchParams)
  }

  const handleResetClick = () => {
    reset(filtersFormDefaultValues)
    setSearchParams({})
  }

  const rows = venues?.pages.flatMap(page => page.data) || []

  const columns: ColumnDef<VenueBase>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row }) => (
          <Link as={RouterLink} to={row.original.id}>
            {row.original.name}
          </Link>
        ),
      },
      {
        accessorKey: 'city',
        header: 'City',
      },
      {
        accessorKey: 'country',
        accessorFn: row => row.country?.name,
        header: 'Country',
      },
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'intId',
        accessorFn: row => row.integrationIds.SDS,
        header: 'SDS ID',
      },
      {
        accessorKey: 'deleted',
        accessorFn: row => (row.isDeleted ? 'true' : 'false'),
        header: 'Deleted',
        cell: ({ row }) => (row.original.isDeleted ? 'Yes' : 'No'),
      },
    ],
    []
  )

  const countryOptions = countriesRefData.map(country => ({ value: country.code, label: country.name }))

  return (
    <>
      <Heading as="h2" size="lg">
        Venues
      </Heading>
      <Stack w="100%" spacing={8} my={6}>
        <ListPageFilters
          formId="venues-filters"
          onDismiss={handleDismissFilters}
          onReset={handleResetClick}
          onSubmit={handleSubmit(handleSubmitClick)}
          title="Filter venues"
        >
          <FormControl>
            <FormLabel>Venue ID</FormLabel>
            <Input {...register('id')} />
          </FormControl>
          <FormControl>
            <FormLabel>SDS ID</FormLabel>
            <Input {...register('intId')} />
          </FormControl>
          <FormControl>
            <FormLabel>City</FormLabel>
            <Input {...register('city')} />
          </FormControl>
          <FormControl>
            <FormLabel>Country</FormLabel>
            <Controller
              control={control}
              name="country"
              render={({ field: { onChange, value } }) => (
                <ComboBox
                  isSingleSelect
                  options={countryOptions}
                  onChange={onChange}
                  selectedOptions={countryOptions.filter(option => option.value === value)}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input {...register('name')} />
          </FormControl>
          <FormControl>
            <FormLabel>Deleted</FormLabel>
            <Select {...register('deleted')}>
              <option value="">-</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Select>
          </FormControl>
        </ListPageFilters>
        {venuesQueryResult.isError ? (
          <NonIdealState
            title="Error fetching venues"
            description="Here's what we know:"
            iconColor="red.600"
            icon={['fad', 'triangle-exclamation']}
          >
            <Code mt={4} px={6} py={4} borderRadius="6px" bg="primary.600">
              {venuesQueryResult.error instanceof Error
                ? venuesQueryResult.error.message
                : 'An unknown error occurred. Please contact a system administrator.'}
            </Code>
            <Button
              mt={4}
              variant="secondary"
              leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'xmark-large']} />}
              onClick={handleResetClick}
            >
              Reset filters
            </Button>
          </NonIdealState>
        ) : rows?.length ? (
          <Table columns={columns} data={rows} />
        ) : (
          <NonIdealState
            title="No venues found"
            description={
              paramsCount > 0 ? "We couldn't find any venues matching your filters" : "We couldn't find any venues"
            }
            iconColor="primary.400"
            icon={['fas', 'face-confused']}
          >
            {paramsCount > 0 && (
              <Button
                mt={4}
                variant="secondary"
                leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'xmark-large']} />}
                onClick={handleResetClick}
              >
                Reset filters
              </Button>
            )}
          </NonIdealState>
        )}
        {venuesQueryResult.isSuccess && venuesQueryResult.hasNextPage && (
          <Button
            mt={8}
            variant="primary"
            leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'ellipsis']} />}
            isLoading={venuesQueryResult.isFetchingNextPage}
            onClick={() => venuesQueryResult.fetchNextPage()}
          >
            Load more
          </Button>
        )}
      </Stack>
    </>
  )
}
