import {
  BodyContactOptions,
  BowlerApproachOptions,
  BowlerTypeOptions,
  ByeBlameOptions,
  ExtrasTypeOptions,
  FootworkOptions,
  HandedTypeOptions,
  ShotContactOptions,
  ShotTypeOptions,
  WicketKeeperPositionOptions,
} from '../reference'
import type { Ball, ConfirmedBallsByOver } from '../types/ball'

export function confirmedBallsGrouped(balls: Ball[] | undefined): ConfirmedBallsByOver {
  if (!balls) return []

  return balls
    .filter(b => b.confirmed)
    .sort((a, b) => {
      if (a.overNumber === b.overNumber) {
        return b.ballNumber - a.ballNumber
      }
      return b.overNumber - a.overNumber
    })
    .reduce((acc, b) => {
      if (!acc[b.overNumber]) {
        acc[b.overNumber] = []
      }
      acc[b.overNumber].push(b)
      return acc
    }, {} as Record<number, Ball[]>)
}

export function getExtraType(ball: Ball) {
  if (ball.extrasTypeId === null) return undefined
  return ExtrasTypeOptions[ball.extrasTypeId]
}

export function getBowlerBallType(ball: Ball) {
  if (!ball.bowlingAnalysis || ball.bowlingAnalysis.bowlerBallTypeId === null) return undefined
  return ExtrasTypeOptions[ball.bowlingAnalysis.bowlerBallTypeId]
}

export function getBowlerApproachType(ball: Ball) {
  if (!ball.bowlingAnalysis || ball.bowlingAnalysis.bowlerBallApproachId === null) return undefined
  return BowlerApproachOptions[ball.bowlingAnalysis.bowlerBallApproachId]
}

export function getBowlerHandType(ball: Ball) {
  if (!ball.bowlingAnalysis || ball.bowlingAnalysis.bowlerHandId === null) return undefined
  return HandedTypeOptions[ball.bowlingAnalysis.bowlerHandId]
}

export function getBowlerType(ball: Ball) {
  if (!ball.bowlingAnalysis || ball.bowlingAnalysis.bowlerTypeId === null) return undefined
  return BowlerTypeOptions[ball.bowlingAnalysis.bowlerTypeId]
}

export function getShotType(ball: Ball) {
  if (!ball.battingAnalysis || ball.battingAnalysis.shots.shotTypeId === null) return undefined
  return ShotTypeOptions[ball.battingAnalysis.shots.shotTypeId]
}

export function getShotContactType(ball: Ball) {
  if (!ball.battingAnalysis || ball.battingAnalysis.shots.shotContactId === null) return undefined
  return ShotContactOptions[ball.battingAnalysis.shots.shotContactId]
}

export function getBodyContactType(ball: Ball) {
  if (!ball.battingAnalysis || ball.battingAnalysis.shots.bodyContactId === null) return undefined
  return BodyContactOptions[ball.battingAnalysis.shots.bodyContactId]
}

export function getFootworkType(ball: Ball) {
  if (!ball.battingAnalysis || ball.battingAnalysis?.footworkTypeId === null) return undefined
  return FootworkOptions[ball.battingAnalysis?.footworkTypeId]
}

export function getByeBlame(ball: Ball) {
  if (ball.byeBlameId === null) return undefined
  return ByeBlameOptions[ball.byeBlameId]
}

export function getWicketKeeperPosition(ball: Ball) {
  if (!ball.fieldingAnalysis || ball.fieldingAnalysis?.wicketKeeperPositionId === null) return undefined
  return WicketKeeperPositionOptions[ball.fieldingAnalysis?.wicketKeeperPositionId]
}

export const ballSummaryColor = (ball: Ball): string => {
  if (ball.dismissal) {
    return 'red'
  } else if (ball.runsExtra || ball.allRun) {
    return 'primary'
  } else if (ball.runsBat === 6) {
    return ball.fieldingAnalysis?.overThrows ? 'primary' : 'purple'
  } else if (ball.runsBat === 4) {
    return ball.fieldingAnalysis?.overThrows ? 'primary' : 'green'
  } else {
    return 'primary'
  }
}
