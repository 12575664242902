import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Select, Stack } from '@chakra-ui/react'
import type { UpdatePersonRequest } from '@clsplus/api-types/api-admin'
import { zodResolver } from '@hookform/resolvers/zod'
import type { QueryClient } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { useLoaderData, useNavigate, useParams, useRevalidator } from 'react-router-dom'
import * as z from 'zod'

import { personDetailsQuery } from '../../../api/get-person-details'
import { updatePersonDetails } from '../../../api/update-person-details'
import { BowlerTypes, HandedTypes } from '../../../reference'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) =>
    await queryClient.ensureQueryData(personDetailsQuery(params.personId || ''))

const personEditSchema = z.object({
  firstName: z.string(),
  middleName: z.string(),
  lastName: z.string(),
  fullName: z.string().min(1, { message: 'Please enter a name' }),
  cardNameF: z.string(),
  cardNameS: z.string(),
  battingHand: z.preprocess(value => (value === '' || value === null ? null : Number(value)), z.nullable(z.number())),
  bowlingHand: z.preprocess(value => (value === '' || value === null ? null : Number(value)), z.nullable(z.number())),
  bowlingType: z.preprocess(value => (value === '' || value === null ? null : Number(value)), z.nullable(z.number())),
  primaryThrowingArm: z.preprocess(
    value => (value === '' || value === null ? null : Number(value)),
    z.nullable(z.number())
  ),
})

type PersonEditFormData = z.infer<typeof personEditSchema>

export default function PersonEdit() {
  const params = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const revalidator = useRevalidator()
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const personId = params.personId || ''

  const { data: personDetails } = useQuery({
    ...personDetailsQuery(personId),
    initialData,
  })

  const updatePersonMutation = useMutation({
    mutationFn: (formData: UpdatePersonRequest) => updatePersonDetails(personId, formData),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['people'] }),
        queryClient.invalidateQueries({ queryKey: ['person', personId] }),
      ])
      revalidator.revalidate()
      navigate(`/people/${personId}`)
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<PersonEditFormData>({
    defaultValues: {
      firstName: personDetails.firstName ?? '',
      middleName: personDetails.middleName ?? '',
      lastName: personDetails.lastName ?? '',
      fullName: personDetails.name ?? '',
      cardNameF: personDetails.cardNameF ?? '',
      cardNameS: personDetails.cardNameS ?? '',
      battingHand: personDetails.battingHand?.id ?? null,
      bowlingHand: personDetails.bowlingHand?.id ?? null,
      bowlingType: personDetails.bowlingType?.id ?? null,
      primaryThrowingArm: personDetails.primaryThrowingArm?.id ?? null,
    },
    resolver: zodResolver(personEditSchema),
  })

  const handleSubmitClick = (formData: PersonEditFormData) => {
    updatePersonMutation.mutate(formData)
  }

  return (
    <Flex direction="column" w="100%">
      <Heading as="h2" size="lg">
        {personDetails.name}
      </Heading>
      <Stack
        as="form"
        noValidate
        id="person-edit"
        spacing={4}
        maxW="container.sm"
        mt={8}
        onSubmit={handleSubmit(handleSubmitClick)}
      >
        <FormControl isInvalid={!!errors.firstName}>
          <FormLabel>First name</FormLabel>
          <Input {...register('firstName')} />
          {errors.firstName && <FormErrorMessage>{errors.firstName.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.middleName}>
          <FormLabel>Middle name</FormLabel>
          <Input {...register('middleName')} />
          {errors.middleName && <FormErrorMessage>{errors.middleName.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.lastName}>
          <FormLabel>Last name</FormLabel>
          <Input {...register('lastName')} />
          {errors.lastName && <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.fullName}>
          <FormLabel>Full name</FormLabel>
          <Input {...register('fullName')} />
          {errors.fullName && <FormErrorMessage>{errors.fullName.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.cardNameF}>
          <FormLabel>Card name (full)</FormLabel>
          <Input {...register('cardNameF')} />
          {errors.cardNameF && <FormErrorMessage>{errors.cardNameF.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.cardNameS}>
          <FormLabel>Card name (short)</FormLabel>
          <Input {...register('cardNameS')} />
          {errors.cardNameS && <FormErrorMessage>{errors.cardNameS.message}</FormErrorMessage>}
        </FormControl>
        <FormControl>
          <FormLabel>Batting hand</FormLabel>
          <Select w={48} {...register('battingHand')}>
            <option value="">-</option>
            {Object.entries(HandedTypes).map(([key, value]) => (
              <option key={key} value={`${value}`}>
                {key}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Bowling hand</FormLabel>
          <Select w={48} {...register('bowlingHand')}>
            <option value="">-</option>
            {Object.entries(HandedTypes).map(([key, value]) => (
              <option key={key} value={`${value}`}>
                {key}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Bowling type</FormLabel>
          <Select w={48} {...register('bowlingType')}>
            <option value="">-</option>
            {Object.entries(BowlerTypes).map(([key, value]) => (
              <option key={key} value={`${value}`}>
                {key}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Primary throwing arm</FormLabel>
          <Select w={48} {...register('primaryThrowingArm')}>
            <option value="">-</option>
            {Object.entries(HandedTypes).map(([key, value]) => (
              <option key={key} value={`${value}`}>
                {key}
              </option>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={4} mt={8}>
        <Button
          type="submit"
          form="person-edit"
          variant="primary"
          isDisabled={!isDirty}
          isLoading={updatePersonMutation.isLoading}
        >
          Save changes
        </Button>
        <Button
          variant="secondary"
          onClick={() => navigate(`/people/${personId}`)}
          isDisabled={updatePersonMutation.isLoading}
        >
          Cancel
        </Button>
      </Stack>
    </Flex>
  )
}
