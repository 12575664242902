import type { InputProps as ChakraInputProps } from '@chakra-ui/react'
import { forwardRef, Input as ChakraInput } from '@chakra-ui/react'

export const Input = forwardRef(({ ...props }: ChakraInputProps, ref) => {
  return <ChakraInput ref={ref} autoComplete="off" {...props} />
})

// Chakra needs this in order for `InputGroup` to identify the `Input` when applying positioning
// styles to other children of `InputGroup` such as `InputRightElement` and `InputLeftElement`.
Input.id = 'Input'
