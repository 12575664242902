import { Button, Flex, Heading, Icon, Stack } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { QueryClient } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Link as RouterLink, useLoaderData, useParams } from 'react-router-dom'

import { organisationDetailsQuery } from '../../../api/get-organisation-details'
import { DetailValue } from '../../../components/detail-value'
import { HasMCAccess, MCRequiredLevelEnum } from '../../../helpers/auth'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    return await queryClient.ensureQueryData(organisationDetailsQuery(params.organisationId || ''))
  }

export default function OrganisationDetails() {
  const params = useParams()
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const { data: organisationDetails } = useQuery({
    ...organisationDetailsQuery(params.organisationId || ''),
    initialData,
  })

  return (
    <>
      <Flex w="100%" justifyContent="space-between" alignItems="baseline">
        <Heading as="h2" size="lg">
          {organisationDetails.name}
        </Heading>

        <HasMCAccess minLevel={MCRequiredLevelEnum['clsp-superadmin']}>
          <Button
            as={RouterLink}
            to={`/organisations/${organisationDetails.id}/edit`}
            variant="primary"
            minW={32}
            leftIcon={<Icon as={FontAwesomeIcon} icon={['fad', 'pen-to-square']} />}
          >
            Edit
          </Button>
        </HasMCAccess>
      </Flex>
      <Stack w="100%" mt={6} spacing={6}>
        <DetailValue label="CLS+ ID" value={organisationDetails.id} />
        <DetailValue label="Short name" value={organisationDetails.shortName} />
        <DetailValue label="Country code" value={organisationDetails.countryCode} />
        <DetailValue label="Can manage comps" value={organisationDetails.canManage ? 'Yes' : 'No'} />
        <DetailValue label="Can participate in comps" value={organisationDetails.canParticipate ? 'Yes' : 'No'} />
      </Stack>
    </>
  )
}
