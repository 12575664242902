import type { MatchDetailed } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

function getMatchDetails(matchId: string): Promise<MatchDetailed> {
  const tokens = getTokens()

  return fetch(`${import.meta.env.VITE_API_URL}admin/matches/${matchId}`, {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const matchDetailsQuery = (matchId: string) => ({
  queryKey: ['match', matchId],
  queryFn: () => getMatchDetails(matchId),
})
