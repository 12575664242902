import { Button, Flex, Heading, Icon, Stack, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { QueryClient } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Link as RouterLink, useLoaderData, useParams } from 'react-router-dom'

import { matchDetailsQuery } from '../../../api/get-match-details'
import { DetailValue } from '../../../components/detail-value'
import { MatchDatesTable } from '../../../components/match-dates-table'
import { HasMCAccess, MCRequiredLevelEnum } from '../../../helpers/auth'
import { formatFullDate } from '../../../helpers/datetime'
import { CodeBlock } from '../../../ui/code-block'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    return await queryClient.ensureQueryData(matchDetailsQuery(params.matchId || ''))
  }

export default function MatchDetails() {
  const params = useParams()
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const { data: matchDetails } = useQuery({
    ...matchDetailsQuery(params.matchId || ''),
    initialData,
  })

  return (
    <>
      <Flex w="100%" justifyContent="space-between" alignItems="baseline">
        <Heading as="h2" size="lg">
          {matchDetails.teams
            ?.map(team => team.name)
            .sort()
            .join(' vs ') ?? 'TBC vs TBC'}
        </Heading>

        <HasMCAccess minLevel={MCRequiredLevelEnum['clsp-superadmin']}>
          <Button
            as={RouterLink}
            to={`/matches/${matchDetails.id}/edit`}
            variant="primary"
            minW={32}
            leftIcon={<Icon as={FontAwesomeIcon} icon={['fad', 'pen-to-square']} />}
          >
            Edit
          </Button>
        </HasMCAccess>
      </Flex>
      <Stack w="100%" mt={6} spacing={6}>
        <DetailValue label="CLS+ ID" value={matchDetails.id} />
        <DetailValue label="SDS ID" value={matchDetails.integrationIds?.SDS} />
        <DetailValue label="Coverage level" value={matchDetails.config?.coverageLevelOverride?.name} />
        <DetailValue label="Title" value={matchDetails.title} />
        <DetailValue label="Description" value={matchDetails.description} />
        <DetailValue
          label="Teams"
          value={matchDetails.teams?.map(team => (
            <Text key={team.id}>{team.name}</Text>
          ))}
        />
        <DetailValue label="Venue" value={matchDetails.venue?.name} />
        <DetailValue label="Time of day" value={matchDetails.timeOfDay?.name} />
        <DetailValue label="Match number" value={matchDetails.matchNumber} />
        <DetailValue label="Was live scored" value={matchDetails.wasLiveScored ? 'Yes' : 'No'} />
        <DetailValue
          label="Scores last updated"
          value={matchDetails.scoresUpdated && formatFullDate(new Date(matchDetails.scoresUpdated))}
        />
        <DetailValue label="Competition pool ID" value={matchDetails.competitionPool?.id} />
        <DetailValue label="Competition stage ID" value={matchDetails.competitionStage?.id} />
        <DetailValue label="Gender" value={matchDetails.gender} />
        <DetailValue label="Format" value={matchDetails.format.name} />
        <DetailValue label="Class" value={matchDetails.class.name} />
        <DetailValue label="Organisation" value={matchDetails.organisation?.name} />
        <Heading size="md" pt={4}>
          Match dates
        </Heading>
        <DetailValue label="Timezone" value={matchDetails.timeZone || 'UTC'} />
        <DetailValue
          label="Dates"
          value={matchDetails.dates && <MatchDatesTable dates={matchDetails.dates} timeZone={matchDetails.timeZone} />}
        />
        <Heading size="md" pt={4}>
          Match config
        </Heading>
        <DetailValue
          label="Config"
          value={matchDetails.config && <CodeBlock>{JSON.stringify(matchDetails.config, null, 4)}</CodeBlock>}
        />
      </Stack>
    </>
  )
}
