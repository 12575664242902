import { forwardRef } from 'react'
import type { ReactDatePickerProps } from 'react-datepicker'
import ReactDatePicker from 'react-datepicker'

export const DatePicker = forwardRef<ReactDatePicker, ReactDatePickerProps>((props, ref) => {
  return (
    <ReactDatePicker
      popperModifiers={[
        {
          name: 'arrow',
          options: {
            // Fix arrow appearing in the middle of the input instead of to the left side
            // React Datepicker doesn't export these types and it's not worth redefining them
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            padding: ({ popper, reference }: any) => ({
              right: Math.min(popper.width, reference.width) - 24,
            }),
          },
        },
      ]}
      ref={ref}
      {...props}
    />
  )
})

DatePicker.displayName = 'DatePicker'
