import type { Country } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

function getCountriesRefData(): Promise<Country[]> {
  const tokens = getTokens()

  return fetch(`${import.meta.env.VITE_API_URL}admin/reference-data/countries`, {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const countriesRefDataQuery = {
  queryKey: ['countries-ref-data'],
  queryFn: () => getCountriesRefData(),
}
