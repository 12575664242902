import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import type { S3PBallPathQuery } from '@clsplus/api-types/s3p-manage'
import { useQuery } from '@tanstack/react-query'

import { getS3PBallQuery } from '../../api/get-s3p-ball-diagnostics'
import { formatFullDate } from '../../helpers/datetime'
import type { Ball } from '../../types/ball'
import type { Inning, Match } from '../../types/match'

type S3PBallDiagnosticModalProps = {
  match: Match
  ball: Ball
  activeInning: Inning
}

export const S3pBallDiagnosticModal = ({ match, ball, activeInning }: S3PBallDiagnosticModalProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure()

  const s3pBallQ: S3PBallPathQuery = {
    matchId: match?.id ?? '',
    inningsMatchOrder: (activeInning.inningsMatchOrder ?? -1).toString(),
    overNumber: (ball.overNumber + 1).toString(),
    ballNumber: (ball.ballNumber ?? -1).toString(),
  }

  const { data: s3pBallData, isLoading } = useQuery({ ...getS3PBallQuery(s3pBallQ) })

  return (
    <>
      <Button onClick={onOpen}>View ball S3P messages</Button>
      <Modal
        blockScrollOnMount={false}
        closeOnOverlayClick={false}
        scrollBehavior={'inside'}
        size={'xl'}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ball S3P</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {isLoading ? (
              <Spinner />
            ) : (
              <Accordion allowMultiple>
                {s3pBallData &&
                  s3pBallData.map &&
                  s3pBallData?.map(ballData => {
                    return (
                      <AccordionItem key={`ballAccordionItem-${ballData.id}`}>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                              <Text>{`Ball: ${ballData.id}`}</Text>
                              {`Number: ${ballData.data?.payload?.ball_number} - Sequence: ${
                                ballData.sequence
                              } - ${ballData.type?.split('.').pop()} - ${
                                ballData.data?.payload?.event_time
                                  ? formatFullDate(new Date(ballData.data?.payload?.event_time))
                                  : 'Invalid Date'
                              }`}
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel>
                          <Text as="pre" fontSize="12px" whiteSpace="pre-wrap">
                            {' '}
                            {JSON.stringify(ballData, null, 2)}{' '}
                          </Text>
                        </AccordionPanel>
                      </AccordionItem>
                    )
                  })}
              </Accordion>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
