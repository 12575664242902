import { Flex, Stack, Tag, Text } from '@chakra-ui/react'
import { isNil } from 'lodash'
import { Link as RouterLink } from 'react-router-dom'

import { textCleaner } from '../../helpers/data'
import { getActivePowerPlay, getPowerPlayShortName } from '../../helpers/inning'
import {
  formatMatchDates,
  getActiveInningBattingTeam,
  getActiveMatchBreak,
  getAwayTeam,
  getHomeTeam,
} from '../../helpers/match'
import { BreakTypes, InningsStatus, MatchResultType } from '../../reference'
import type { Match, MatchDls, MatchTeam } from '../../types/match'
import { NonIdealState } from '../../ui/non-ideal-state'

type TeamProps = {
  team?: MatchTeam
  home?: boolean
  isBatting?: boolean
  maxOvers: number | null
  dls: MatchDls | null
}

const Team = ({ team, home = false, isBatting, maxOvers, dls }: TeamProps) => {
  return (
    <Flex
      flex={1}
      py={10}
      justifyContent="center"
      alignItems="center"
      direction="column"
      backgroundColor={isBatting ? 'green.500' : undefined}
      borderRadius={home ? '6px 0px 0px 6px' : '0px 6px 6px 0px'}
    >
      <Flex direction="column" justifyContent="center" mx="15%" w="70%" alignItems="center">
        <Text textStyle="h3" textAlign="center" color="white" marginBottom="20px">
          {team?.name}
        </Text>
        {team?.innings.map((inning, idx) => {
          const activePowerPlay = getActivePowerPlay(inning)
          return (
            <Text key={inning.inningsMatchOrder} as="span" textStyle="h1" textAlign="center" color="white">
              {idx > 0 ? ' & ' : ''}
              {inning.progressiveScores.runs}/{inning.progressiveScores.wickets}
              {!isNil(maxOvers) &&
                maxOvers !== 0 &&
                parseInt(inning.progressiveScores.oversBowled || '0') !== maxOvers && (
                  <Text marginLeft="7px" as="span" textStyle="value" textAlign="center" color="white">
                    {InningsStatus[inning.inningsStatusId] === 'IN_PROGRESS' &&
                      `(${inning.progressiveScores.oversBowled})`}
                    {InningsStatus[inning.inningsStatusId] === 'IN_PROGRESS' && activePowerPlay && (
                      <Text marginLeft="7px" as="span" textStyle="value" textAlign="center" color="white">
                        {getPowerPlayShortName(activePowerPlay)}
                      </Text>
                    )}
                    {inning.superOver && (
                      <Text marginLeft="7px" as="span" textStyle="value" textAlign="center" color="white">
                        s.o
                      </Text>
                    )}
                  </Text>
                )}
              {(isNil(maxOvers) || maxOvers === 0) && (
                <Text marginLeft="7px" as="span" textStyle="value" textAlign="center" color="white">
                  {InningsStatus[inning.inningsStatusId] === 'IN_PROGRESS' &&
                    `(${inning.progressiveScores.oversBowled})`}
                  {inning.closeReasonId === 0 && (
                    <Text marginLeft="7px" as="span" textStyle="value" textAlign="center" color="white">
                      dec
                    </Text>
                  )}
                </Text>
              )}
            </Text>
          )
        })}
        {dls?.targetScore && team?.innings && team?.innings.length > 0 && team?.innings[0].inningsMatchOrder === 2 && (
          <Flex flex={1} textAlign="center" alignItems="center" direction="column">
            <Tag size="lg">Target {dls.targetScore}</Tag>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

const MatchInfo = ({ game }: ScoreBoxProps) => {
  const gameBreak = getActiveMatchBreak(game)

  return (
    <Flex flex={1} justifyContent="center" alignItems="center" direction="column" py={10}>
      {gameBreak && (
        <Text textStyle="value" textAlign="center" color="white" marginBottom="20px">
          {BreakTypes[gameBreak.matchBreakTypeId ?? 0]}
        </Text>
      )}
      {!isNil(game.matchResultTypeId) && (
        <>
          {!isNil(game.matchStatusId) &&
            !(game.matchStatusId === 0 || game.matchStatusId === 1 || game.matchStatusId === 2) && (
              <Text textStyle="value" textAlign="center" color="white">
                {game.description}
              </Text>
            )}
          <Text textStyle="value" textAlign="center" color="white" marginBottom="20px" fontSize="12px">
            {textCleaner(MatchResultType[game.matchResultTypeId])}
          </Text>
        </>
      )}
      <Text textStyle="boldLabel" textAlign="center" color="white" marginBottom="20px">
        {game.competitionStage?.competition?.name || game.competitionPool?.name}
      </Text>
      <Text textStyle="boldLabel" textAlign="center" color="white">
        {game.venue?.fullName}
      </Text>
      <Text textStyle="boldLabel" textAlign="center" color="white">
        {formatMatchDates(game.matchDates)}
      </Text>
    </Flex>
  )
}

type ScoreBoxProps = {
  game: Match
}

const OpsScoreBox = ({ game }: ScoreBoxProps) => {
  const homeTeam = getHomeTeam(game.matchTeams)
  const awayTeam = getAwayTeam(game.matchTeams)

  // only mark current batting team for a match that is In Progress
  const battingTeam = (game.matchStatusId || 0) <= 2 ? getActiveInningBattingTeam(game) : undefined

  return (
    <Flex
      flex={1}
      borderRadius="6px"
      bgColor="primary.600"
      border="4px"
      borderColor="primary.600"
      _hover={{ borderColor: 'primary.500' }}
    >
      <Team
        home
        team={homeTeam}
        isBatting={homeTeam?.id === battingTeam?.id}
        maxOvers={game.matchConfigs?.maxOvers || null}
        dls={game.matchDls}
      />
      <MatchInfo game={game} />
      <Team
        team={awayTeam}
        isBatting={awayTeam?.id === battingTeam?.id}
        maxOvers={game.matchConfigs?.maxOvers || null}
        dls={game.matchDls}
      />
    </Flex>
  )
}

type OpsMatchesViewProps = {
  games: Match[] | undefined
}

export const OpsMatchesView = ({ games }: OpsMatchesViewProps) => {
  if (!games?.length) {
    return (
      <NonIdealState
        h="auto"
        mt={16}
        title="No matches found"
        description="We couldn't find any matches on the selected dates"
        iconColor="blue.500"
        icon={['fad', 'triangle-exclamation']}
      />
    )
  }

  return (
    <Stack spacing={6}>
      {games.map(game => (
        <RouterLink key={game.id} to={`/match-centre/${game.id}`}>
          <OpsScoreBox game={game} />
        </RouterLink>
      ))}
    </Stack>
  )
}
