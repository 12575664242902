import { Button, Code, Flex, FormControl, FormLabel, Heading, Icon, Link, Stack } from '@chakra-ui/react'
import type { GetOrganisationsResponse, OrganisationBase } from '@clsplus/api-types/api-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { InfiniteData, QueryClient } from '@tanstack/react-query'
import { useInfiniteQuery } from '@tanstack/react-query'
import type { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Link as RouterLink, useLoaderData, useSearchParams } from 'react-router-dom'

import { organisationsQuery } from '../../../api/get-organisations'
import { ListPageFilters } from '../../../components/list-page-filters'
import { HasMCAccess, MCRequiredLevelEnum } from '../../../helpers/auth'
import { Input } from '../../../ui/input'
import { NonIdealState } from '../../../ui/non-ideal-state'
import { Table } from '../../../ui/table'

type OrganisationsFilters = {
  id: string
  name: string
}

export const loader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const urlParams = Object.fromEntries(new URL(request.url).searchParams)
    const organisationsQueryOpts = organisationsQuery(urlParams)

    return (
      queryClient.getQueryData<InfiniteData<GetOrganisationsResponse>>(organisationsQueryOpts.queryKey) ??
      (await queryClient.fetchInfiniteQuery(organisationsQueryOpts))
    )
  }

export default function Organisations() {
  const [searchParams, setSearchParams] = useSearchParams()

  const params = Object.fromEntries(searchParams)
  const paramsCount = Object.keys(params).length

  const initialOrganisationsData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const { data: organisations, ...organisationsQueryResult } = useInfiniteQuery({
    ...organisationsQuery(params),
    initialData: initialOrganisationsData,
  })

  const filtersFormDefaultValues = {
    id: params.id ?? '',
    name: params.name ?? '',
  }

  const { register, handleSubmit, reset } = useForm<OrganisationsFilters>({
    values: filtersFormDefaultValues,
  })

  const handleDismissFilters = () => reset(filtersFormDefaultValues)

  const handleSubmitClick = (formData: OrganisationsFilters) => {
    const newSearchParams = Object.entries(formData).reduce<Record<string, string>>(
      (acc, [key, val]) => ({
        ...(val && { [key]: val }),
        ...acc,
      }),
      {}
    )

    setSearchParams(newSearchParams)
  }

  const handleResetClick = () => {
    reset(filtersFormDefaultValues)
    setSearchParams({})
  }

  const rows = organisations?.pages.flatMap(page => [...page.data])

  const columns: ColumnDef<OrganisationBase>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row }) => (
          <Link as={RouterLink} to={row.original.id}>
            {row.original.name}
          </Link>
        ),
      },
      {
        accessorKey: 'id',
        header: 'ID',
      },
    ],
    []
  )

  return (
    <>
      <Heading as="h2" size="lg">
        Organisations
      </Heading>

      <Stack w="100%" spacing={8} my={6}>
        <Flex justifyContent="space-between">
          <ListPageFilters
            formId="organisations-filters"
            onDismiss={handleDismissFilters}
            onReset={handleResetClick}
            onSubmit={handleSubmit(handleSubmitClick)}
            title="Filter organisations"
          >
            <FormControl>
              <FormLabel>Organisation ID</FormLabel>
              <Input {...register('id')} />
            </FormControl>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input {...register('name')} />
            </FormControl>
          </ListPageFilters>
          <HasMCAccess minLevel={MCRequiredLevelEnum['clsp-superadmin']}>
            <Button as={RouterLink} to="create" variant="primary" leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} />}>
              New organisation
            </Button>
          </HasMCAccess>
        </Flex>

        {organisationsQueryResult.isError ? (
          <NonIdealState
            title="Error fetching organisations"
            description="Here's what we know:"
            iconColor="red.600"
            icon={['fad', 'triangle-exclamation']}
          >
            <Code mt={4} px={6} py={4} borderRadius="6px" bg="primary.600">
              {organisationsQueryResult.error instanceof Error
                ? organisationsQueryResult.error.message
                : 'An unknown error occurred. Please contact a system administrator.'}
            </Code>
            <Button
              mt={4}
              variant="secondary"
              leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'xmark-large']} />}
              onClick={handleResetClick}
            >
              Reset filters
            </Button>
          </NonIdealState>
        ) : rows?.length ? (
          <Table columns={columns} data={rows} />
        ) : (
          <NonIdealState
            title="No organisations found"
            description={
              paramsCount > 0
                ? "We couldn't find any organisations matching your filters"
                : "We couldn't find any organisations"
            }
            iconColor="primary.400"
            icon={['fas', 'face-confused']}
          >
            {paramsCount > 0 && (
              <Button
                mt={4}
                variant="secondary"
                leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'xmark-large']} />}
                onClick={handleResetClick}
              >
                Reset filters
              </Button>
            )}
          </NonIdealState>
        )}
        {organisationsQueryResult.isSuccess && organisationsQueryResult.hasNextPage && (
          <Button
            mt={8}
            variant="primary"
            leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'ellipsis']} />}
            isLoading={organisationsQueryResult.isFetchingNextPage}
            onClick={() => organisationsQueryResult.fetchNextPage()}
          >
            Load more
          </Button>
        )}
      </Stack>
    </>
  )
}
