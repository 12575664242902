import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack, Switch } from '@chakra-ui/react'
import type { CreateOrganisationRequest } from '@clsplus/api-types/api-admin'
import { zodResolver } from '@hookform/resolvers/zod'
import type { QueryClient } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { useLoaderData, useNavigate, useRevalidator } from 'react-router-dom'
import * as z from 'zod'

import { createOrganisationDetails } from '../../../api/create-organisation-request'
import { countriesRefDataQuery } from '../../../api/get-countries-ref-data'
import { ComboBox } from '../../../ui/combo-box'

const CreateOrganisationSchema = z.object({
  name: z.string().min(1, { message: 'Please enter an organisation name' }),
  shortName: z.string(),
  countryCode: z
    .union([
      z.string().length(0),
      z.string().min(2, { message: '2 letter minimum' }).max(3, { message: '3 letter maximum' }),
    ])
    .refine(
      data => {
        return data.toUpperCase() === data
      },
      {
        message: 'Must be uppercase',
      }
    )
    .optional()
    .transform(e => (e === '' ? undefined : e)),
  canManage: z.boolean(),
  canParticipate: z.boolean(),
})

type OrganisationCreateFormData = z.infer<typeof CreateOrganisationSchema>

export const loader = (queryClient: QueryClient) => async () => {
  return await queryClient.ensureQueryData(countriesRefDataQuery)
}

export default function CreateOrganisation() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const revalidator = useRevalidator()

  const initialCountriesRefData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const { data: countriesRefData } = useQuery({
    ...countriesRefDataQuery,
    initialData: initialCountriesRefData,
  })

  const createOrganisationMutation = useMutation({
    mutationFn: (formData: CreateOrganisationRequest) => createOrganisationDetails(formData),
    onSuccess: async data => {
      const organisationId = data.id
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['organisations'] }),
        queryClient.invalidateQueries({ queryKey: ['organisations/new'] }),
      ])
      revalidator.revalidate()
      navigate(`/organisations/${organisationId}`)
    },
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<OrganisationCreateFormData>({
    defaultValues: {
      name: undefined,
      shortName: undefined,
      countryCode: undefined,
      canManage: false,
      canParticipate: false,
    },
    resolver: zodResolver(CreateOrganisationSchema),
  })

  const handleSubmitClick = (formData: OrganisationCreateFormData) => {
    createOrganisationMutation.mutate(formData)
  }

  const countryOptions = countriesRefData.map(country => ({ value: country.code, label: country.name }))

  return (
    <Flex direction="column" w="100%">
      <Heading as="h2" size="lg">
        New Organisation
      </Heading>
      <Stack
        as="form"
        noValidate
        id="new-organisation"
        spacing={4}
        maxW="container.sm"
        mt={8}
        onSubmit={handleSubmit(handleSubmitClick)}
      >
        <FormControl isRequired isInvalid={!!errors.name}>
          <FormLabel>Name</FormLabel>
          <Input {...register('name')} />
          {errors.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.shortName}>
          <FormLabel>Short name</FormLabel>
          <Input {...register('shortName')} />
          {errors.shortName && <FormErrorMessage>{errors.shortName.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.countryCode}>
          <FormLabel>Country Code</FormLabel>
          <Controller
            control={control}
            name={register('countryCode').name}
            render={({ field: { onChange, value } }) => (
              <ComboBox
                isSingleSelect
                options={countryOptions}
                onChange={onChange}
                selectedOptions={countryOptions.filter(option => option.value === value)}
              />
            )}
          />
          {errors.countryCode && <FormErrorMessage>{errors.countryCode.message}</FormErrorMessage>}
        </FormControl>
        <FormControl>
          <FormLabel>Can manage competitions</FormLabel>
          <Switch variant="primary" {...register('canManage')} />
        </FormControl>
        <FormControl>
          <FormLabel>Can participate in competitions</FormLabel>
          <Switch variant="primary" {...register('canParticipate')} />
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={4} mt={8}>
        <Button
          type="submit"
          form="new-organisation"
          variant="primary"
          isDisabled={!isDirty}
          isLoading={createOrganisationMutation.isLoading}
        >
          Save changes
        </Button>
        <Button
          variant="secondary"
          onClick={() => navigate(`/organisations/`)}
          isDisabled={createOrganisationMutation.isLoading}
        >
          Cancel
        </Button>
      </Stack>
    </Flex>
  )
}
