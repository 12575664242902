import { Button, Flex, Image, Link, Text } from '@chakra-ui/react'

import { loginUrl } from '../../helpers/auth'
import logo from '../../images/logo.png'

export const Login = () => {
  return (
    <Flex direction="column" h="100vh" align="center" justifyContent="center" bg="primary.800">
      <Flex mb={12} alignItems="center">
        <Image src={logo} alt="Mission control logo" h="80px" mr={8} />
        <Text fontSize="48px" fontWeight="bold">
          Mission Control
        </Text>
      </Flex>
      <Button as={Link} href={loginUrl} variant="primary" size="lg" _hover={{ textDecoration: 'none' }}>
        Login
      </Button>
    </Flex>
  )
}
