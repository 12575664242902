import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Switch,
} from '@chakra-ui/react'
import type { UpdateCompetitionRequest } from '@clsplus/api-types/api-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import type { QueryClient } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { useLoaderData, useNavigate, useParams, useRevalidator } from 'react-router-dom'
import * as z from 'zod'

import { competitionDetailsQuery } from '../../../api/get-competition-details'
import { matchClassesRefDataQuery } from '../../../api/get-match-classes-ref-data'
import { matchFormatsRefDataQuery } from '../../../api/get-match-formats-ref-data'
import { organisationsRefDataQuery } from '../../../api/get-organisations-ref-data'
import { updateCompetitionDetails } from '../../../api/update-competition-details'
import { AgeGroups, CoverageLevels, Genders } from '../../../reference'
import { ComboBox } from '../../../ui/combo-box'
import { DatePicker } from '../../../ui/date-picker'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const [
      initialOrganisationsRefData,
      initialMatchClassesRefData,
      initialMatchFormatsRefData,
      initialCompetitionDetails,
    ] = await Promise.all([
      queryClient.ensureQueryData(organisationsRefDataQuery),
      queryClient.ensureQueryData(matchClassesRefDataQuery),
      queryClient.ensureQueryData(matchFormatsRefDataQuery),
      queryClient.ensureQueryData(competitionDetailsQuery(params.competitionId || '')),
    ])

    return {
      initialOrganisationsRefData,
      initialMatchClassesRefData,
      initialMatchFormatsRefData,
      initialCompetitionDetails,
    }
  }

const competitionEditSchema = z.object({
  organisation: z.string().uuid(),
  name: z.string().min(1, { message: 'Please enter a competition name' }),
  shortName: z.string(),
  coverageLevelId: z.coerce.number(),
  startDate: z.nullable(z.date()),
  endDate: z.nullable(z.date()),
  defaultMatchFormat: z.coerce.number(),
  defaultMatchClass: z.coerce.number(),
  ageGroup: z.preprocess(value => (value === '' ? null : Number(value)), z.nullable(z.number())),
  gender: z.preprocess(value => (value === '' ? null : Number(value)), z.nullable(z.number())),
  isUnofficial: z.boolean(),
})

type CompetitionEditFormData = z.infer<typeof competitionEditSchema>

export default function CompetitionEdit() {
  const params = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const revalidator = useRevalidator()
  const {
    initialOrganisationsRefData,
    initialMatchClassesRefData,
    initialMatchFormatsRefData,
    initialCompetitionDetails,
  } = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const competitionId = params.competitionId || ''

  const { data: competitionDetails } = useQuery({
    ...competitionDetailsQuery(competitionId),
    initialData: initialCompetitionDetails,
  })

  const { data: organisationsRefData } = useQuery({
    ...organisationsRefDataQuery,
    initialData: initialOrganisationsRefData,
  })

  const { data: matchClassesRefData } = useQuery({
    ...matchClassesRefDataQuery,
    initialData: initialMatchClassesRefData,
  })

  const { data: matchFormatsRefData } = useQuery({
    ...matchFormatsRefDataQuery,
    initialData: initialMatchFormatsRefData,
  })

  const updateCompetitionMutation = useMutation({
    mutationFn: (formData: UpdateCompetitionRequest) => updateCompetitionDetails(competitionId, formData),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['competitions'] }),
        queryClient.invalidateQueries({ queryKey: ['competition', competitionId] }),
      ])
      revalidator.revalidate()
      navigate(`/competitions/${competitionId}`)
    },
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<CompetitionEditFormData>({
    defaultValues: {
      organisation: competitionDetails.organisation?.id,
      name: competitionDetails.name,
      shortName: competitionDetails.shortName ?? '',
      coverageLevelId: competitionDetails.coverageLevel?.id,
      startDate: competitionDetails.startDate ? new Date(competitionDetails.startDate) : null,
      endDate: competitionDetails.endDate ? new Date(competitionDetails.endDate) : null,
      defaultMatchFormat: competitionDetails.defaultMatchFormat.id,
      defaultMatchClass: competitionDetails.defaultMatchClass.id,
      ageGroup: competitionDetails.ageGroup?.id,
      gender: competitionDetails.gender?.id,
      isUnofficial: !competitionDetails.isUnofficial,
    },
    resolver: zodResolver(competitionEditSchema),
  })

  const handleSubmitClick = (formData: CompetitionEditFormData) => {
    updateCompetitionMutation.mutate({
      ...formData,
      startDate: formData.startDate?.toISOString(),
      endDate: formData.endDate?.toISOString(),
      isUnofficial: !formData.isUnofficial,
    })
  }

  const organisationOptions = organisationsRefData.map(organisation => ({
    value: organisation.id,
    label: organisation.name,
  }))

  const matchClassOptions = matchClassesRefData.map(matchClass => ({
    value: `${matchClass.id}`,
    label: matchClass.name,
  }))

  const matchFormatOptions = matchFormatsRefData.map(format => ({ value: `${format.id}`, label: format.name }))

  return (
    <Flex direction="column" w="100%">
      <Heading as="h2" size="lg">
        {competitionDetails.name}
      </Heading>
      <Stack
        as="form"
        noValidate
        id="competition-edit"
        spacing={4}
        maxW="container.sm"
        mt={8}
        onSubmit={handleSubmit(handleSubmitClick)}
      >
        <FormControl isRequired isInvalid={!!errors.name}>
          <FormLabel>Name</FormLabel>
          <Input {...register('name')} />
          {errors.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
        </FormControl>
        <FormControl>
          <FormLabel>Coverage level</FormLabel>
          <Select w={48} {...register('coverageLevelId')}>
            {!initialCompetitionDetails.coverageLevel && <option value="">-</option>}
            {Object.entries(CoverageLevels).map(([key, value]) => (
              <option key={key} value={`${value}`}>
                {key}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Organisation</FormLabel>
          <Controller
            control={control}
            name="organisation"
            render={({ field: { onChange, value } }) => (
              <ComboBox
                isSingleSelect
                options={organisationOptions}
                onChange={onChange}
                selectedOptions={organisationOptions.filter(option => option.value === value)}
              />
            )}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Short name</FormLabel>
          <Input {...register('shortName')} />
        </FormControl>
        <FormControl>
          <FormLabel fontSize="sm">Start date</FormLabel>
          <Controller
            control={control}
            name="startDate"
            render={({ field: { onChange, value } }) => (
              <InputGroup isolation="auto">
                <Input
                  as={DatePicker}
                  showTimeInput
                  selected={value}
                  onChange={onChange}
                  dateFormat="dd/MM/yyyy h:mm aa"
                />
                <InputRightElement pointerEvents="none">
                  <Icon as={FontAwesomeIcon} icon={['fad', 'calendar-days']} color="primary.400" />
                </InputRightElement>
              </InputGroup>
            )}
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize="sm">End date</FormLabel>
          <Controller
            control={control}
            name="endDate"
            render={({ field: { onChange, value } }) => (
              <InputGroup isolation="auto">
                <Input
                  as={DatePicker}
                  showTimeInput
                  selected={value}
                  onChange={onChange}
                  dateFormat="dd/MM/yyyy h:mm aa"
                />
                <InputRightElement pointerEvents="none">
                  <Icon as={FontAwesomeIcon} icon={['fad', 'calendar-days']} color="primary.400" />
                </InputRightElement>
              </InputGroup>
            )}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Default match format</FormLabel>
          <Select w={48} {...register('defaultMatchFormat')}>
            {matchFormatOptions.map(mf => (
              <option key={mf.label} value={mf.value}>
                {mf.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Default match class</FormLabel>
          <Select w={48} {...register('defaultMatchClass')}>
            {matchClassOptions.map(mc => (
              <option key={mc.label} value={mc.value}>
                {mc.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Age group</FormLabel>
          <Select w={48} {...register('ageGroup')}>
            <option value="">-</option>
            {Object.entries(AgeGroups).map(([key, value]) => (
              <option key={key} value={`${value}`}>
                {key}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Gender</FormLabel>
          <Select w={48} {...register('gender')}>
            <option value="">-</option>
            {Object.entries(Genders).map(([key, value]) => (
              <option key={key} value={`${value}`}>
                {key}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Is official</FormLabel>
          <Switch variant="primary" {...register('isUnofficial')} />
        </FormControl>
      </Stack>
      <Stack direction="row" mt={8} spacing={4}>
        <Button
          type="submit"
          form="competition-edit"
          variant="primary"
          isDisabled={!isDirty}
          isLoading={updateCompetitionMutation.isLoading}
        >
          Save changes
        </Button>
        <Button
          variant="secondary"
          onClick={() => navigate(`/competitions/${competitionId}`)}
          isDisabled={updateCompetitionMutation.isLoading}
        >
          Cancel
        </Button>
      </Stack>
    </Flex>
  )
}
