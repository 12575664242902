import { Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { orderBy, take } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import type { Column, Row } from 'react-table'
import { useTable } from 'react-table'

import { idCleaner } from '../../../helpers/data'
import { getInningsBattingTeam, getPlayersInBattingOrder } from '../../../helpers/inning'
import { getBattingMinutes, getBattingScorecardStatus, getStrikeRate, useMatch } from '../../../helpers/match'
import type { BattingPerformance, Inning, MatchPlayer } from '../../../types/match'

type BattingProps = {
  inning: Inning
  condensed: boolean
}

type BattingRow = {
  player: MatchPlayer
  performance: BattingPerformance | undefined
  first_performance_order: number | null
}

export const Batting = ({ inning, condensed }: BattingProps) => {
  const match = useMatch()

  const [matchInfo, setMatchInfo] = useState(match)

  useEffect(() => {
    setMatchInfo(match)
  }, [match])

  const battingTeamPlayers = getInningsBattingTeam(inning, matchInfo.matchTeams)?.matchPlayers
  const teamPlayers: MatchPlayer[] | undefined = idCleaner(getPlayersInBattingOrder(battingTeamPlayers), 'PLAYER')
  const battingPerformances = inning.battingPerformances

  const columns: Column<BattingRow>[] = useMemo(
    () => [
      {
        id: 'batterNames',
        accessor: (row: BattingRow) => row.player.cardNameF,
        Header: 'Batting',
        Cell: ({ row }: { row: Row<BattingRow> }) => {
          return (
            <Stack direction={{ base: 'column', md: 'row' }} spacing={2}>
              <Text fontSize={condensed ? 'sm' : 'md'}>{row.original.player.cardNameF}</Text>
              <Text fontSize={condensed ? 'sm' : 'md'} color="primary.400">
                {row.original.performance?.text ||
                  getBattingScorecardStatus(
                    matchInfo,
                    row.original.performance?.notOut,
                    row.original.performance?.dismissal
                  )}
              </Text>
            </Stack>
          )
        },
      },
      {
        accessor: (row: BattingRow) => row.performance?.runs,
        Header: 'R',
        Cell: ({ row }: { row: Row<BattingRow> }) => {
          return (
            <Text width={condensed ? 8 : 14} margin="0 auto">
              {row.original.performance?.runs}
            </Text>
          )
        },
      },
      {
        accessor: (row: BattingRow) => row.performance?.balls,
        Header: 'B',
        Cell: ({ row }: { row: Row<BattingRow> }) => {
          return (
            <Text width={condensed ? 8 : 14} margin="0 auto">
              {row.original.performance?.balls}
            </Text>
          )
        },
      },
      ...(!condensed
        ? ([
            {
              accessor: (row: BattingRow) => getStrikeRate(row.performance),
              Header: 'SR',
              Cell: ({ row }: { row: Row<BattingRow> }) => {
                return (
                  <Text width={14} margin="0 auto">
                    {getStrikeRate(row.original.performance)}
                  </Text>
                )
              },
            },
            {
              accessor: (row: BattingRow) => row.performance?.dots,
              Header: '\u2022',
              Cell: ({ row }: { row: Row<BattingRow> }) => {
                return (
                  <Text width={12} margin="0 auto">
                    {row.original.performance?.dots}
                  </Text>
                )
              },
            },
            {
              accessor: (row: BattingRow) => row.performance?.ones,
              Header: '1',
              Cell: ({ row }: { row: Row<BattingRow> }) => {
                return (
                  <Text width={12} margin="0 auto">
                    {row.original.performance?.ones}
                  </Text>
                )
              },
            },
            {
              accessor: (row: BattingRow) => row.performance?.twos,
              Header: '2',
              Cell: ({ row }: { row: Row<BattingRow> }) => {
                return (
                  <Text width={12} margin="0 auto">
                    {row.original.performance?.twos}
                  </Text>
                )
              },
            },
            {
              accessor: (row: BattingRow) => row.performance?.threes,
              Header: '3',
              Cell: ({ row }: { row: Row<BattingRow> }) => {
                return (
                  <Text width={12} margin="0 auto">
                    {row.original.performance?.threes}
                  </Text>
                )
              },
            },
            {
              accessor: (row: BattingRow) => row.performance?.fours,
              Header: '4',
              Cell: ({ row }: { row: Row<BattingRow> }) => {
                return (
                  <Text width={12} margin="0 auto">
                    {row.original.performance?.fours}
                  </Text>
                )
              },
            },
            {
              accessor: (row: BattingRow) => row.performance?.sixes,
              Header: '6',
              Cell: ({ row }: { row: Row<BattingRow> }) => {
                return (
                  <Text width={12} margin="0 auto">
                    {row.original.performance?.sixes}
                  </Text>
                )
              },
            },
            {
              accessor: (row: BattingRow) => getBattingMinutes(row.performance),
              Header: 'Mins',
              Cell: ({ row }: { row: Row<BattingRow> }) => {
                return (
                  <Text width={14} margin="0 auto">
                    {getBattingMinutes(row.original.performance)}
                  </Text>
                )
              },
            },
          ] as const)
        : []),
    ],
    [condensed, matchInfo]
  )

  const data = useMemo(() => {
    return take(
      orderBy(
        teamPlayers?.map(p => {
          const perf = battingPerformances.find(b => b.playerMp === p.id)
          return {
            player: p,
            performance: perf,
            first_performance_order: perf ? perf.order : null,
          }
        }),
        ['first_performance_order'],
        ['asc']
      ),
      11
    )
  }, [battingPerformances, teamPlayers])
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data })

  return (
    <Table {...getTableProps()}>
      <Thead>
        {headerGroups.map(headerGroup => (
          // eslint-disable-next-line react/jsx-key
          <Tr {...headerGroup.getHeaderGroupProps()} bg="primary.600">
            {headerGroup.headers.map((column, idx) => (
              // eslint-disable-next-line react/jsx-key
              <Th
                {...column.getHeaderProps()}
                py={3}
                px={2}
                borderBottom="none"
                color="primary.200"
                textAlign={idx === 0 ? 'left' : 'center'}
              >
                {column.render('Header')}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            // eslint-disable-next-line react/jsx-key
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell, idx) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <Td
                    {...cell.getCellProps()}
                    py={3}
                    px={2}
                    borderBottom="1px solid"
                    borderColor="primary.600"
                    textAlign={idx === 0 ? 'left' : 'center'}
                  >
                    {cell.render('Cell')}
                  </Td>
                )
              })}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}
