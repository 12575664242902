import type { GetTeamsQuery, GetTeamsResponse } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

const GET_TEAMS_LIMIT = 50

function getTeams(query: GetTeamsQuery): Promise<GetTeamsResponse> {
  const tokens = getTokens()
  const url = new URL(`${import.meta.env.VITE_API_URL}admin/teams`)

  if (query) {
    Object.entries(query).forEach(([key, value]) => url.searchParams.append(key, value.toString()))
  }

  return fetch(url.toString(), {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const teamsQuery = (queryParams: GetTeamsQuery) => ({
  queryKey: ['teams', queryParams],
  queryFn: ({ pageParam = 0 }: { pageParam?: number }) =>
    getTeams({
      limit: GET_TEAMS_LIMIT.toString(),
      offset: pageParam.toString(),
      ...queryParams,
    }),
  getNextPageParam: (lastPage: GetTeamsResponse, pages: GetTeamsResponse[]) => {
    if (lastPage.data.length < GET_TEAMS_LIMIT) return
    return pages.length * GET_TEAMS_LIMIT
  },
})
