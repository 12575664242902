import { Box, Grid, Heading } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { idCleaner } from '../../../helpers/data'
import { getInningsBattingTeam, getPlayersInBattingOrder } from '../../../helpers/inning'
import { useMatch } from '../../../helpers/match'
import type { Inning, MatchPlayer } from '../../../types/match'
import { PartnershipCard } from '../../partnership-card'

type PartnershipsProps = {
  inning: Inning
  condensed: boolean
}

export const Partnerships = ({ inning, condensed }: PartnershipsProps) => {
  const match = useMatch()

  const [matchInfo, setMatchInfo] = useState(match)

  useEffect(() => {
    setMatchInfo(match)
  }, [match])

  const matchPlayers = getInningsBattingTeam(inning, matchInfo.matchTeams)?.matchPlayers
  const teamPlayers: MatchPlayer[] = idCleaner(getPlayersInBattingOrder(matchPlayers), 'PLAYER')

  return (
    <Box>
      <Heading as="h3" size="md">
        Partnerships
      </Heading>
      <Grid templateColumns={condensed ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'} gap={condensed ? 3 : 6} mt={3}>
        {inning.partnerships &&
          inning.partnerships
            .sort((a, b) => a.wicketNumber - b.wicketNumber)
            .map(p => (
              <PartnershipCard
                key={`partnership_${p.wicketNumber}`}
                partnership={p}
                teamPlayers={teamPlayers}
                condensed={condensed}
              />
            ))}
      </Grid>
    </Box>
  )
}
