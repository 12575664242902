import type { GetMatchesQuery, GetMatchesResponse } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

const GET_MATCHES_LIMIT = 50

function getMatches(query: GetMatchesQuery): Promise<GetMatchesResponse> {
  const tokens = getTokens()
  const url = new URL(`${import.meta.env.VITE_API_URL}admin/matches`)

  if (query) {
    Object.entries(query).forEach(([key, value]) => url.searchParams.append(key, value.toString()))
  }

  return fetch(url.toString(), {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const matchesQuery = (queryParams: GetMatchesQuery) => ({
  queryKey: ['matches', queryParams],
  queryFn: ({ pageParam = 0 }: { pageParam?: number }) =>
    getMatches({
      limit: GET_MATCHES_LIMIT.toString(),
      offset: pageParam.toString(),
      ...queryParams,
    }),
  getNextPageParam: (lastPage: GetMatchesResponse, pages: GetMatchesResponse[]) => {
    if (lastPage.data.length < GET_MATCHES_LIMIT) return
    return pages.length * GET_MATCHES_LIMIT
  },
})
