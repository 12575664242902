import { Button, Flex, Icon, InputGroup, InputLeftElement, InputRightElement, Stack, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { QueryClient } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import type { ChangeEvent } from 'react'
import { useState } from 'react'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom'

import { matchCentreMatchesQuery } from '../../api/get-match-centre-matches'
import { OpsMatchesView } from '../../components/ops-matches-view'
import { formatShortDate } from '../../helpers/datetime'
import { DatePicker } from '../../ui/date-picker'
import { Input } from '../../ui/input'

function getDatesFromSearchParams(searchParams: URLSearchParams) {
  const startDate = searchParams.has('start') ? new Date(searchParams.get('start') || '') : new Date()
  const endDate = searchParams.has('end') ? new Date(searchParams.get('end') || '') : null
  const startDateLocale = formatShortDate(startDate)
  const endDateLocale = endDate !== null ? formatShortDate(endDate) : formatShortDate(startDate)

  return {
    startDate,
    endDate,
    startDateUTC: new Date(`${startDateLocale} 00:00:00`).toISOString(),
    endDateUTC: new Date(`${endDateLocale} 23:59:59`).toISOString(),
  }
}

export const loader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const searchParams = new URL(request.url).searchParams
    const { startDateUTC, endDateUTC } = getDatesFromSearchParams(searchParams)
    return await queryClient.ensureQueryData(matchCentreMatchesQuery({ startdate: startDateUTC, enddate: endDateUTC }))
  }

const MatchCenter = observer(() => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [userMatchId, setUserMatchId] = useState('')

  const initialMatches = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>
  const { startDate, endDate, startDateUTC, endDateUTC } = getDatesFromSearchParams(searchParams)

  const { data: games } = useQuery({
    ...matchCentreMatchesQuery({ startdate: startDateUTC, enddate: endDateUTC }),
    initialData: initialMatches,
  })

  const handleClick = () => {
    if (userMatchId) {
      navigate(`/match-centre/${userMatchId}`)
    }
  }

  const handleUserMatchIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event) {
      setUserMatchId(event.target.value)
    }
  }

  const handleStartDateChange = (date: Date) => {
    // Prevent user from setting an invalid date range
    const overrideEndDate = !endDate || (endDate && date > endDate)

    const params = new URLSearchParams({ start: date.toISOString() })
    if (endDate || overrideEndDate) {
      params.append('end', overrideEndDate ? date.toISOString() : endDate.toISOString())
    } else {
      params.delete('end')
    }
    setSearchParams(params)
  }

  const handleEndDateChange = (date: Date) => {
    // Prevent user from setting an invalid date range
    const overrideStartDate = date && date < startDate

    const params = new URLSearchParams({ end: date.toISOString() })
    if (startDate || overrideStartDate) {
      params.append('start', overrideStartDate ? date.toISOString() : startDate.toISOString())
    } else {
      params.delete('start')
    }
    setSearchParams(params)
  }

  return (
    <Flex direction="column" flex={1} w="100%">
      <Stack direction={{ base: 'column', xl: 'row' }} mb={8} spacing={6}>
        <InputGroup flex={{ base: '1', xl: '1 0 280px' }}>
          <InputLeftElement pointerEvents="none">
            <Icon as={FontAwesomeIcon} icon={['fas', 'search']} color="primary.400" />
          </InputLeftElement>
          <Input placeholder="Match ID" value={userMatchId} onChange={handleUserMatchIdChange} />
          <InputRightElement w="64px" mx={1}>
            <Button size="xs" isDisabled={!userMatchId} onClick={handleClick}>
              Search
            </Button>
          </InputRightElement>
        </InputGroup>
        <Stack direction="row" spacing={4}>
          <Flex direction="row" alignItems="center">
            <Text mr={2}>From:</Text>
            <InputGroup>
              <Input
                as={DatePicker}
                selected={startDate}
                // @ts-ignore - Chakra UI has a TS issue when forwarding the DatePicker props type using `as`
                onChange={handleStartDateChange}
                onChangeRaw={e => e.preventDefault()}
                startDate={startDate}
                dateFormat="dd/MM/yyyy"
              />
              <InputRightElement pointerEvents="none">
                <Icon as={FontAwesomeIcon} icon={['fad', 'calendar-days']} color="primary.400" />
              </InputRightElement>
            </InputGroup>
          </Flex>
          <Flex direction="row" alignItems="center">
            <Text mr={2}>To:</Text>
            <InputGroup>
              <Input
                as={DatePicker}
                selected={endDate || startDate}
                // @ts-ignore - Chakra UI has a TS issue when forwarding the DatePicker props type using `as`
                onChange={handleEndDateChange}
                onChangeRaw={e => e.preventDefault()}
                startDate={endDate || startDate}
                minDate={startDate}
                dateFormat="dd/MM/yyyy"
              />
              <InputRightElement pointerEvents="none">
                <Icon as={FontAwesomeIcon} icon={['fad', 'calendar-days']} color="primary.400" />
              </InputRightElement>
            </InputGroup>
          </Flex>
        </Stack>
      </Stack>
      <OpsMatchesView games={games} />
    </Flex>
  )
})

export default MatchCenter
