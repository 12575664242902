import { Box, Heading, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react'
import React from 'react'

import { getInningNotesInOrder } from '../../helpers/inning'
import type { InningNotes, Match } from '../../types/match'

type MatchNotesProps = {
  notes: Match['matchNotes']
}

export const MatchNotes = ({ notes }: MatchNotesProps) => {
  if (!notes) return null

  const parsedNotes = typeof notes === 'string' ? (JSON.parse(notes) as InningNotes[]) : notes

  return (
    <Box mt={8}>
      <Heading as="h3" size="md">
        Match notes
      </Heading>
      <Stack spacing={3} mt={4}>
        {parsedNotes.map(inningNotes => (
          <React.Fragment key={inningNotes.inningsId}>
            <Text fontWeight="bold">{inningNotes.name}</Text>
            <UnorderedList listStylePosition="inside" spacing={0.5}>
              {getInningNotesInOrder(inningNotes).map(note => (
                <ListItem key={`${inningNotes.inningsId}-${note.order}`}>
                  <Text as="span" fontSize="md" textStyle="label">
                    {note.detail}
                  </Text>
                </ListItem>
              ))}
            </UnorderedList>
          </React.Fragment>
        ))}
      </Stack>
    </Box>
  )
}
