import { FormControl, FormLabel, Text } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { isValidElement } from 'react'

type DetailValueProps = {
  label: string
  value: ReactNode
}

export function DetailValue({ label, value }: DetailValueProps) {
  const isReactElementOrElementArray = value instanceof Array ? value.every(isValidElement) : isValidElement(value)

  return (
    <FormControl>
      <FormLabel fontWeight="bold" fontSize="sm">
        {label}
      </FormLabel>
      {isReactElementOrElementArray ? value : <Text>{value ?? '-'}</Text>}
    </FormControl>
  )
}
