import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  List,
  ListIcon,
  ListItem,
  Stack,
} from '@chakra-ui/react'
import type { CompetitionTeamSquadMember } from '@clsplus/api-types/api-admin'
import { faLink, faSquareCheck, faSquareXmark } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { QueryClient } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Link as RouterLink, useLoaderData, useParams } from 'react-router-dom'

import { competitionTeamDetailsQuery } from '../../../../api/get-competition-team-details'
import { DetailValue } from '../../../../components/detail-value'
import { HasMCAccess, MCRequiredLevelEnum } from '../../../../helpers/auth'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    return await queryClient.ensureQueryData(
      competitionTeamDetailsQuery(params.competitionId || '', params.teamId || '')
    )
  }

export default function CompetitionTeamDetails() {
  const params = useParams()
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const { data: competitionTeam } = useQuery({
    ...competitionTeamDetailsQuery(params.competitionId || '', params.teamId || ''),
    initialData,
  })

  const getSquadMembers = (squad: CompetitionTeamSquadMember[] | undefined) => {
    const players =
      squad
        ?.filter(member => {
          return member.isPlayer
        })
        .sort((a, b) => {
          if (a.person.fullName && b.person.fullName) {
            if (a.person.fullName < b.person.fullName) {
              return -1
            }
            if (a.person.fullName > b.person.fullName) {
              return 1
            }
          }
          return 0
        }) || []

    const nonPlayers =
      squad
        ?.filter(member => {
          return !member.isPlayer
        })
        .sort((a, b) => {
          if (a.person.fullName && b.person.fullName) {
            if (a.person.fullName < b.person.fullName) {
              return -1
            }
            if (a.person.fullName > b.person.fullName) {
              return 1
            }
          }
          return 0
        }) || []

    const getPersonLink = (id: string) => {
      return (
        <List>
          <ListItem key={id}>
            <ListIcon as={FontAwesomeIcon} icon={faLink} color="gray.400" />
            <Link fontWeight={'normal'} as={RouterLink} to={`/people/${id}`}>
              {id}
            </Link>
          </ListItem>
        </List>
      )
    }

    const accordionItems = players.concat(nonPlayers).map(member => {
      return (
        <>
          <AccordionItem>
            <Heading as="h3">
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" fontWeight="normal" fontSize="xl">
                  {member.person.fullName}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Heading>

            <AccordionPanel pb={4}>
              <Stack direction="column" spacing="24px">
                <DetailValue label="Person ID" value={getPersonLink(member.person.id)} />
                <DetailValue label="Shirt Number" value={member.shirtNumber} />
                <DetailValue label="Nationality" value={member.nationality?.name} />
                <DetailValue label="Country Code" value={member.nationality?.code} />
                <DetailValue
                  label="Is Player?"
                  value={<FontAwesomeIcon icon={member.isPlayer ? faSquareCheck : faSquareXmark} />}
                />
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </>
      )
    })

    return (
      <>
        <Accordion allowMultiple>{accordionItems}</Accordion>
      </>
    )
  }

  return (
    <>
      <Flex w="100%" justifyContent="space-between" alignItems="baseline">
        <Heading as="h2" size="lg">
          {competitionTeam.name}
        </Heading>

        <HasMCAccess minLevel={MCRequiredLevelEnum['clsp-superadmin']}>
          <Button
            as={RouterLink}
            to={`/competitions/${competitionTeam.competition.id}/team/${competitionTeam.id}/edit`}
            variant="primary"
            minW={32}
            leftIcon={<Icon as={FontAwesomeIcon} icon={['fad', 'pen-to-square']} />}
          >
            Edit
          </Button>
        </HasMCAccess>
      </Flex>
      <Stack w="100%" mt={6} spacing={6}>
        <DetailValue label="CLS+ ID" value={competitionTeam.id} />
        <DetailValue label="Squad" value={getSquadMembers(competitionTeam.squad)} />
      </Stack>
    </>
  )
}
