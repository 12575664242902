import type { MatchPlayer, MatchTeam } from '../types/match'

export function getPlayerFullName(player: MatchPlayer) {
  return (
    player.cardNameF ||
    player.player.person?.cardNameF ||
    `${player.player.person?.firstName} ${player.player.person?.lastName}`
  )
}

export function getPlayerShortName(player: MatchPlayer | null) {
  if (!player) return 'Unknown player'
  return player.cardNameS || player.player.person?.cardNameS || player.player.person?.firstName
}

export function getPlayer(matchTeams: MatchTeam[], playerId: string | null | undefined) {
  if (!playerId) return null

  return (
    matchTeams
      ?.map(team => team.matchPlayers ?? [])
      .flat()
      .find(player => player.id === playerId) ?? null
  )
}

export function getPlayerDisplayName(
  matchTeams: MatchTeam[],
  playerId: string | null | undefined,
  showSubPrefix?: boolean
) {
  const player = getPlayer(matchTeams, playerId)
  if (!player) return ''

  return showSubPrefix && player.substitute ? `sub (${getPlayerFullName(player)})` : getPlayerFullName(player)
}
