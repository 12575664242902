import { Button, Code, FormControl, FormLabel, Heading, Icon, Link, Select, Stack } from '@chakra-ui/react'
import type { GetPeopleResponse, PersonBase } from '@clsplus/api-types/api-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { InfiniteData, QueryClient } from '@tanstack/react-query'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import type { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Link as RouterLink, useLoaderData, useSearchParams } from 'react-router-dom'

import { peopleQuery } from '../../../api/get-people'
import { teamsRefDataQuery } from '../../../api/get-teams-ref-data'
import { ListPageFilters } from '../../../components/list-page-filters'
import { ComboBox } from '../../../ui/combo-box'
import { Input } from '../../../ui/input'
import { NonIdealState } from '../../../ui/non-ideal-state'
import { Table } from '../../../ui/table'

type PeopleFilters = {
  deleted: string
  gender: string
  id: string
  intId: string
  name: string
  middleName: string
  nationality: string
  personType: string
  teamName: string
}

export const loader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const urlParams = Object.fromEntries(new URL(request.url).searchParams)
    const peopleQueryOpts = peopleQuery(urlParams)

    const [initialTeamsRefData, initialPeopleData] = await Promise.all([
      queryClient.ensureQueryData(teamsRefDataQuery),
      queryClient.getQueryData<InfiniteData<GetPeopleResponse>>(peopleQueryOpts.queryKey) ??
        (await queryClient.fetchInfiniteQuery(peopleQueryOpts)),
    ])

    return {
      initialPeopleData,
      initialTeamsRefData,
    }
  }

export default function People() {
  const [searchParams, setSearchParams] = useSearchParams()

  const params = Object.fromEntries(searchParams)
  const paramsCount = Object.keys(params).length

  const { initialPeopleData, initialTeamsRefData } = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const { data: people, ...peopleQueryResult } = useInfiniteQuery({
    ...peopleQuery(params),
    initialData: initialPeopleData,
  })

  const { data: teamsRefData } = useQuery({
    ...teamsRefDataQuery,
    initialData: initialTeamsRefData,
  })

  const filtersFormDefaultValues = {
    deleted: params.deleted ?? '',
    gender: params.gender ?? '',
    id: params.id ?? '',
    intId: params.intId ?? '',
    name: params.name ?? '',
    middleName: params.middleName ?? '',
    nationality: params.nationality ?? '',
    personType: params.personType ?? '',
    teamName: params.teamName ?? '',
  }

  const { control, register, handleSubmit, reset } = useForm<PeopleFilters>({
    values: filtersFormDefaultValues,
  })

  const handleDismissFilters = () => reset(filtersFormDefaultValues)

  const handleResetClick = () => {
    reset(filtersFormDefaultValues)
    setSearchParams({})
  }

  const handleSubmitClick = (formData: PeopleFilters) => {
    const newSearchParams = Object.entries(formData).reduce<Record<string, string>>(
      (acc, [key, val]) => ({
        ...(val && { [key]: val }),
        ...acc,
      }),
      {}
    )

    setSearchParams(newSearchParams)
  }

  const rows = people?.pages.flatMap(page => [...page.data])

  const columns: ColumnDef<PersonBase>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row }) => (
          <Link as={RouterLink} to={row.original.id}>
            {row.original.name}
          </Link>
        ),
      },
      {
        accessorKey: 'middleName',
        header: 'Middle Name',
      },
      {
        accessorKey: 'personType',
        accessorFn: row => {
          if (row.isPlayer && row.isOfficial) return 'Player & Official'
          if (row.isPlayer) return 'Player'
          if (row.isOfficial) return 'Official'
          return ''
        },
        header: 'Type',
      },
      {
        accessorKey: 'gender',
        header: 'Gender',
      },
      {
        accessorKey: 'nationality',
        header: 'Nationality',
        cell: ({ row }) => row.original.nationality?.code ?? '-',
      },
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'intId',
        accessorFn: row => row.integrationIds.SDS,
        header: 'SDS ID',
      },
      {
        accessorKey: 'deleted',
        accessorFn: row => (row.isDeleted ? 'true' : 'false'),
        header: 'Deleted',
        cell: ({ row }) => (row.original.isDeleted ? 'Yes' : 'No'),
      },
    ],
    []
  )

  const teamOptions = teamsRefData.map(team => ({ value: team.name, label: team.name }))

  return (
    <>
      <Heading as="h2" size="lg">
        People
      </Heading>
      <Stack w="100%" spacing={8} my={6}>
        <ListPageFilters
          formId="people-filters"
          onDismiss={handleDismissFilters}
          onReset={handleResetClick}
          onSubmit={handleSubmit(handleSubmitClick)}
          title="Filter people"
        >
          <FormControl>
            <FormLabel>Type</FormLabel>
            <Select {...register('personType')}>
              <option value="">-</option>
              <option value="player">Player</option>
              <option value="official">Official</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Person ID</FormLabel>
            <Input {...register('id')} />
          </FormControl>
          <FormControl>
            <FormLabel>SDS ID</FormLabel>
            <Input {...register('intId')} />
          </FormControl>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input {...register('name')} />
          </FormControl>
          <FormControl>
            <FormLabel>Middle Name</FormLabel>
            <Input {...register('middleName')} />
          </FormControl>
          <FormControl>
            <FormLabel>Nationality</FormLabel>
            <Input {...register('nationality')} />
          </FormControl>
          <FormControl>
            <FormLabel>Gender</FormLabel>
            <Select {...register('gender')}>
              <option value="">-</option>
              <option value="F">Female</option>
              <option value="M">Male</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Team</FormLabel>
            <Controller
              control={control}
              name="teamName"
              render={({ field: { onChange, value } }) => (
                <ComboBox
                  isSingleSelect
                  options={teamOptions}
                  onChange={onChange}
                  selectedOptions={teamOptions.filter(option => option.value === value)}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Deleted</FormLabel>
            <Select {...register('deleted')}>
              <option value="">-</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Select>
          </FormControl>
        </ListPageFilters>
        {peopleQueryResult.isError ? (
          <NonIdealState
            title="Error fetching people"
            description="Here's what we know:"
            iconColor="red.600"
            icon={['fad', 'triangle-exclamation']}
          >
            <Code mt={4} px={6} py={4} borderRadius="6px" bg="primary.600">
              {peopleQueryResult.error instanceof Error
                ? peopleQueryResult.error.message
                : 'An unknown error occurred. Please contact a system administrator.'}
            </Code>
            <Button
              mt={4}
              variant="secondary"
              leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'xmark-large']} />}
              onClick={handleResetClick}
            >
              Reset filters
            </Button>
          </NonIdealState>
        ) : rows?.length ? (
          <Table columns={columns} data={rows} />
        ) : (
          <NonIdealState
            title="No people found"
            description={
              paramsCount > 0 ? "We couldn't find any people matching your filters" : "We couldn't find any people"
            }
            iconColor="primary.400"
            icon={['fas', 'face-confused']}
          >
            {paramsCount > 0 && (
              <Button
                mt={4}
                variant="secondary"
                leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'xmark-large']} />}
                onClick={handleResetClick}
              >
                Reset filters
              </Button>
            )}
          </NonIdealState>
        )}
        {peopleQueryResult.isSuccess && peopleQueryResult.hasNextPage && (
          <Button
            mt={8}
            variant="primary"
            leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'ellipsis']} />}
            isLoading={peopleQueryResult.isFetchingNextPage}
            onClick={() => peopleQueryResult.fetchNextPage()}
          >
            Load more
          </Button>
        )}
      </Stack>
    </>
  )
}
