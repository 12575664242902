import type { PersonDetailed } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

function getPersonDetails(personId: string): Promise<PersonDetailed> {
  const tokens = getTokens()

  return fetch(`${import.meta.env.VITE_API_URL}admin/people/${personId}`, {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const personDetailsQuery = (personId: string) => ({
  queryKey: ['person', personId],
  queryFn: () => getPersonDetails(personId),
})
