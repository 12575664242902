import { Flex, Text } from '@chakra-ui/react'
import { capitalize } from 'lodash'

type ModeHeadingProps = {
  mode: string
}

export const ModeHeading = ({ mode }: ModeHeadingProps) => {
  return (
    <Flex w="100%" flexDirection="row" mb={3}>
      <Text as="h2" w="100%" px={2} fontWeight="bold" fontSize="xl" textAlign="center">
        {`${capitalize(mode)} mode`}
      </Text>
    </Flex>
  )
}
