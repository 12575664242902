import { replace } from 'lodash'

import { getExtraType } from '../../helpers/ball'
import { ExtrasTypeAbbreviations } from '../../reference'
import type { Ball } from '../../types/ball'

export const BallSummary = (ball: Ball) => {
  let summary: string | number = 0
  const extraType = getExtraType(ball)

  if (ball.dismissal) {
    summary = 'W'
    if (ball.runsBat) {
      summary = `${summary} + ${ball.runsBat}`
    }
    if (ball.runsExtra) {
      const abbr = ExtrasTypeAbbreviations.find(ref => ref.key === extraType)
      if (!abbr) return `${summary} + ${ball.runsExtra}`
      if (extraType === 'NO_BALL_LEG_BYE' || extraType === 'NO_BALL_BYE') {
        abbr.value = replace(`${ball.runsExtra - 1}`, '#', abbr.value)
        return `${summary}+${abbr.value}`
      }
      summary = `${summary}+${ball.runsExtra === 1 ? '' : ball.runsExtra}${abbr.value}`
    }
    return summary
  }

  if (ball.runsBat) {
    summary = `${ball.runsBat}`
  }
  if (ball.runsExtra) {
    const abbr = ExtrasTypeAbbreviations.find(ref => ref.key === extraType)
    if (!abbr) {
      return `${ball.runsExtra}`
    }
    if (extraType === 'NO_BALL_LEG_BYE' || extraType === 'NO_BALL_BYE') {
      summary = replace(abbr.value, '#', ball.runsExtra - 1 > 1 ? `${ball.runsExtra - 1}` : '')
    } else {
      if (summary !== 0) {
        summary = `${abbr.value}+${summary}`
      } else {
        summary = `${ball.runsExtra === 1 ? '' : ball.runsExtra}${abbr.value}`
      }
    }
  }

  if (typeof summary === 'string') {
    return parseInt(summary) === 0 ? '•' : summary
  } else {
    return summary === 0 ? '•' : summary.toString()
  }
}
