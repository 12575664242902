import type { CompetitionDetailed, UpdateCompetitionRequest } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

export function updateCompetitionDetails(
  competitionId: string,
  formData: UpdateCompetitionRequest
): Promise<CompetitionDetailed> {
  const tokens = getTokens()
  return fetch(`${import.meta.env.VITE_API_URL}admin/competitions/${competitionId}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${tokens?.accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}
