import { getTokens } from '../../helpers/auth'
import type { BallInning } from '../../types/ball'

type GetBallInningsParams = {
  id: string
  mode: 'main' | 'betting'
}

function getBallInnings(params: GetBallInningsParams): Promise<BallInning[]> {
  const tokens = getTokens()
  const { id, mode } = params

  return fetch(`${import.meta.env.VITE_API_URL}balls/${id}?mode=${mode}`, {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const ballInningsQuery = (params: GetBallInningsParams) => ({
  queryKey: ['ball-inning', params],
  queryFn: () => getBallInnings(params),
})
