import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack } from '@chakra-ui/react'
import type { UpdateTeamRequest } from '@clsplus/api-types/api-admin'
import { zodResolver } from '@hookform/resolvers/zod'
import type { QueryClient } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { useLoaderData, useNavigate, useParams, useRevalidator } from 'react-router-dom'
import * as z from 'zod'

import { organisationsRefDataQuery } from '../../../api/get-organisations-ref-data'
import { teamDetailsQuery } from '../../../api/get-team-details'
import { updateTeamDetails } from '../../../api/update-team-details'
import { ComboBox } from '../../../ui/combo-box'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const [initialOrganisationsRefData, initialTeamDetails] = await Promise.all([
      queryClient.ensureQueryData(organisationsRefDataQuery),
      queryClient.ensureQueryData(teamDetailsQuery(params.teamId || '')),
    ])

    return {
      initialOrganisationsRefData,
      initialTeamDetails,
    }
  }

const teamEditSchema = z.object({
  defaultMatchTeamName: z.string(),
  defaultMatchTeamShortName: z.string(),
  description: z.string(),
  name: z.string().min(1, { message: 'Please enter a team name' }),
  organisation: z.string().uuid({
    message: "Please select an organisation. Select 'No Organisation' if the team is not part of an organisation.",
  }),
  shortName: z.string(),
})

type TeamEditFormData = z.infer<typeof teamEditSchema>

export default function TeamEdit() {
  const params = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const revalidator = useRevalidator()
  const { initialOrganisationsRefData, initialTeamDetails } = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >

  const teamId = params.teamId || ''

  const { data: teamDetails } = useQuery({
    ...teamDetailsQuery(teamId),
    initialData: initialTeamDetails,
  })

  const { data: organisationsRefData } = useQuery({
    ...organisationsRefDataQuery,
    initialData: initialOrganisationsRefData,
  })

  const updateTeamMutation = useMutation({
    mutationFn: (formData: UpdateTeamRequest) => updateTeamDetails(teamId, formData),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['teams'] }),
        queryClient.invalidateQueries({ queryKey: ['team', teamId] }),
      ])
      revalidator.revalidate()
      navigate(`/teams/${teamId}`)
    },
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { dirtyFields, errors, isDirty },
  } = useForm<TeamEditFormData>({
    defaultValues: {
      defaultMatchTeamName: teamDetails.defaultMatchTeamName ?? '',
      defaultMatchTeamShortName: teamDetails.defaultMatchTeamShortName ?? '',
      description: teamDetails.description ?? '',
      name: teamDetails.name,
      organisation: teamDetails.organisation.id,
      shortName: teamDetails.shortName ?? '',
    },
    resolver: zodResolver(teamEditSchema),
  })

  const handleSubmitClick = (formData: TeamEditFormData) => {
    const changedFields: Partial<TeamEditFormData> = Object.fromEntries(
      Object.entries(formData).filter(([key]) => key in dirtyFields)
    )

    updateTeamMutation.mutate(changedFields)
  }

  const organisationOptions = organisationsRefData.map(organisation => ({
    value: organisation.id,
    label: organisation.name,
  }))

  return (
    <Flex direction="column" w="100%">
      <Heading as="h2" size="lg">
        {teamDetails.name}
      </Heading>
      <Stack
        as="form"
        noValidate
        id="team-edit"
        spacing={4}
        maxW="container.sm"
        mt={8}
        onSubmit={handleSubmit(handleSubmitClick)}
      >
        <FormControl isRequired isInvalid={!!errors.name}>
          <FormLabel>Name</FormLabel>
          <Input {...register('name')} />
          {errors.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.shortName}>
          <FormLabel>Short name</FormLabel>
          <Input {...register('shortName')} />
          {errors.shortName && <FormErrorMessage>{errors.shortName.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.defaultMatchTeamName}>
          <FormLabel>Default match team name</FormLabel>
          <Input {...register('defaultMatchTeamName')} />
          {errors.defaultMatchTeamName && <FormErrorMessage>{errors.defaultMatchTeamName.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.defaultMatchTeamShortName}>
          <FormLabel>Default match team short name</FormLabel>
          <Input {...register('defaultMatchTeamShortName')} />
          {errors.defaultMatchTeamShortName && (
            <FormErrorMessage>{errors.defaultMatchTeamShortName.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors.description}>
          <FormLabel>Description</FormLabel>
          <Input {...register('description')} />
          {errors.description && <FormErrorMessage>{errors.description.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.organisation}>
          <FormLabel>Organisation</FormLabel>
          <Controller
            control={control}
            name="organisation"
            render={({ field: { onChange, value } }) => (
              <ComboBox
                isSingleSelect
                options={organisationOptions}
                onChange={onChange}
                selectedOptions={organisationOptions.filter(option => option.value === value)}
              />
            )}
          />
          {errors.organisation && <FormErrorMessage>{errors.organisation.message}</FormErrorMessage>}
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={4} mt={8}>
        <Button
          type="submit"
          form="team-edit"
          variant="primary"
          isDisabled={!isDirty}
          isLoading={updateTeamMutation.isLoading}
        >
          Save changes
        </Button>
        <Button
          variant="secondary"
          onClick={() => navigate(`/teams/${teamId}`)}
          isDisabled={updateTeamMutation.isLoading}
        >
          Cancel
        </Button>
      </Stack>
    </Flex>
  )
}
