import type { CompetitionStageDetailed } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

function getCompetitionStageDetails(competitionId: string, stageId: string): Promise<CompetitionStageDetailed> {
  const tokens = getTokens()

  return fetch(`${import.meta.env.VITE_API_URL}admin/competitions/${competitionId}/competition-stages/${stageId}`, {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const competitionStageDetailsQuery = (competitionId: string, stageId: string) => ({
  queryKey: ['competition', competitionId, 'stage', stageId],
  queryFn: () => getCompetitionStageDetails(competitionId, stageId),
})
