import type { StackProps } from '@chakra-ui/react'
import { Icon, Link, Stack, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link as RouterLink, useMatches } from 'react-router-dom'

import type { RouteMatchWithHandle } from '../../types'

export function Breadcrumb({ ...props }: StackProps) {
  const matches = useMatches()

  const crumbs = matches
    .filter((match): match is RouteMatchWithHandle => Boolean(match.handle))
    .map(match => match.handle.crumb?.(match.data))

  return (
    <Stack direction="row" alignItems="center" wrap="wrap" divider={<Text mx={3}>/</Text>} {...props}>
      <BreadcrumbLink to="/">
        <Icon as={FontAwesomeIcon} icon={['fad', 'house']} boxSize={5} color="white" />
      </BreadcrumbLink>
      {crumbs}
    </Stack>
  )
}

export function BreadcrumbLink({ to, children }: { to: string; children: React.ReactNode }) {
  return (
    <Link as={RouterLink} to={to} fontSize="sm">
      {children}
    </Link>
  )
}
