import type { CompetitionPoolDetailed } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

function getCompetitionPoolDetails(
  competitionId: string,
  stageId: string,
  poolId: string
): Promise<CompetitionPoolDetailed> {
  const tokens = getTokens()

  return fetch(
    `${
      import.meta.env.VITE_API_URL
    }admin/competitions/${competitionId}/competition-stages/${stageId}/competition-pools/${poolId}`,
    {
      headers: { Authorization: `Bearer ${tokens?.accessToken}` },
    }
  )
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const competitionPoolDetailsQuery = (competitionId: string, stageId: string, poolId: string) => ({
  queryKey: ['competition', competitionId, 'stage', stageId, 'pool', poolId],
  queryFn: () => getCompetitionPoolDetails(competitionId, stageId, poolId),
})
