import type { GetOrganisationsQuery, GetOrganisationsResponse } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

const GET_ORGANISATIONS_LIMIT = 50

function getOrganisations(query: GetOrganisationsQuery): Promise<GetOrganisationsResponse> {
  const tokens = getTokens()
  const url = new URL(`${import.meta.env.VITE_API_URL}admin/organisations`)

  if (query) {
    Object.entries(query).forEach(([key, value]) => url.searchParams.append(key, value.toString()))
  }

  return fetch(url.toString(), {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const organisationsQuery = (queryParams: GetOrganisationsQuery) => ({
  queryKey: ['organisations', queryParams],
  queryFn: ({ pageParam = 0 }: { pageParam?: number }) =>
    getOrganisations({
      limit: GET_ORGANISATIONS_LIMIT.toString(),
      offset: pageParam.toString(),
      ...queryParams,
    }),
  getNextPageParam: (lastPage: GetOrganisationsResponse, pages: GetOrganisationsResponse[]) => {
    if (lastPage.data.length < GET_ORGANISATIONS_LIMIT) return
    return pages.length * GET_ORGANISATIONS_LIMIT
  },
})
