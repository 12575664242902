import type { S3PBallPathQuery, S3PBallResponse } from '@clsplus/api-types/s3p-manage'

import { getTokens } from '../../helpers/auth'

function getS3PBallDiagnosticData(query: S3PBallPathQuery): Promise<Array<S3PBallResponse>> {
  const tokens = getTokens()
  const url = new URL(`${import.meta.env.VITE_API_URL}s3p/diagnostics/ball`)

  if (query) {
    Object.entries(query).forEach(([key, value]) => url.searchParams.append(key, value.toString()))
  }

  return fetch(url.toString(), {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const getS3PBallQuery = (queryParams: S3PBallPathQuery | undefined) => ({
  queryKey: ['s3p-ball', queryParams],
  queryFn: () =>
    queryParams
      ? getS3PBallDiagnosticData({
          ...queryParams,
        })
      : null,
})
