import type { CompetitionTeamDetailed } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

function getCompetitionTeamDetails(competitionId: string, teamId: string): Promise<CompetitionTeamDetailed> {
  const tokens = getTokens()

  return fetch(`${import.meta.env.VITE_API_URL}admin/competitions/${competitionId}/competition-teams/${teamId}`, {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const competitionTeamDetailsQuery = (competitionId: string, teamId: string) => ({
  queryKey: ['competition', competitionId, 'team', teamId],
  queryFn: () => getCompetitionTeamDetails(competitionId, teamId),
})
