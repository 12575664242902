import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

export function formatShortDate(date: Date): string {
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })

  const { day, month, year } = dateFormatter.formatToParts(date).reduce<{ [key: string]: string }>((acc, cur) => {
    acc[cur.type] = cur.value
    return acc
  }, {})

  return `${year}-${month}-${day}`
}

export function formatFullDate(date: Date): string {
  const dateFormatter = new Intl.DateTimeFormat('en', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

  const { day, month, year, hour, minute, dayPeriod } = dateFormatter
    .formatToParts(date)
    .reduce<{ [key: string]: string }>((acc, cur) => {
      acc[cur.type] = cur.value
      return acc
    }, {})

  return `${day}/${month}/${year} ${hour}:${minute} ${dayPeriod.toLowerCase()}`
}

export function addUserOffset(date: Date | string): Date {
  const userOffsetAtDate = dayjs.tz(date, dayjs.tz.guess()).utcOffset()
  return dayjs(date).add(userOffsetAtDate, 'minute').toDate()
}

export function subtractUserOffset(date: Date | string): Date {
  const userOffsetAtDate = dayjs.tz(date, dayjs.tz.guess()).utcOffset()
  return dayjs(date).subtract(userOffsetAtDate, 'minute').toDate()
}

export function applyTimeZoneOffset({
  date,
  currentTimeZone,
  newTimeZone,
}: {
  date: Date | string
  currentTimeZone?: string | undefined // UTC if undefined
  newTimeZone?: string | undefined // UTC if undefined
  subtractUserOffset?: boolean
}): Date {
  // Calculate offsets for the current timezone and the new timezone, defaulting to 0 (UTC) if undefined
  const currentTimeZoneOffsetAtDate = currentTimeZone ? dayjs.tz(date, currentTimeZone).utcOffset() : 0
  const newTimeZoneOffsetAtDate = newTimeZone ? dayjs.tz(date, newTimeZone).utcOffset() : 0

  // Add the new timezone offset and subtract the current timezone offset
  return dayjs(date)
    .add(newTimeZoneOffsetAtDate - currentTimeZoneOffsetAtDate, 'minute')
    .toDate()
}
