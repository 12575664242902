import type { OrganisationDetailed } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

function getOrganisationDetails(organisationId: string): Promise<OrganisationDetailed> {
  const tokens = getTokens()

  return fetch(`${import.meta.env.VITE_API_URL}admin/organisations/${organisationId}`, {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const organisationDetailsQuery = (organisationId: string) => ({
  queryKey: ['organisation', organisationId],
  queryFn: () => getOrganisationDetails(organisationId),
})
