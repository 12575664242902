import type { FlexProps } from '@chakra-ui/react'
import { Flex, Heading, Icon, Text } from '@chakra-ui/react'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { ReactNode } from 'react'

export type NonIdealStateProps = FlexProps & {
  iconColor: string
  icon: IconProp
  title: string
  description: string
  children?: ReactNode
}

export const NonIdealState = ({ iconColor, icon, title, description, children, ...props }: NonIdealStateProps) => {
  return (
    <Flex
      direction="column"
      h="100%"
      w="100%"
      align="center"
      justifyContent="center"
      bg="primary.700"
      color="white"
      {...props}
    >
      <Icon as={FontAwesomeIcon} icon={icon} boxSize={20} color={iconColor} />
      <Heading mt={4} textAlign="center">
        {title}
      </Heading>
      <Text mt={4} fontSize="lg" fontWeight="bold" textAlign="center">
        {description}
      </Text>
      {children}
    </Flex>
  )
}
