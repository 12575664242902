import type { GetVenuesQuery, GetVenuesResponse } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

const GET_VENUES_LIMIT = 50

function getVenues(query: GetVenuesQuery): Promise<GetVenuesResponse> {
  const tokens = getTokens()
  const url = new URL(`${import.meta.env.VITE_API_URL}admin/venues`)

  if (query) {
    Object.entries(query).forEach(([key, value]) => url.searchParams.append(key, value.toString()))
  }

  return fetch(url.toString(), {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const venuesQuery = (queryParams: GetVenuesQuery) => ({
  queryKey: ['venues', queryParams],
  queryFn: async ({ pageParam = 0 }: { pageParam?: number }) =>
    getVenues({
      limit: GET_VENUES_LIMIT.toString(),
      offset: pageParam.toString(),
      ...queryParams,
    }),
  getNextPageParam: (lastPage: GetVenuesResponse, pages: GetVenuesResponse[]) => {
    if (lastPage.data.length < GET_VENUES_LIMIT) return
    return pages.length * GET_VENUES_LIMIT
  },
})
