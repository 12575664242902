import { Badge, Button, Flex, Grid, GridItem, Stack, Text } from '@chakra-ui/react'
import { isNil } from 'lodash'
import { type ReactNode, useEffect, useState } from 'react'

import {
  ballSummaryColor,
  getBodyContactType,
  getBowlerApproachType,
  getBowlerBallType,
  getBowlerHandType,
  getBowlerType,
  getByeBlame,
  getFootworkType,
  getShotContactType,
  getShotType,
  getWicketKeeperPosition,
} from '../../helpers/ball'
import { textCleaner } from '../../helpers/data'
import { getBatterPerformance } from '../../helpers/inning'
import { getBattingMinutes, getBattingScorecardStatus, getStrikeRate, useMatch } from '../../helpers/match'
import { getPlayer, getPlayerDisplayName, getPlayerShortName } from '../../helpers/player'
import type { FeedType } from '../../types'
import type { Ball, FieldingPlayer } from '../../types/ball'
import type { Inning, MatchDls } from '../../types/match'
import { BallSummary } from '../activity-log'
import { S3pBallDiagnosticModal } from '../s3p-ball-diagnostic-modal'

type BallOverProps = {
  inningMatchOrder: number
  over: Ball[]
  overNumber: number
  setModalAndBall: (data: Ball | MatchDls | null) => void
  activeInning: Inning
  sendFeed: (type: FeedType, ballId?: string) => Promise<void>
  isSending: boolean
  condensed: boolean
  isBetting?: boolean
}

export const BallOver = ({
  inningMatchOrder,
  over,
  overNumber,
  setModalAndBall,
  activeInning,
  sendFeed,
  isSending,
  condensed,
  isBetting,
}: BallOverProps) => {
  const overRuns = over
    .map(ball => (ball.runsBat || 0) + (ball.runsExtra || 0))
    .reduce((acc: number, cur: number) => acc + cur)

  return (
    <Flex direction="column">
      <Flex justifyContent="flex-start" alignItems="flex-end" mx={4}>
        <Text as="span" textStyle="h3">
          Over {overNumber + 1}
        </Text>
        <Text as="span" textStyle="boldLabel" ml={6}>
          runs {overRuns}
        </Text>
      </Flex>
      <Stack spacing={3} my={4} mr={4}>
        {over.map(ball => (
          <BallInfo
            key={`${inningMatchOrder}_ball_${ball.overNumber}.${ball.ballNumber}`}
            ball={ball}
            setModalAndBall={setModalAndBall}
            activeInning={activeInning}
            sendFeed={sendFeed}
            isSending={isSending}
            condensed={condensed}
            isBetting={isBetting}
          />
        ))}
      </Stack>
    </Flex>
  )
}

type BallProps = {
  ball: Ball
  setModalAndBall: (data: Ball | MatchDls | null) => void
  activeInning: Inning
  sendFeed: (type: FeedType, ballId?: string) => Promise<void>
  isSending: boolean
  condensed: boolean
  isBetting?: boolean
}

const BallInfo = ({ ball, setModalAndBall, activeInning, sendFeed, isSending, condensed, isBetting }: BallProps) => {
  const match = useMatch()

  const [matchInfo, setMatchInfo] = useState(match)

  useEffect(() => {
    setMatchInfo(match)
  }, [match])

  const bowler = getPlayer(matchInfo.matchTeams, ball.bowlerMp)
  const batter = getPlayer(matchInfo.matchTeams, ball.batterMp)

  function getValidFielders() {
    return (
      ball.fieldingAnalysis?.fieldingPlayers &&
      ball.fieldingAnalysis?.fieldingPlayers.filter(fieldingPlayer => {
        const player = getPlayer(matchInfo.matchTeams, fieldingPlayer.playerMp)
        return !!player
      })
    )
  }

  return (
    <Flex direction="column" borderRadius="6px" bg="primary.600" minH="440px" overflow="hidden">
      <Flex flex={1} alignItems="center" w="100%" minH="81px" maxH="110px" background="primary.800" p={2}>
        <Flex w="40px" justifyContent="center">
          {ball.overNumber}.{ball.ballDisplayNumber}
        </Flex>
        <Flex justifyContent="center" marginX="7px" minW="100px">
          <Badge
            style={{ lineHeight: '1.4em' }}
            px={2}
            py={1}
            variant="solid"
            fontSize="1.2em"
            colorScheme={ballSummaryColor(ball)}
          >
            {BallSummary(ball)}
          </Badge>
        </Flex>
        <Flex alignItems="center" pr={2} minW="200px">
          <Text textStyle="boldLabel">
            {getPlayerShortName(bowler)} to {getPlayerShortName(batter)}
          </Text>
        </Flex>
        {!condensed && (
          <Flex alignItems="center">
            <Text textStyle="lable">{ball.textDescription}</Text>
          </Flex>
        )}
      </Flex>
      <OutgoingBatterInfo ball={ball} activeInning={activeInning} condensed={condensed} />
      <Flex direction="column" p={2}>
        <Flex direction="column" minH={ball.dismissal ? '220px' : '260px'}>
          <Flex my={2} ml={2}>
            {ball.freeHit && <Tag emphasis>{textCleaner('Free Hit')}</Tag>}
          </Flex>
          <Text p={2}>Bowling</Text>
          <Flex pl={2} mb={2}>
            {ball.bowlingAnalysis?.ballSpeed && <Tag>{ball.bowlingAnalysis?.ballSpeed} km/h</Tag>}
            {!isNil(ball.bowlingAnalysis?.bowlerBallTypeId) && <Tag>{textCleaner(getBowlerBallType(ball) || '')}</Tag>}
            {!condensed && ball.bowlingAnalysis?.pitchMap && (
              <Tag>
                {textCleaner(
                  `Pitch Map Coords: ${ball.bowlingAnalysis?.pitchMap.x}, ${ball.bowlingAnalysis?.pitchMap.y}`
                )}
              </Tag>
            )}
            {ball.bowlingAnalysis?.spinRate && <Tag>{ball.bowlingAnalysis?.spinRate} rpm</Tag>}
            {!isNil(ball.bowlingAnalysis?.bowlerBallApproachId) && (
              <Tag>{textCleaner(`Approach: ${getBowlerApproachType(ball)}`)}</Tag>
            )}
            {!isNil(ball.bowlingAnalysis?.bowlerHandId) && <Tag>{textCleaner(`Hand: ${getBowlerHandType(ball)}`)}</Tag>}
            {!isNil(ball.bowlingAnalysis?.bowlerTypeId) && <Tag>{textCleaner(`Type: ${getBowlerType(ball)}`)}</Tag>}
          </Flex>
          <Text p={2}>Batting</Text>
          <Flex pl={2} mb={2}>
            {!isNil(ball.battingAnalysis?.shots.shotTypeId) && <Tag>{textCleaner(getShotType(ball) || '')}</Tag>}
            {!isNil(ball.battingAnalysis?.shots.shotContactId) && (
              <Tag>{textCleaner(getShotContactType(ball) || '')}</Tag>
            )}
            {!isNil(ball.battingAnalysis?.shots.bodyContactId) && (
              <Tag>{textCleaner(getBodyContactType(ball) || '')}</Tag>
            )}
            {ball.battingAnalysis?.shots.inTheAir && <Tag>{textCleaner('in the air')}</Tag>}
            {ball.battingAnalysis?.shots.throughField && <Tag>{textCleaner('through field')}</Tag>}
            {!condensed && ball.battingAnalysis?.shots.wagonWheel && (
              <Tag>
                {textCleaner(
                  // eslint-disable-next-line max-len
                  `Wagon Wheel Coords: ${ball.battingAnalysis?.shots.wagonWheel.x}, ${ball.battingAnalysis?.shots.wagonWheel.y}`
                )}
              </Tag>
            )}
            {!isNil(ball.battingAnalysis?.footworkTypeId) && <Tag>{textCleaner(getFootworkType(ball) || '')}</Tag>}
            {!condensed && ball.battingAnalysis?.arrival && (
              <Tag>
                {textCleaner(
                  // eslint-disable-next-line max-len
                  `Arrival Coords: ${ball.battingAnalysis?.arrival.x}, ${ball.battingAnalysis?.arrival.y}`
                )}
              </Tag>
            )}
          </Flex>
          <Text p={2}>Fielding</Text>
          <Flex pl={2} mb={2}>
            {!isNil(ball.byeBlameId) && <Tag>{textCleaner(getByeBlame(ball) || '')}</Tag>}
            {ball.fieldingAnalysis &&
              ball.fieldingAnalysis.runsSaved !== null &&
              ball.fieldingAnalysis.runsSaved !== 0 && (
                <Tag>{textCleaner(`Runs Saved: ${ball.fieldingAnalysis.runsSaved}`)}</Tag>
              )}
            {ball.fieldingAnalysis?.fielded && <Tag>{textCleaner('Fielded')}</Tag>}
            {ball.fieldingAnalysis?.fieldedWicketKeeper && <Tag>{textCleaner('Fielded by wk')}</Tag>}
            {getValidFielders()?.map((player: FieldingPlayer) => (
              <Flex key={player.playerMp}>
                <Tag>
                  {textCleaner(
                    `${player.order === 1 ? 'Primary:' : 'Secondary:'} ${getPlayerDisplayName(
                      matchInfo.matchTeams,
                      player.playerMp
                    )}`
                  )}
                </Tag>
                {player.diveGood && <Tag>{textCleaner('Dive Good')}</Tag>}
                {player.slideGood && <Tag>{textCleaner('Slide Good')}</Tag>}
                {player.throwGood && <Tag>{textCleaner('Throw Good')}</Tag>}
                {player.throwHitStumps && <Tag>{textCleaner('Throw Hit Stumps')}</Tag>}
                {player.difficultyRating && player.order === 1 && (
                  <Tag>
                    {textCleaner(`Primary Difficulty: ${player.difficultyRating}`)}
                    {player.pressure ? <small> (++ Pressure)</small> : ''}
                  </Tag>
                )}
                {player.difficultyRating && player.order > 1 && (
                  <Tag>
                    {textCleaner(`Secondary Difficulty: ${player.difficultyRating}`)}
                    {player.pressure ? <small> (++ Pressure)</small> : ''}
                  </Tag>
                )}
              </Flex>
            ))}
            {ball.fieldingAnalysis?.droppedCatch && <Tag>{textCleaner('Dropped Catch')}</Tag>}
            {ball.fieldingAnalysis?.misfielded && <Tag>{textCleaner('Misfield')}</Tag>}
            {ball.fieldingAnalysis &&
              ball.fieldingAnalysis.overThrows !== null &&
              ball.fieldingAnalysis?.overThrows !== 0 && (
                <Tag>{textCleaner(`Over throws: ${ball.fieldingAnalysis.overThrows}`)}</Tag>
              )}
            {ball.fieldingAnalysis?.runOutMissed && <Tag>{textCleaner('Run out missed')}</Tag>}
            {ball.fieldingAnalysis?.stumpingMissed && <Tag>{textCleaner('Stumping missed')}</Tag>}
            {!isNil(ball.fieldingAnalysis?.wicketKeeperPositionId) && (
              <Tag>{textCleaner(`WK position: ${getWicketKeeperPosition(ball)}`)}</Tag>
            )}
          </Flex>
        </Flex>
        <Flex justifyContent="flex-end" alignItems="flex-end" mt={3}>
          {!isBetting && (
            <Button
              variant="primary"
              onClick={() => sendFeed('ball', ball.id)}
              isDisabled={isSending}
              marginRight="4px"
            >
              Send Single Ball Feed
            </Button>
          )}
          <Button onClick={() => setModalAndBall(ball)} marginRight="4px">
            View ball JSON payload
          </Button>
          <S3pBallDiagnosticModal match={match} ball={ball} activeInning={activeInning}></S3pBallDiagnosticModal>
        </Flex>
      </Flex>
    </Flex>
  )
}

type TagProps = {
  emphasis?: boolean
  children: ReactNode
}

const Tag = ({ emphasis, children }: TagProps) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    px={3}
    py={1.5}
    mr={2}
    bg={emphasis ? 'orange' : 'blue.600'}
    borderRadius="full"
  >
    <Text textStyle="boldLabel" fontStyle="italic" fontSize="12px" lineHeight="14px">
      {children}
    </Text>
  </Flex>
)

type OutgoingBatterInfoProps = {
  ball: Ball
  activeInning: Inning
  condensed: boolean
}

const OutgoingBatterInfo = ({ ball, activeInning, condensed }: OutgoingBatterInfoProps) => {
  const match = useMatch()

  const [matchInfo, setMatchInfo] = useState(match)

  useEffect(() => {
    setMatchInfo(match)
  }, [match])

  const battingPerformance = getBatterPerformance(ball.dismissal?.batterMp, activeInning)
  const batterDisplayName = getPlayerDisplayName(matchInfo.matchTeams, battingPerformance?.playerMp)
  const scorecardStatus = getBattingScorecardStatus(
    matchInfo,
    battingPerformance?.notOut,
    battingPerformance?.dismissal
  )

  if (ball.dismissal === null || ball.dismissal?.batterMp === undefined) return null
  return (
    <Flex px="88px" py={2} background="primary.900">
      <Grid w="100%" gap="0.125rem" templateColumns="repeat(2, 1fr)">
        <GridItem>
          <Text display="block">{batterDisplayName}</Text>
          <Text display="block" fontSize="sm">
            {scorecardStatus}
          </Text>
        </GridItem>
        <GridItem>
          <Grid templateColumns={condensed ? 'repeat(2, 1fr)' : 'repeat(10, 1fr)'}>
            {/* Header */}
            <GridItem>
              <Text align="center">R</Text>
            </GridItem>
            <GridItem>
              <Text align="center">B</Text>
            </GridItem>
            {!condensed && (
              <>
                <GridItem>
                  <Text align="center">SR</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">•</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">1</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">2</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">3</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">4</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">6</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">Mins</Text>
                </GridItem>
              </>
            )}
            {/* Data */}
            <GridItem>
              <Text align="center">{battingPerformance?.runs || 0}</Text>
            </GridItem>
            <GridItem>
              <Text align="center">{battingPerformance?.balls || 0}</Text>
            </GridItem>
            {!condensed && (
              <>
                <GridItem>
                  <Text align="center">{getStrikeRate(battingPerformance)}</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">{battingPerformance?.dots || 0}</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">{battingPerformance?.ones || 0}</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">{battingPerformance?.twos || 0}</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">{battingPerformance?.threes || 0}</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">{battingPerformance?.fours || 0}</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">{battingPerformance?.sixes || 0}</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">{getBattingMinutes(battingPerformance)}</Text>
                </GridItem>
              </>
            )}
          </Grid>
        </GridItem>
      </Grid>
    </Flex>
  )
}
