import { Button, Flex, Heading, Icon, Stack } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { QueryClient } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Link as RouterLink, useLoaderData, useParams } from 'react-router-dom'

import { venueDetailsQuery } from '../../../api/get-venue-details'
import { DetailValue } from '../../../components/detail-value'
import { HasMCAccess, MCRequiredLevelEnum } from '../../../helpers/auth'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    return await queryClient.ensureQueryData(venueDetailsQuery(params.venueId || ''))
  }

export default function VenueDetails() {
  const params = useParams()
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const { data: venueDetails } = useQuery({
    ...venueDetailsQuery(params.venueId || ''),
    initialData,
  })

  return (
    <>
      <Flex w="100%" justifyContent="space-between" alignItems="baseline">
        <Heading as="h2" size="lg">
          {venueDetails.name}
        </Heading>
        <HasMCAccess minLevel={MCRequiredLevelEnum['clsp-superadmin']}>
          <Button
            as={RouterLink}
            to={`/venues/${venueDetails.id}/edit`}
            variant="primary"
            minW={32}
            leftIcon={<Icon as={FontAwesomeIcon} icon={['fad', 'pen-to-square']} />}
          >
            Edit
          </Button>
        </HasMCAccess>
      </Flex>
      <Stack w="100%" mt={6} spacing={6}>
        <DetailValue label="CLS+ ID" value={venueDetails.id} />
        <DetailValue label="SDS ID" value={venueDetails.integrationIds.SDS} />
        <DetailValue label="Country" value={venueDetails.country?.name} />
        <DetailValue label="City" value={venueDetails.city} />
      </Stack>
    </>
  )
}
