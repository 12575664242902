import type { OrganisationDetailed, UpdateOrganisationRequest } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

export function updateOrganisationDetails(
  organisationId: string,
  formData: UpdateOrganisationRequest
): Promise<OrganisationDetailed> {
  const tokens = getTokens()
  return fetch(`${import.meta.env.VITE_API_URL}admin/organisations/${organisationId}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${tokens?.accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}
