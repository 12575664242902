import { Box, Flex, Text } from '@chakra-ui/react'
import { flatten, sortBy, uniqueId } from 'lodash'
import moment from 'moment'
import { useRef } from 'react'
import { Virtuoso as List } from 'react-virtuoso'

import type { Match, MatchConditions } from '../../types/match'
import { MatchConditionCard } from '../match-condition-card'

type MatchConditionsPanelProps = {
  game: Match
}

const MatchConditionsPanel = ({ game }: MatchConditionsPanelProps) => {
  const listRef = useRef(null)
  const conditionsMatrix = game.matchDates?.map(matchDate => matchDate.conditions ?? [])
  const matchConditions = flatten(conditionsMatrix) as MatchConditions[]

  const iteratees = (condition: MatchConditions) => condition.timestamp
  const sortedConditions = sortBy(matchConditions, iteratees)

  const ConditionsCard = (index: number) => {
    if (!matchConditions[index]) return null

    const conditions = matchConditions[index]
    const matchLocalDate = game.timeZone ? moment(matchConditions[index].timestamp).tz(game.timeZone) : null
    const date = game?.timeZone ? matchLocalDate : moment(matchConditions[index].timestamp)

    const prevIndexAvailable = index - 1 <= matchConditions.length && index !== 0
    const prevCondtionDate = prevIndexAvailable ? matchLocalDate : moment()

    return (
      <Box key={uniqueId(conditions.timestamp)} mb={4}>
        {date && (
          <Flex direction="row" alignItems="center" mb={3}>
            <Text fontSize="xl" fontStyle="italic">
              {date.format('dddd, MMMM Do') !== prevCondtionDate?.format('dddd, MMMM Do') &&
                date.format('dddd, MMMM Do')}
            </Text>
            <Text fontSize="sm" ml={5} color="primary.300">
              {date.format('h:mm a')}
            </Text>
          </Flex>
        )}
        <MatchConditionCard conditions={conditions} />
      </Box>
    )
  }
  return (
    <List
      ref={listRef}
      style={{ height: '800px', width: '100%' }}
      totalCount={sortedConditions.length}
      itemContent={ConditionsCard}
    />
  )
}

export default MatchConditionsPanel
