import { Badge, Box, Divider, Flex, Grid, GridItem, Heading, Icon, Stack, Text, Tooltip } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { uniqueId } from 'lodash'
import { useEffect, useState } from 'react'

import { ballSummaryColor } from '../../helpers/ball'
import { getCurrentBatterPerformances, getCurrentBowlerPerformances } from '../../helpers/inning'
import {
  getBattingMinutes,
  getBowlingOvers,
  getBowlingPerformanceEconomy,
  getDatesInOrder,
  getLatestConditions,
  getStrikeRate,
  useMatch,
} from '../../helpers/match'
import { getPlayerDisplayName } from '../../helpers/player'
import type { ConfirmedBallsByOver } from '../../types/ball'
import type { BattingPerformance, BowlingPerformance, Inning } from '../../types/match'
import { BallSummary } from '../activity-log'
import { MatchConditionCard } from '../match-condition-card'

type ActiveInningCardProps = {
  inning: Inning
  balls: ConfirmedBallsByOver | undefined
  ballKeys: number[]
  condensed: boolean
}

const ActiveInningCard = ({ inning, balls, ballKeys, condensed }: ActiveInningCardProps) => {
  const match = useMatch()

  const [matchInfo, setMatchInfo] = useState(match)

  useEffect(() => {
    setMatchInfo(match)
  }, [match])

  const ballsPerOver = matchInfo.matchConfigs?.ballsPerOver || 6
  const battingPerf = getCurrentBatterPerformances(inning)
  const bowlingPerf = getCurrentBowlerPerformances(inning)

  function onStrikerOrdered<T extends Array<Partial<BattingPerformance | BowlingPerformance>>>(vals: T): T {
    let onStriker = vals.length > 0 ? vals[1] : undefined
    const perfArr = vals.filter(perf => {
      if (perf.onStrike) {
        onStriker = perf
      } else {
        return perf
      }
    })

    return [onStriker, ...perfArr] as T
  }

  return (
    <>
      <Box minH="144px" borderColor="primary.600" borderWidth="1px" borderRadius="6px" overflow="hidden">
        <Grid p={2} fontStyle="italic" templateColumns="2fr 4fr" bg="primary.600">
          <Heading size="sm" px={2} lineHeight="1.5">
            Batters
          </Heading>
          <Grid templateColumns={condensed ? 'repeat(4, 1fr)' : 'repeat(10, 1fr)'}>
            {condensed && (
              <>
                <GridItem></GridItem>
                <GridItem></GridItem>
              </>
            )}
            <GridItem>
              <Text align="center">R</Text>
            </GridItem>
            <GridItem>
              <Text align="center">B</Text>
            </GridItem>
            {!condensed && (
              <>
                <GridItem>
                  <Text align="center">SR</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">•</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">1</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">2</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">3</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">4</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">6</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">Mins</Text>
                </GridItem>
              </>
            )}
          </Grid>
        </Grid>
        {battingPerf.map((batterPerf, index) => {
          return (
            <BatterPerfInfo
              index={index}
              battingPerformance={batterPerf}
              key={`BatterPerfInfo_${index}`}
              condensed={condensed}
            />
          )
        })}
      </Box>
      <Box minH="144px" mt={3} borderColor="primary.600" borderWidth="1px" borderRadius="6px" overflow="hidden">
        <Grid bg="primary.600" fontStyle="italic" templateColumns="2fr 4fr" p={2}>
          <Heading size="sm" px="2" lineHeight="1.5">
            Bowlers
          </Heading>
          <Grid templateColumns={condensed ? 'repeat(4, 1fr)' : 'repeat(10, 1fr)'}>
            {!condensed && (
              <>
                <GridItem></GridItem>
                <GridItem></GridItem>
              </>
            )}
            <GridItem>
              <Text align="center">O</Text>
            </GridItem>
            <GridItem>
              <Text align="center">M</Text>
            </GridItem>
            <GridItem>
              <Text align="center">R</Text>
            </GridItem>
            <GridItem>
              <Text align="center">W</Text>
            </GridItem>
            {!condensed && (
              <>
                <GridItem>
                  <Text align="center">•</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">Econ</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">Wd</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">NB</Text>
                </GridItem>
              </>
            )}
          </Grid>
        </Grid>
        {onStrikerOrdered(bowlingPerf).map((bowlerPerf, index) => {
          return (
            <BowlingPerfInfo
              index={index}
              bowlingPerformance={bowlerPerf}
              ballsPerOver={ballsPerOver}
              key={`BowlingPerfInfo_${index}`}
              condensed={condensed}
            />
          )
        })}
      </Box>
      <Last12Balls overs={balls} overKeys={ballKeys} condensed={condensed} />
    </>
  )
}

type BatterPerfInfoProps = {
  battingPerformance: BattingPerformance
  index: number
  condensed: boolean
}

const BatterPerfInfo = ({ battingPerformance, index, condensed }: BatterPerfInfoProps) => {
  const match = useMatch()

  const [matchInfo, setMatchInfo] = useState(match)

  useEffect(() => {
    setMatchInfo(match)
  }, [match])

  const divider = index === 1 ? <Divider w="100%" /> : null

  return (
    <>
      {divider}
      <Grid px={2} py={3} templateColumns={condensed ? '4fr 2fr' : '2fr 4fr'} gap="0.125rem" width="100%">
        <GridItem>
          <Flex alignItems="center" flex={1}>
            <Text px={2} fontSize="lg" fontWeight={battingPerformance?.onStrike ? 'bold' : 'normal'}>
              {getPlayerDisplayName(matchInfo.matchTeams, battingPerformance?.playerMp)}
            </Text>
            {battingPerformance?.onStrike && <Icon as={FontAwesomeIcon} icon={['fad', 'cricket-bat-ball']} ml={2} />}
          </Flex>
        </GridItem>
        <GridItem>
          <Grid templateColumns={condensed ? 'repeat(2, 1fr)' : 'repeat(10, 1fr)'}>
            <GridItem>
              <Text align="center">{battingPerformance?.runs || 0}</Text>
            </GridItem>
            <GridItem>
              <Text align="center">{battingPerformance?.balls || 0}</Text>
            </GridItem>
            {!condensed && (
              <>
                <GridItem>
                  <Text align="center">{getStrikeRate(battingPerformance)}</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">{battingPerformance?.dots || 0}</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">{battingPerformance?.ones || 0}</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">{battingPerformance?.twos || 0}</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">{battingPerformance?.threes || 0}</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">{battingPerformance?.fours || 0}</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">{battingPerformance?.sixes || 0}</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">{getBattingMinutes(battingPerformance)}</Text>
                </GridItem>
              </>
            )}
          </Grid>
        </GridItem>
      </Grid>
    </>
  )
}

type BowlingPerfInfoProps = {
  bowlingPerformance: BowlingPerformance
  ballsPerOver: number
  index: number
  condensed: boolean
}

const BowlingPerfInfo = ({ bowlingPerformance, ballsPerOver, index, condensed }: BowlingPerfInfoProps) => {
  const match = useMatch()

  const [matchInfo, setMatchInfo] = useState(match)

  useEffect(() => {
    setMatchInfo(match)
  }, [match])

  const divider = index === 1 ? <Divider w="100%" /> : null

  return (
    <>
      {divider}
      <Grid px={2} py={3} templateColumns="2fr 4fr" gap="0.125rem" width="100%">
        <GridItem>
          <Flex alignItems="center" flex={1}>
            <Text px={2} fontSize="lg" fontWeight={bowlingPerformance?.onStrike ? 'bold' : 'normal'}>
              {getPlayerDisplayName(matchInfo.matchTeams, bowlingPerformance?.playerMp)}
            </Text>
            {bowlingPerformance?.onStrike && <Icon as={FontAwesomeIcon} icon={['fad', 'person-running']} ml={2} />}
          </Flex>
        </GridItem>
        <GridItem>
          <Grid templateColumns={condensed ? 'repeat(4, 1fr)' : 'repeat(10, 1fr)'}>
            {!condensed && (
              <>
                <GridItem></GridItem>
                <GridItem></GridItem>
              </>
            )}
            <GridItem>
              <Text align="center">{getBowlingOvers(bowlingPerformance)}</Text>
            </GridItem>
            <GridItem>
              <Text align="center">{bowlingPerformance?.maidens || 0}</Text>
            </GridItem>
            <GridItem>
              <Text align="center">{bowlingPerformance?.runs || 0}</Text>
            </GridItem>
            <GridItem>
              <Text align="center">{bowlingPerformance?.wickets || 0}</Text>
            </GridItem>
            {!condensed && (
              <>
                <GridItem>
                  <Text align="center">{bowlingPerformance?.dots || 0}</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">{getBowlingPerformanceEconomy(bowlingPerformance, ballsPerOver)}</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">{bowlingPerformance?.extras?.wides || 0}</Text>
                </GridItem>
                <GridItem>
                  <Text align="center">{bowlingPerformance?.extras?.noBalls || 0}</Text>
                </GridItem>
              </>
            )}
          </Grid>
        </GridItem>
      </Grid>
    </>
  )
}

type Last12BallsProps = {
  overs: ConfirmedBallsByOver | undefined
  overKeys: number[]
  condensed: boolean
}

const Last12Balls = ({ overs, overKeys, condensed }: Last12BallsProps) => {
  let count = 0
  const maxCount = condensed ? 6 : 12
  const match = useMatch()

  const [matchInfo, setMatchInfo] = useState(match)

  useEffect(() => {
    setMatchInfo(match)
  }, [match])

  const func = () => {
    const jsxArray: JSX.Element[] = []
    for (let i = 0; i < overKeys.length; i++) {
      const over = overs ? overs[overKeys[i]] : []

      for (let j = 0; j < over.length; j++) {
        const ball = over[j]
        if (count < maxCount) {
          count++
          jsxArray.push(
            <Tooltip
              label={
                <Stack py={2} px={1} spacing={2}>
                  <Text fontWeight="bold">{`${ball.overNumber}.${ball.ballDisplayNumber} - ${getPlayerDisplayName(
                    matchInfo.matchTeams,
                    ball.bowlerMp
                  )} to ${getPlayerDisplayName(matchInfo.matchTeams, ball.batterMp)}`}</Text>
                  <Text>{ball.textDescription}</Text>
                </Stack>
              }
              key={ball.id}
              aria-label="A tooltip"
              placement="top"
              bg="primary.800"
              color="primary.200"
              hasArrow
            >
              <Badge
                mx={1}
                my={2}
                variant="solid"
                height={10}
                width={10}
                minW={10}
                lineHeight={ball.freeHit ? '36px' : '40px'}
                textAlign="center"
                fontSize={BallSummary(ball).length > 3 ? '14px' : '20px'}
                colorScheme={ballSummaryColor(ball)}
                sx={ball.freeHit ? { border: '2px solid', borderColor: 'yellow.500' } : undefined}
                _hover={{ cursor: 'pointer' }}
              >
                {BallSummary(ball)}
              </Badge>
            </Tooltip>
          )
        } else {
          return jsxArray
        }
      }
      if (over[0].overNumber > 0 && count < maxCount) {
        jsxArray.push(
          <Badge
            key={uniqueId(i.toString())}
            variant="solid"
            height={10}
            width={10}
            minW={10}
            mx={1}
            my={2}
            py={1.5}
            textAlign="center"
            bg="primary.800"
            _hover={{ cursor: 'pointer' }}
          >
            <Text fontSize="0.8em" textAlign="center">
              Over
              <br />
              {over[0].overNumber} ▶
            </Text>
          </Badge>
        )
      }
    }
    return jsxArray
  }
  const jsxArray = func()

  return (
    <Flex width="100%" justify="center" flex={1} mt="10px" padding="21px 7px" alignItems="center">
      <Heading pl="2" size="sm" lineHeight="48px">
        Recent:
      </Heading>
      <Flex ml={4} alignItems="center" wrap="wrap">
        {jsxArray}
      </Flex>
    </Flex>
  )
}

type LivePanelProps = {
  activeInning: Inning
  balls: ConfirmedBallsByOver | undefined
  ballKeys: number[]
  condensed: boolean
}

export const LivePanel = ({ activeInning, balls, ballKeys, condensed }: LivePanelProps) => {
  const match = useMatch()

  const [matchInfo, setMatchInfo] = useState(match)

  useEffect(() => {
    setMatchInfo(match)
  }, [match])

  const allDates = getDatesInOrder(matchInfo)
  const conditions = getLatestConditions(allDates[allDates.length - 1])

  return (
    <Stack spacing={4}>
      <ActiveInningCard inning={activeInning} balls={balls} ballKeys={ballKeys} condensed={condensed} />
      {conditions && <MatchConditionCard conditions={conditions} />}
    </Stack>
  )
}
