import type { LookupReference } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

function getMatchFormatsRefData(): Promise<LookupReference[]> {
  const tokens = getTokens()

  return fetch(`${import.meta.env.VITE_API_URL}admin/reference-data/match-formats`, {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const matchFormatsRefDataQuery = {
  queryKey: ['match-formats-ref-data'],
  queryFn: () => getMatchFormatsRefData(),
}
