import type { TeamDetailed } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

function getTeamDetails(teamId: string): Promise<TeamDetailed> {
  const tokens = getTokens()

  return fetch(`${import.meta.env.VITE_API_URL}admin/teams/${teamId}`, {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const teamDetailsQuery = (teamId: string) => ({
  queryKey: ['team', teamId],
  queryFn: () => getTeamDetails(teamId),
})
