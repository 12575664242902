// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const idCleaner = (d: any, type: string) => {
  let data = JSON.parse(JSON.stringify(d))
  if (type === 'MATCH') {
    // venueEnds
    if (data.venue && data.venue.venueEnds) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.venue.venueEnds = data.venue.venueEnds.map((end: any) => {
        const splitID = end.id.split('_')
        if (splitID.length > 1) {
          return { ...end, id: splitID[1] }
        } else {
          return { ...end, id: splitID[0] }
        }
      })
    }
  }

  if (type === 'BALL') {
    let splitID
    if (data.venueEnd && data.venueEnd.id) {
      splitID = data.venueEnd.id.split('_')
      if (splitID.length > 1) {
        data.venueEnd.id = splitID[1]
      } else {
        data.venueEnd.id = splitID[0]
      }
    }
    return data
  }

  if (type === 'PLAYER') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data = data.map((player: any) => {
      const splitID = player.id.split('_')
      return { ...player, id: splitID[0] }
    })
  }
  return data
}

export const textCleaner = (text: string, upper = true) => {
  // remove any underscores
  const newText = text.replace('_', ' ')
  if (upper) {
    return newText.toUpperCase()
  } else {
    return newText.toLowerCase()
  }
}
