import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react'

const switchStyleHelpers = createMultiStyleConfigHelpers(switchAnatomy.keys)

export const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  styles: {
    global: {
      body: {
        color: 'primary.300',
      },
      '.react-datepicker-popper': {
        zIndex: 20,
      },
      'input.react-datepicker-time__input': {
        marginLeft: '4px',
        padding: '6px 10px 5px',
        borderRadius: '4px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'gray.300',
        backgroundColor: 'transparent',
      },
      '.react-datepicker__navigation-icon::before': {
        top: '10px',
      },
    },
  },
  components: {
    Button: defineStyleConfig({
      variants: {
        primary: defineStyle({
          _dark: {
            bg: 'blue.600',
            color: 'white',
            _hover: {
              bg: 'blue.500',
            },
            _disabled: {
              bg: 'blue.600',
            },
          },
        }),
        secondary: defineStyle({
          _dark: {
            bg: 'primary.600',
            color: 'white',
            _hover: {
              bg: 'primary.500',
            },
            _disabled: {
              bg: 'primary.600',
            },
          },
        }),
        warning: defineStyle({
          _dark: {
            bg: 'red.500',
            color: 'white',
            _hover: {
              bg: 'red.400',
            },
            _disabled: {
              bg: 'red.500',
            },
          },
        }),
        outline: defineStyle({
          _dark: {
            border: '1px solid',
            borderColor: 'primary.500',
            bg: 'transparent',
            color: 'white',
            _hover: {
              bg: 'primary.500',
            },
            _disabled: {
              bg: 'transparent',
            },
          },
        }),
      },
    }),
    Heading: defineStyleConfig({
      baseStyle: {
        color: 'white',
      },
    }),
    Link: defineStyleConfig({
      baseStyle: {
        color: 'primary.100',
        fontWeight: 'bold',
        _hover: {
          color: 'primary.300',
          textDecoration: 'none',
        },
      },
    }),
    Switch: switchStyleHelpers.defineMultiStyleConfig({
      variants: {
        primary: switchStyleHelpers.definePartsStyle({
          thumb: {
            bg: 'white',
          },
          track: {
            bg: 'primary.400',
            _checked: {
              bg: 'green.400',
            },
          },
        }),
      },
    }),
  },
  shadows: {
    cls: {
      standard: '0px 4px 15px 2px rgba(0, 0, 0, 0.25)',
    },
  },
  colors: {
    primary: {
      '50': '#F8FAFF',
      '100': '#F1F5F9',
      '200': '#E2E8F0',
      '300': '#CBD5E1',
      '400': '#94A3B8',
      '500': '#64748B',
      '600': '#475569',
      '700': '#334155',
      '800': '#1E293B',
      '900': '#0F172A',
    },
    blue: {
      '50': '#f0f9ff',
      '100': '#e0f2fe',
      '200': '#bae6fd',
      '300': '#7dd3fc',
      '400': '#38bdf8',
      '500': '#0ea5e9',
      '600': '#0284c7',
      '700': '#0369a1',
      '800': '#075985',
      '900': '#0c4a6e',
    },
  },
  textStyles: {
    h1: {
      fontSize: '48px',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 700,
      lineHeight: '56px',
    },
    h2: {
      fontSize: '36px',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      lineHeight: '42px',
    },
    h3: {
      fontSize: '24px',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      fontStyle: 'italic',
      lineHeight: '28px',
    },
    label: {
      fontSize: '16px',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 300,
      lineHeight: '19px',
    },
    boldLabel: {
      fontSize: '16px',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 700,
      lineHeight: '19px',
    },
    smallLabel: {
      fontSize: '13px',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 400,
      lineHeight: '19px',
    },
    value: {
      fontSize: '24px',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      lineHeight: '28px',
    },
  },
})
