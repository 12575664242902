import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack, Switch } from '@chakra-ui/react'
import type { UpdateOrganisationRequest } from '@clsplus/api-types/api-admin'
import { zodResolver } from '@hookform/resolvers/zod'
import type { QueryClient } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { useLoaderData, useNavigate, useParams, useRevalidator } from 'react-router-dom'
import * as z from 'zod'

import { countriesRefDataQuery } from '../../../api/get-countries-ref-data'
import { organisationDetailsQuery } from '../../../api/get-organisation-details'
import { updateOrganisationDetails } from '../../../api/update-organisation-details'
import { ComboBox } from '../../../ui/combo-box'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const [initialCountriesRefData, initialOrganisationData] = await Promise.all([
      queryClient.ensureQueryData(countriesRefDataQuery),
      queryClient.ensureQueryData(organisationDetailsQuery(params.organisationId || '')),
    ])

    return {
      initialCountriesRefData,
      initialOrganisationData,
    }
  }

const organisationEditSchema = z.object({
  name: z.string().min(1, { message: 'Please enter an organisation name' }),
  shortName: z.string(),
  countryCode: z
    .union([
      z.string().length(0),
      z.string().min(2, { message: '2 letter minimum' }).max(3, { message: '3 letter maximum' }),
    ])
    .refine(
      data => {
        return data.toUpperCase() === data
      },
      {
        message: 'Must be uppercase',
      }
    )
    .optional()
    .transform(e => (e === '' ? undefined : e)),
  canManage: z.boolean(),
  canParticipate: z.boolean(),
})

type OrganisationEditFormData = z.infer<typeof organisationEditSchema>

export default function OrganisationEdit() {
  const params = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const revalidator = useRevalidator()
  const { initialCountriesRefData, initialOrganisationData } = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >

  const organisationId = params.organisationId || ''

  const { data: organisationDetails } = useQuery({
    ...organisationDetailsQuery(organisationId),
    ...initialOrganisationData,
  })

  const { data: countriesRefData } = useQuery({
    ...countriesRefDataQuery,
    initialData: initialCountriesRefData,
  })

  const updateOrganisationMutation = useMutation({
    mutationFn: (formData: UpdateOrganisationRequest) => updateOrganisationDetails(organisationId, formData),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['organisations'] }),
        queryClient.invalidateQueries({ queryKey: ['organisation', organisationId] }),
      ])
      revalidator.revalidate()
      navigate(`/organisations/${organisationId}`)
    },
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<OrganisationEditFormData>({
    defaultValues: {
      name: organisationDetails?.name,
      shortName: organisationDetails?.shortName ?? '',
      countryCode: organisationDetails?.countryCode,
      canManage: organisationDetails?.canManage,
      canParticipate: organisationDetails?.canParticipate,
    },
    resolver: zodResolver(organisationEditSchema),
  })

  const handleSubmitClick = (formData: OrganisationEditFormData) => {
    updateOrganisationMutation.mutate(formData)
  }

  const countryOptions = countriesRefData?.map(country => ({ value: country.code, label: country.name }))

  return (
    <Flex direction="column" w="100%">
      <Heading as="h2" size="lg">
        {organisationDetails?.name ?? 'Unknown'}
      </Heading>
      <Stack
        as="form"
        noValidate
        id="organisation-edit"
        spacing={4}
        maxW="container.sm"
        mt={8}
        onSubmit={handleSubmit(handleSubmitClick)}
      >
        <FormControl isRequired isInvalid={!!errors.name}>
          <FormLabel>Name</FormLabel>
          <Input {...register('name')} />
          {errors.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.shortName}>
          <FormLabel>Short name</FormLabel>
          <Input {...register('shortName')} />
          {errors.shortName && <FormErrorMessage>{errors.shortName.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.countryCode}>
          <FormLabel>Country Code</FormLabel>
          <Controller
            control={control}
            name={register('countryCode').name}
            render={({ field: { onChange, value } }) => (
              <ComboBox
                isSingleSelect
                options={countryOptions}
                onChange={onChange}
                selectedOptions={(() => {
                  return countryOptions.filter(option => option.value === value)
                })()}
              />
            )}
          />
          {errors.countryCode && <FormErrorMessage>{errors.countryCode.message}</FormErrorMessage>}
        </FormControl>
        <FormControl>
          <FormLabel>Can manage competitions</FormLabel>
          <Switch variant="primary" {...register('canManage')} />
        </FormControl>
        <FormControl>
          <FormLabel>Can participate in competitions</FormLabel>
          <Switch variant="primary" {...register('canParticipate')} />
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={4} mt={8}>
        <Button
          type="submit"
          form="organisation-edit"
          variant="primary"
          isDisabled={!isDirty}
          isLoading={updateOrganisationMutation.isLoading}
        >
          Save changes
        </Button>
        <Button
          variant="secondary"
          onClick={() => navigate(`/organisations/${organisationId}`)}
          isDisabled={updateOrganisationMutation.isLoading}
        >
          Cancel
        </Button>
      </Stack>
    </Flex>
  )
}
