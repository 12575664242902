import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack } from '@chakra-ui/react'
import type { UpdateCompetitionStageRequest } from '@clsplus/api-types/api-admin'
import { zodResolver } from '@hookform/resolvers/zod'
import type { QueryClient } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { useLoaderData, useNavigate, useParams, useRevalidator } from 'react-router-dom'
import * as z from 'zod'

import { competitionStageDetailsQuery } from '../../../../api/get-competition-stage-details'
import { updateCompetitionStageDetails } from '../../../../api/update-competition-stage-details'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) =>
    await queryClient.ensureQueryData(competitionStageDetailsQuery(params.competitionId || '', params.stageId || ''))

const competitionStageEditSchema = z.object({
  name: z.string().min(1, { message: 'Please enter a stage name' }),
})

type CompetitionStageEditFormData = z.infer<typeof competitionStageEditSchema>

export default function CompetitionStageEdit() {
  const params = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const revalidator = useRevalidator()
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const stageId = params.stageId || ''
  const competitionId = params.competitionId || ''

  const { data: stageDetails } = useQuery({
    ...competitionStageDetailsQuery(competitionId, stageId),
    initialData,
  })

  const updatePersonMutation = useMutation({
    mutationFn: (formData: UpdateCompetitionStageRequest) =>
      updateCompetitionStageDetails(competitionId, stageId, formData),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['competitions'] }),
        queryClient.invalidateQueries({ queryKey: ['competition', competitionId] }),
        queryClient.invalidateQueries({ queryKey: ['stage', stageId] }),
      ])
      revalidator.revalidate()
      navigate(`/competitions/${competitionId}/stage/${stageId}`)
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<CompetitionStageEditFormData>({
    defaultValues: {
      name: stageDetails.name ?? null,
    },
    resolver: zodResolver(competitionStageEditSchema),
  })

  const handleSubmitClick = (formData: CompetitionStageEditFormData) => {
    updatePersonMutation.mutate(formData)
  }

  return (
    <Flex direction="column" w="100%">
      <Heading as="h2" size="lg">
        {stageDetails.name}
      </Heading>
      <Stack
        as="form"
        noValidate
        id="stage-edit"
        spacing={4}
        maxW="container.sm"
        mt={8}
        onSubmit={handleSubmit(handleSubmitClick)}
      >
        <FormControl isInvalid={!!errors.name}>
          <FormLabel>Stage name</FormLabel>
          <Input {...register('name')} />
          {errors.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={4} mt={8}>
        <Button
          type="submit"
          form="stage-edit"
          variant="primary"
          isDisabled={!isDirty}
          isLoading={updatePersonMutation.isLoading}
        >
          Save changes
        </Button>
        <Button
          variant="secondary"
          onClick={() => navigate(`/competitions/${competitionId}/stage/${stageId}`)}
          isDisabled={updatePersonMutation.isLoading}
        >
          Cancel
        </Button>
      </Stack>
    </Flex>
  )
}
