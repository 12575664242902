import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  IconButton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { FormEvent, ReactNode } from 'react'
import type { UseFormHandleSubmit } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

type ListPageFiltersProps<TFormData> = {
  children: ReactNode
  formId: string
  onDismiss: () => void
  onReset: () => void
  onSubmit: ReturnType<UseFormHandleSubmit<TFormData>>
  title: string
}

export function ListPageFilters<TFormData>({
  children,
  formId,
  onDismiss,
  onReset,
  onSubmit,
  title,
}: ListPageFiltersProps<TFormData>) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [searchParams] = useSearchParams()

  const paramsCount = Object.keys(Object.fromEntries(searchParams)).length

  const handleSubmitClick = (event: FormEvent<HTMLDivElement>) => {
    event.preventDefault()
    onSubmit()
    onClose()
  }

  const handleResetClick = () => {
    onReset()
    onClose()
  }

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Button
          variant="secondary"
          minW={36}
          leftIcon={<Icon as={FontAwesomeIcon} icon={['fad', 'filter-list']} boxSize={5} mr={2} />}
          onClick={onOpen}
        >
          Filters {paramsCount > 0 && `(${paramsCount})`}
        </Button>
        <IconButton
          variant="warning"
          aria-label="Reset filters"
          icon={<FontAwesomeIcon icon={['fas', 'xmark-large']} />}
          onClick={handleResetClick}
          isDisabled={!paramsCount}
        />
      </Stack>
      <Drawer
        size="sm"
        placement="right"
        isOpen={isOpen}
        onClose={onClose}
        onOverlayClick={onDismiss}
        onEsc={onDismiss}
      >
        <DrawerOverlay />
        <DrawerContent bg="primary.800">
          <DrawerCloseButton top={4} right={4} />
          <DrawerHeader>
            <Text fontSize="xl" fontWeight="bold">
              {title}
            </Text>
          </DrawerHeader>
          <DrawerBody p={6}>
            <Stack as="form" noValidate id={formId} spacing={4} onSubmit={handleSubmitClick}>
              {children}
            </Stack>
            <Stack direction="row" spacing={2} mt={6}>
              <Button
                variant="primary"
                minW={32}
                leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'check']} />}
                type="submit"
                form={formId}
              >
                Apply
              </Button>
              <Button
                variant="secondary"
                minW={32}
                leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'xmark-large']} />}
                onClick={handleResetClick}
              >
                Reset
              </Button>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
