import { Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { filter, orderBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import type { Column, Row } from 'react-table'
import { useTable } from 'react-table'

import { idCleaner } from '../../../helpers/data'
import { getInningsBowlingTeam } from '../../../helpers/inning'
import { getBowlingOvers, getBowlingPerformanceEconomy, getBowlingSpellEconomy, useMatch } from '../../../helpers/match'
import type { BowlingPerformance, BowlingPerformanceSpell, Inning, Match, MatchPlayer } from '../../../types/match'

type BowlingProps = {
  inning: Inning
  game: Match
  ballsPerOver: number
  condensed: boolean
}

type BowlingRow = {
  player: MatchPlayer
  performance: BowlingPerformance | undefined
  first_performance_order: number | null
}

type BowlingSpellRow = {
  reference: string
  spell: BowlingPerformanceSpell
  order: number
}

const BowlingSpell = ({
  row,
  ballsPerOver,
  condensed,
}: {
  row: BowlingRow
  ballsPerOver: number
  condensed: boolean
}) => {
  const columns: Column<BowlingSpellRow>[] = useMemo(
    () => [
      {
        id: 'spell',
        accessor: (row: BowlingSpellRow) => row.reference,
        Header: 'Spells',
        Cell: ({ row }: { row: Row<BowlingSpellRow> }) => {
          return (
            <Text fontSize="14px" ml={6}>
              {row.original.reference}
            </Text>
          )
        },
      },
      {
        accessor: (row: BowlingSpellRow) => row.spell?.overs,
        id: 'O',
      },
      {
        accessor: (row: BowlingSpellRow) => row.spell?.maidens,
        id: 'M',
      },
      {
        accessor: (row: BowlingSpellRow) => row.spell?.runs,
        id: 'R',
      },
      {
        accessor: (row: BowlingSpellRow) => row.spell?.wickets,
        id: 'W',
      },
      ...(!condensed
        ? ([
            {
              accessor: (row: BowlingSpellRow) => row.spell?.dots,
              id: '\u2022',
            },
            {
              accessor: (row: BowlingSpellRow) => getBowlingSpellEconomy(row.spell, ballsPerOver),
              id: 'Econ',
            },
            {
              accessor: (row: BowlingSpellRow) => row.spell?.extras?.wides,
              id: 'Wd',
            },
            {
              accessor: (row: BowlingSpellRow) => row.spell?.extras?.noBalls,
              id: 'NB',
            },
          ] as const)
        : []),
    ],
    [condensed, ballsPerOver]
  )

  const data: BowlingSpellRow[] = useMemo(() => {
    return orderBy(
      row.performance?.bowlingPerformanceSpells?.map(spell => {
        return {
          reference: `Spell ${spell.start} \u279E ${spell.end || ''}`,
          spell,
          order: Number(`${spell.startOver}.${spell.startBall}`),
        }
      }),
      ['order'],
      ['asc']
    )
  }, [row.performance])

  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({ columns, data })

  return (
    <Table {...getTableProps()}>
      <Tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            // eslint-disable-next-line react/jsx-key
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell, idx) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <Td
                    {...cell.getCellProps()}
                    w={condensed ? (idx === 0 ? '40%' : '15%') : idx === 0 ? '28%' : '9%'}
                    pt={0}
                    pb={4}
                    px={2}
                    borderBottom="none"
                    fontSize="12px"
                    textAlign={idx === 0 ? 'left' : 'center'}
                  >
                    {cell.render('Cell')}
                  </Td>
                )
              })}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}

export const Bowling = ({ inning, ballsPerOver, condensed }: BowlingProps) => {
  const match = useMatch()

  const [matchInfo, setMatchInfo] = useState(match)

  useEffect(() => {
    setMatchInfo(match)
  }, [match])

  const bowlingTeamPlayers = getInningsBowlingTeam(inning, matchInfo.matchTeams)?.matchPlayers
  const teamPlayers: MatchPlayer[] | undefined = idCleaner(bowlingTeamPlayers, 'PLAYER')
  const bowlingPerformances = inning.bowlingPerformances

  const columns: Column<BowlingRow>[] = useMemo(
    () => [
      {
        id: 'bowlers',
        accessor: (row: BowlingRow) => row.player.cardNameF,
        Header: 'Bowling',
        Cell: ({ row }: { row: Row<BowlingRow> }) => {
          return <Text fontSize="xl">{row.original.player.cardNameF}</Text>
        },
      },
      {
        accessor: (row: BowlingRow) => getBowlingOvers(row.performance),
        Header: 'O',
      },
      {
        accessor: (row: BowlingRow) => row.performance?.maidens,
        Header: 'M',
      },
      {
        accessor: (row: BowlingRow) => row.performance?.runs,
        Header: 'R',
      },
      {
        accessor: (row: BowlingRow) => row.performance?.wickets,
        Header: 'W',
      },
      ...(!condensed
        ? ([
            {
              accessor: (row: BowlingRow) => row.performance?.dots,
              Header: '\u2022',
            },
            {
              accessor: (row: BowlingRow) => getBowlingPerformanceEconomy(row.performance, ballsPerOver),
              Header: 'Econ',
            },
            {
              accessor: (row: BowlingRow) => row.performance?.extras?.wides,
              Header: 'Wd',
            },
            {
              accessor: (row: BowlingRow) => row.performance?.extras?.noBalls,
              Header: 'NB',
            },
          ] as const)
        : []),
    ],
    [condensed, ballsPerOver]
  )

  const data: BowlingRow[] = useMemo(() => {
    const bowlingTeamWithPerfs = orderBy(
      teamPlayers?.map(p => {
        const perf = bowlingPerformances.find(b => b.playerMp === p.id)
        return {
          player: p,
          performance: perf,
          first_performance_order: perf ? perf.order : null,
        }
      }),
      ['first_performance_order'],
      ['asc']
    )
    return filter(bowlingTeamWithPerfs, p => p.first_performance_order !== null)
  }, [bowlingPerformances, teamPlayers])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data })

  return (
    <Table {...getTableProps()}>
      <Thead>
        {headerGroups.map(headerGroup => (
          // eslint-disable-next-line react/jsx-key
          <Tr {...headerGroup.getHeaderGroupProps()} bg="primary.600">
            {headerGroup.headers.map((column, idx) => (
              // eslint-disable-next-line react/jsx-key
              <Th
                {...column.getHeaderProps()}
                py={3}
                px={2}
                borderBottom="none"
                color="primary.200"
                textAlign={idx === 0 ? 'left' : 'center'}
              >
                {column.render('Header')}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <React.Fragment key={row.id}>
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell, idx) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <Td
                      {...cell.getCellProps()}
                      p={4}
                      borderBottom="none"
                      textAlign={idx === 0 ? 'left' : 'center'}
                      w={condensed ? (idx === 0 ? '40%' : '15%') : idx === 0 ? '28%' : '9%'}
                    >
                      {cell.render('Cell')}
                    </Td>
                  )
                })}
              </Tr>
              <Tr borderBottom="1px solid" borderColor="primary.600">
                <Td p={0} borderBottom="none" colSpan={9}>
                  <BowlingSpell row={row.original} ballsPerOver={ballsPerOver} condensed={condensed} />
                </Td>
              </Tr>
            </React.Fragment>
          )
        })}
      </Tbody>
    </Table>
  )
}
