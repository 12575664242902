import {
  Button,
  Code,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  InputGroup,
  InputRightElement,
  Link,
  Select,
  Stack,
} from '@chakra-ui/react'
import type { GetMatchesResponse, MatchBase } from '@clsplus/api-types/api-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { InfiniteData, QueryClient } from '@tanstack/react-query'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import type { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Link as RouterLink, useLoaderData, useSearchParams } from 'react-router-dom'

import { competitionsRefDataQuery } from '../../../api/get-competitions-ref-data'
import { matchClassesRefDataQuery } from '../../../api/get-match-classes-ref-data'
import { matchFormatsRefDataQuery } from '../../../api/get-match-formats-ref-data'
import { matchesQuery } from '../../../api/get-matches'
import { organisationsRefDataQuery } from '../../../api/get-organisations-ref-data'
import { teamsRefDataQuery } from '../../../api/get-teams-ref-data'
import { ListPageFilters } from '../../../components/list-page-filters'
import { applyTimeZoneOffset, formatShortDate, subtractUserOffset } from '../../../helpers/datetime'
import { formatMatchDates } from '../../../helpers/match'
import type { ComboBoxOption } from '../../../ui/combo-box'
import { ComboBox } from '../../../ui/combo-box'
import { DatePicker } from '../../../ui/date-picker'
import { Input } from '../../../ui/input'
import { NonIdealState } from '../../../ui/non-ideal-state'
import { Table } from '../../../ui/table'

type MatchesFilters = {
  classId: string
  competitionId: string
  endDate: Date | null
  formatId: string
  gender: string
  id: string
  intId: string
  organisationId: string
  startDate: Date | null
  teamIds: string[]
  title: string
}

export const loader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const urlParams = Object.fromEntries(new URL(request.url).searchParams)
    const matchesQueryOpts = matchesQuery(urlParams)

    const [
      initialCompetitionsRefData,
      initialMatchClassesRefData,
      initialMatchFormatsRefData,
      initialOrganisationsRefData,
      initialTeamsRefData,
      initialMatchesData,
    ] = await Promise.all([
      queryClient.ensureQueryData(competitionsRefDataQuery),
      queryClient.ensureQueryData(matchClassesRefDataQuery),
      queryClient.ensureQueryData(matchFormatsRefDataQuery),
      queryClient.ensureQueryData(organisationsRefDataQuery),
      queryClient.ensureQueryData(teamsRefDataQuery),
      queryClient.getQueryData<InfiniteData<GetMatchesResponse>>(matchesQueryOpts.queryKey) ??
        (await queryClient.fetchInfiniteQuery(matchesQueryOpts)),
    ])

    return {
      initialCompetitionsRefData,
      initialMatchClassesRefData,
      initialMatchFormatsRefData,
      initialMatchesData,
      initialOrganisationsRefData,
      initialTeamsRefData,
    }
  }

export default function Matches() {
  const [searchParams, setSearchParams] = useSearchParams()

  const params = Object.fromEntries(searchParams)
  const paramsCount = Object.keys(params).length

  const {
    initialCompetitionsRefData,
    initialMatchClassesRefData,
    initialMatchFormatsRefData,
    initialMatchesData,
    initialOrganisationsRefData,
    initialTeamsRefData,
  } = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const { data: matches, ...matchesQueryResult } = useInfiniteQuery({
    ...matchesQuery(params),
    initialData: initialMatchesData,
  })

  const { data: organisationsRefData } = useQuery({
    ...organisationsRefDataQuery,
    initialData: initialOrganisationsRefData,
  })

  const { data: teamsRefData } = useQuery({
    ...teamsRefDataQuery,
    initialData: initialTeamsRefData,
  })

  const { data: competitionsRefData } = useQuery({
    ...competitionsRefDataQuery,
    initialData: initialCompetitionsRefData,
  })

  const { data: matchClassesRefData } = useQuery({
    ...matchClassesRefDataQuery,
    initialData: initialMatchClassesRefData,
  })

  const { data: matchFormatsRefData } = useQuery({
    ...matchFormatsRefDataQuery,
    initialData: initialMatchFormatsRefData,
  })

  const filtersFormDefaultValues = {
    classId: params.classId ?? '',
    competitionId: params.competitionId ?? '',
    endDate: params.endDate ? new Date(params.endDate) : null,
    formatId: params.formatId ?? '',
    gender: params.gender ?? '',
    id: params.id ?? '',
    intId: params.intId ?? '',
    organisationId: params.organisationId ?? '',
    startDate: params.startDate ? new Date(params.startDate) : null,
    teamIds: params.teamIds ? params.teamIds.split(',') : [],
    title: params.title ?? '',
  }

  const { control, register, handleSubmit, reset } = useForm<MatchesFilters>({
    values: filtersFormDefaultValues,
  })

  const handleDismissFilters = () => reset(filtersFormDefaultValues)

  const handleSubmitClick = (formData: MatchesFilters) => {
    const newSearchParams = Object.entries(formData).reduce<Record<string, string | string[]>>((acc, [key, val]) => {
      if (val instanceof Array) {
        return val.length ? { [key]: val.join(','), ...acc } : acc
      }

      if (val instanceof Date) {
        return { [key]: formatShortDate(val), ...acc }
      }

      return {
        ...(val && { [key]: val }),
        ...acc,
      }
    }, {})

    setSearchParams(newSearchParams)
  }

  const handleResetClick = () => {
    reset(filtersFormDefaultValues)
    setSearchParams({})
  }

  const rows = matches?.pages.flatMap(page => [...page.data])

  const columns: ColumnDef<MatchBase>[] = useMemo(
    () => [
      {
        accessorKey: 'teams',
        accessorFn: row =>
          row.teams
            ?.map(team => team.name)
            .sort()
            .join(' vs ') ?? 'TBC vs TBC',
        header: 'Teams',
        cell: ({ getValue, row }) => (
          <Link as={RouterLink} to={row.original.id}>
            {getValue<string>()}
          </Link>
        ),
      },
      {
        accessorKey: 'title',
        header: 'Title',
      },
      {
        accessorKey: 'competition',
        accessorFn: row => row.competition?.name,
        header: 'Competition',
      },
      {
        accessorKey: 'organisation',
        accessorFn: row => row.organisation?.name,
        header: 'Organisation',
      },
      {
        accessorKey: 'datesLocal',
        accessorFn: row => {
          if (!row.dates) return undefined

          return formatMatchDates(
            row.dates
              .map(date => ({
                ...date,
                startDateTime: applyTimeZoneOffset({
                  date: subtractUserOffset(date.startDateTime),
                  currentTimeZone: undefined,
                  newTimeZone: row.timeZone,
                }).toISOString(),
                endDateTime: date.endDateTime
                  ? applyTimeZoneOffset({
                      date: subtractUserOffset(date.endDateTime),
                      currentTimeZone: undefined,
                      newTimeZone: row.timeZone,
                    }).toISOString()
                  : null,
              }))
              .sort((a, b) => {
                if (a.startDateTime < b.startDateTime) return -1
                if (a.startDateTime > b.startDateTime) return 1
                return 0
              })
          )
        },
        header: 'Dates (local)',
      },
      {
        accessorKey: 'datesOperator',
        accessorFn: row =>
          row.dates
            ? formatMatchDates(
                row.dates.sort((a, b) => {
                  if (a.startDateTime < b.startDateTime) return -1
                  if (a.startDateTime > b.startDateTime) return 1
                  return 0
                })
              )
            : undefined,
        header: 'Dates (operator)',
      },
      {
        accessorKey: 'venue',
        accessorFn: row => row.venue?.name,
        header: 'Venue',
      },
      {
        accessorKey: 'class',
        accessorFn: row => row.class.name,
        header: 'Class',
      },
      {
        accessorKey: 'format',
        accessorFn: row => row.format.name,
        header: 'Format',
      },
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'intId',
        accessorFn: row => row.integrationIds?.SDS,
        header: 'SDS ID',
      },
      {
        accessorKey: 'gender',
        header: 'Gender',
      },
    ],
    []
  )

  const matchClassOptions = matchClassesRefData.map(matchClass => ({
    value: `${matchClass.id}`,
    label: matchClass.name,
  }))

  const matchFormatOptions = matchFormatsRefData.map(format => ({ value: `${format.id}`, label: format.name }))

  const competitionOptions = competitionsRefData.map(competition => ({
    value: competition.id,
    label: competition.name,
  }))

  const organisationOptions = organisationsRefData.map(organisation => ({
    value: organisation.id,
    label: organisation.name,
  }))

  return (
    <>
      <Heading as="h2" size="lg">
        Matches
      </Heading>
      <Stack w="100%" spacing={8} my={6}>
        <ListPageFilters
          formId="matches-filters"
          onDismiss={handleDismissFilters}
          onReset={handleResetClick}
          onSubmit={handleSubmit(handleSubmitClick)}
          title="Filter matches"
        >
          <FormControl>
            <FormLabel>Match ID</FormLabel>
            <Input {...register('id')} />
          </FormControl>
          <FormControl>
            <FormLabel>SDS ID</FormLabel>
            <Input {...register('intId')} />
          </FormControl>
          <FormControl>
            <FormLabel>Organisation</FormLabel>
            <Controller
              control={control}
              name="organisationId"
              render={({ field: { onChange, value } }) => (
                <ComboBox
                  isSingleSelect
                  options={organisationOptions}
                  onChange={onChange}
                  selectedOptions={organisationOptions.filter(option => option.value === value)}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Competition</FormLabel>
            <Controller
              control={control}
              name="competitionId"
              render={({ field: { onChange, value } }) => (
                <ComboBox
                  isSingleSelect
                  options={competitionOptions}
                  onChange={onChange}
                  selectedOptions={competitionOptions.filter(option => option.value === value)}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Teams</FormLabel>
            <Controller
              control={control}
              name="teamIds"
              render={({ field: { onChange, value } }) => (
                <ComboBox
                  maxSelectedItems={2}
                  options={teamsRefData.map(team => ({ value: team.id, label: team.name }))}
                  onChange={onChange}
                  selectedOptions={value
                    .map(teamId => {
                      const selectedTeam = teamsRefData.find(team => team.id === teamId)
                      if (selectedTeam) {
                        return { value: selectedTeam.id, label: selectedTeam.name }
                      }
                      return undefined
                    })
                    .filter((option): option is ComboBoxOption => !!option)}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Class</FormLabel>
            <Controller
              control={control}
              name="classId"
              render={({ field: { onChange, value } }) => (
                <ComboBox
                  isSingleSelect
                  options={matchClassOptions}
                  onChange={onChange}
                  selectedOptions={matchClassOptions.filter(option => option.value === value)}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Format</FormLabel>
            <Controller
              control={control}
              name="formatId"
              render={({ field: { onChange, value } }) => (
                <ComboBox
                  isSingleSelect
                  options={matchFormatOptions}
                  onChange={onChange}
                  selectedOptions={matchFormatOptions.filter(option => option.value === value)}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Start date</FormLabel>
            <Controller
              control={control}
              name="startDate"
              render={({ field: { onChange, value } }) => (
                <InputGroup zIndex={10}>
                  <Input as={DatePicker} selected={value} onChange={onChange} dateFormat="dd/MM/yyyy" />
                  <InputRightElement pointerEvents="none">
                    <Icon as={FontAwesomeIcon} icon={['fad', 'calendar-days']} color="primary.400" />
                  </InputRightElement>
                </InputGroup>
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel>End date</FormLabel>
            <Controller
              control={control}
              name="endDate"
              render={({ field: { onChange, value } }) => (
                <InputGroup zIndex={10}>
                  <Input as={DatePicker} selected={value} onChange={onChange} dateFormat="dd/MM/yyyy" />
                  <InputRightElement pointerEvents="none">
                    <Icon as={FontAwesomeIcon} icon={['fad', 'calendar-days']} color="primary.400" />
                  </InputRightElement>
                </InputGroup>
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Title</FormLabel>
            <Input {...register('title')} />
          </FormControl>
          <FormControl>
            <FormLabel>Gender</FormLabel>
            <Select {...register('gender')}>
              <option value="">-</option>
              <option value="FEMALE">Female</option>
              <option value="MALE">Male</option>
              <option value="MIXED">Mixed</option>
            </Select>
          </FormControl>
        </ListPageFilters>
        {matchesQueryResult.isError ? (
          <NonIdealState
            title="Error fetching matches"
            description="Here's what we know:"
            iconColor="red.600"
            icon={['fad', 'triangle-exclamation']}
          >
            <Code mt={4} px={6} py={4} borderRadius="6px" bg="primary.600">
              {matchesQueryResult.error instanceof Error
                ? matchesQueryResult.error.message
                : 'An unknown error occurred. Please contact a system administrator.'}
            </Code>
            <Button
              mt={4}
              variant="secondary"
              leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'xmark-large']} />}
              onClick={handleResetClick}
            >
              Reset filters
            </Button>
          </NonIdealState>
        ) : rows?.length ? (
          <Table columns={columns} data={rows} />
        ) : (
          <NonIdealState
            title="No matches found"
            description={
              paramsCount > 0 ? "We couldn't find any matches matching your filters" : "We couldn't find any matches"
            }
            iconColor="primary.400"
            icon={['fas', 'face-confused']}
          >
            {paramsCount > 0 && (
              <Button
                mt={4}
                variant="secondary"
                leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'xmark-large']} />}
                onClick={handleResetClick}
              >
                Reset filters
              </Button>
            )}
          </NonIdealState>
        )}
        {matchesQueryResult.isSuccess && matchesQueryResult.hasNextPage && (
          <Button
            mt={8}
            variant="primary"
            leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'ellipsis']} />}
            isLoading={matchesQueryResult.isFetchingNextPage}
            onClick={() => matchesQueryResult.fetchNextPage()}
          >
            Load more
          </Button>
        )}
      </Stack>
    </>
  )
}
