/* eslint-disable max-len */
import { Flex, Tag, Text } from '@chakra-ui/react'
import { isNil } from 'lodash'

import { textCleaner } from '../../helpers/data'
import { getActivePowerPlay, getPowerPlayShortName } from '../../helpers/inning'
import {
  formatMatchDates,
  getActiveInningBattingTeam,
  getActiveMatchBreak,
  getAwayTeam,
  getHomeTeam,
} from '../../helpers/match'
import { BreakTypes, InningsStatus, MatchResultType } from '../../reference'
import type { Match, MatchDls, MatchTeam } from '../../types/match'

type TeamProps = {
  team?: MatchTeam
  home?: boolean
  isBatting?: boolean
  maxOvers: number | null
  dls: MatchDls | null
}

const Team = ({ team, home = false, isBatting, maxOvers, dls }: TeamProps) => {
  return (
    <Flex
      flex={1}
      justifyContent="center"
      alignItems="center"
      direction="column"
      backgroundColor={isBatting ? 'green.400' : undefined}
      borderRadius={home ? '6px 0px 0px 6px' : '0px 6px 6px 0px'}
      py={2}
    >
      <Flex direction="column" justifyContent="center" mx="15%" w="70%" alignItems="center">
        <Text textStyle="h3" textAlign="center" mb={5}>
          {team?.name}
        </Text>
        {team &&
          team.innings.map((inning, idx) => {
            const activePowerPlay = getActivePowerPlay(inning)
            return (
              <Text key={inning.inningsMatchOrder} as="span" textStyle="h1" textAlign="center">
                {idx > 0 ? ' & ' : ''}
                {inning.progressiveScores.runs}/{inning.progressiveScores.wickets}
                {!isNil(maxOvers) &&
                  maxOvers !== 0 &&
                  parseInt(inning.progressiveScores.oversBowled || '0') !== maxOvers && (
                    <Text ml={2} as="span" textStyle="value" textAlign="center">
                      {InningsStatus[inning.inningsStatusId] === 'IN_PROGRESS' &&
                        `(${inning.progressiveScores.oversBowled})`}
                      {InningsStatus[inning.inningsStatusId] === 'IN_PROGRESS' && activePowerPlay && (
                        <Text ml={2} as="span" textStyle="value" textAlign="center">
                          {getPowerPlayShortName(activePowerPlay)}
                        </Text>
                      )}
                      {inning.superOver && (
                        <Text ml={2} as="span" textStyle="value" textAlign="center">
                          s.o
                        </Text>
                      )}
                    </Text>
                  )}
                {(isNil(maxOvers) || maxOvers === 0) && (
                  <Text ml={2} as="span" textStyle="value" textAlign="center">
                    {InningsStatus[inning.inningsStatusId] === 'IN_PROGRESS' &&
                      `(${inning.progressiveScores.oversBowled})`}
                    {inning.closeReasonId === 0 && (
                      <Text ml={2} as="span" textStyle="value" textAlign="center">
                        dec
                      </Text>
                    )}
                  </Text>
                )}
              </Text>
            )
          })}
        {dls?.targetScore && team && team.innings.length > 0 && team.innings[0].inningsMatchOrder === 2 && (
          <Flex flex={1} textAlign="center" alignItems="center" direction="column">
            <Tag size="lg">Target {dls.targetScore}</Tag>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

const MatchInfo = ({ game }: ScoreBoxProps) => {
  const gameBreak = getActiveMatchBreak(game)

  return (
    <Flex flex={1} justifyContent="center" alignItems="center" direction="column" px={5}>
      {gameBreak && (
        <Text textStyle="value" textAlign="center" mb={5}>
          {BreakTypes[gameBreak.matchBreakTypeId ?? 0]}
        </Text>
      )}
      {!isNil(game.matchResultTypeId) && (
        <>
          {!isNil(game.matchStatusId) &&
            !(game.matchStatusId === 0 || game.matchStatusId === 1 || game.matchStatusId === 2) && (
              <Text textStyle="value" textAlign="center">
                {game.description}
              </Text>
            )}
          <Text textStyle="value" textAlign="center" mb={5} fontSize="12px">
            {textCleaner(MatchResultType[game.matchResultTypeId])}
          </Text>
        </>
      )}
      <Text textStyle="boldLabel" textAlign="center" mb={5}>
        {game.competitionStage?.competition?.name || game.competitionPool?.name}
      </Text>
      <Text textStyle="boldLabel" textAlign="center">
        {game.venue?.fullName}
      </Text>
      <Text textStyle="boldLabel" textAlign="center">
        {formatMatchDates(game.matchDates)}
      </Text>
    </Flex>
  )
}

type ScoreBoxProps = {
  game: Match
}

export const ScoreBox = ({ game }: ScoreBoxProps) => {
  const homeTeam = getHomeTeam(game.matchTeams)
  const awayTeam = getAwayTeam(game.matchTeams)

  // only mark current batting team for a match that is In Progress
  const battingTeam = (game.matchStatusId || 0) <= 2 ? getActiveInningBattingTeam(game) : undefined

  return (
    <Flex
      flex={1}
      maxW="container.xl"
      width="100%"
      borderRadius="6px"
      backgroundColor="primary.600"
      minH="206px"
      mt={6}
    >
      <Team
        team={homeTeam}
        home={true}
        isBatting={homeTeam?.id === battingTeam?.id}
        maxOvers={game.matchConfigs?.maxOvers ?? null}
        dls={game.matchDls}
      />
      <MatchInfo game={game} />
      <Team
        team={awayTeam}
        isBatting={awayTeam?.id === battingTeam?.id}
        maxOvers={game.matchConfigs?.maxOvers ?? null}
        dls={game.matchDls}
      />
    </Flex>
  )
}
