import { Button, Flex, Heading, Icon, Stack, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { QueryClient } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Link as RouterLink, useLoaderData, useParams } from 'react-router-dom'

import { personDetailsQuery } from '../../../api/get-person-details'
import { DetailValue } from '../../../components/detail-value'
import { HasMCAccess, MCRequiredLevelEnum } from '../../../helpers/auth'
import { formatShortDate } from '../../../helpers/datetime'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    return await queryClient.ensureQueryData(personDetailsQuery(params.personId || ''))
  }

export default function PersonDetails() {
  const params = useParams()
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const { data: personDetails } = useQuery({
    ...personDetailsQuery(params.personId || ''),
    initialData,
  })

  return (
    <>
      <Flex w="100%" justifyContent="space-between" alignItems="baseline">
        <Heading as="h2" size="lg">
          {personDetails.name || `${personDetails?.firstName ?? ''} ${personDetails?.lastName ?? ''}`.trim()}
        </Heading>

        <HasMCAccess minLevel={MCRequiredLevelEnum['clsp-superadmin']}>
          <Button
            as={RouterLink}
            to={`/people/${personDetails.id}/edit`}
            variant="primary"
            minW={32}
            leftIcon={<Icon as={FontAwesomeIcon} icon={['fad', 'pen-to-square']} />}
          >
            Edit
          </Button>
        </HasMCAccess>
      </Flex>
      <Stack w="100%" mt={6} spacing={6}>
        <DetailValue label="CLS+ ID" value={personDetails.id} />
        <DetailValue label="First name" value={personDetails.firstName} />
        <DetailValue label="Middle name" value={personDetails.middleName} />
        <DetailValue label="Last name" value={personDetails.lastName} />
        <DetailValue label="Card name (full)" value={personDetails.cardNameF} />
        <DetailValue label="Card name (short)" value={personDetails.cardNameS} />
        <DetailValue label="SDS ID" value={personDetails.integrationIds.SDS} />
        <DetailValue label="Nationality" value={personDetails.nationality?.code} />
        <DetailValue
          label="Teams"
          value={personDetails.teams?.map(team => (
            <Text key={team.id}>{team.name}</Text>
          ))}
        />
        <DetailValue label="Gender" value={personDetails.gender} />
        <DetailValue
          label="Date of birth"
          value={personDetails.dateOfBirth && formatShortDate(new Date(personDetails.dateOfBirth))}
        />
        <DetailValue label="Batting hand" value={personDetails.battingHand?.name} />
        <DetailValue label="Bowling hand" value={personDetails.bowlingHand?.name} />
        <DetailValue label="Bowling type" value={personDetails.bowlingType?.name} />
        <DetailValue label="Primary throwing arm" value={personDetails.primaryThrowingArm?.name} />
      </Stack>
    </>
  )
}
