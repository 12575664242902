import type { VenueDetailed } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

function getVenueDetails(venueId: string): Promise<VenueDetailed> {
  const tokens = getTokens()

  return fetch(`${import.meta.env.VITE_API_URL}admin/venues/${venueId}`, {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const venueDetailsQuery = (venueId: string) => ({
  queryKey: ['venue', venueId],
  queryFn: () => getVenueDetails(venueId),
})
