import type { CompetitionDetailed } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

function getCompetitionDetails(competitionId: string): Promise<CompetitionDetailed> {
  const tokens = getTokens()

  return fetch(`${import.meta.env.VITE_API_URL}admin/competitions/${competitionId}`, {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const competitionDetailsQuery = (competitionId: string) => ({
  queryKey: ['competition', competitionId],
  queryFn: () => getCompetitionDetails(competitionId),
})
