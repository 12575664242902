import { Code } from '@chakra-ui/react'
import type { ReactNode } from 'react'

type CodeBlockProps = {
  children: ReactNode
}

export function CodeBlock({ children }: CodeBlockProps) {
  return (
    <Code as="pre" p={3} borderRadius="6px">
      {children}
    </Code>
  )
}
