import { Box, Heading, Stack, Text } from '@chakra-ui/react'
import { orderBy } from 'lodash'

import { textCleaner } from '../../helpers/data'
import { getAwayTeam, getHomeTeam } from '../../helpers/match'
import { getPlayerDisplayName } from '../../helpers/player'
import { getStaffFullName } from '../../helpers/staff'
import { MatchStaffRoles } from '../../reference'
import type { Match, MatchTeam } from '../../types/match'

type SquadsProps = {
  game: Match
}

export const Squads = ({ game }: SquadsProps) => {
  const homeTeam = getHomeTeam(game.matchTeams)
  const awayTeam = getAwayTeam(game.matchTeams)
  return (
    <Stack spacing={3}>
      <Heading as="h3" size="md">
        Squads
      </Heading>
      <Stack direction="row" spacing={8}>
        {homeTeam && <SquadList team={homeTeam} />}
        {awayTeam && <SquadList team={awayTeam} />}
      </Stack>
    </Stack>
  )
}

type SquadsListProps = {
  team?: MatchTeam
}

const SquadList = ({ team }: SquadsListProps) => {
  if (!team) return null

  return (
    <Stack flex={1} spacing={3} border="1px solid" borderColor="primary.600" borderRadius="6px" py={3} px={4}>
      <Text fontWeight="bold">{team.name}</Text>
      <Box>
        {orderBy(team.matchPlayers, ['selectionNumber'], ['asc']).map(player => (
          <Text key={player.id} textStyle="label" fontSize="18px" textTransform="uppercase" marginBottom="5px">
            {player.shirtNumber !== null && (
              <Text as="span" display="inline-block" width="40px" textAlign="center">
                {player.shirtNumber}
              </Text>
            )}
            {getPlayerDisplayName([team], player.id)} {player.wicketKeeper && '(WK)'} {player.captain && '(C)'}
          </Text>
        ))}
      </Box>
      {team.matchStaff && (
        <Box>
          {team.matchStaff.map(staff => (
            <Text key={staff.id} textStyle="boldLabel" fontSize="16px" textTransform="uppercase" marginBottom="5px">
              {getStaffFullName(staff)}{' '}
              <small>
                ({staff.matchTeamRoleId !== null ? textCleaner(MatchStaffRoles[staff.matchTeamRoleId]) : 'Unknown'})
              </small>
            </Text>
          ))}
        </Box>
      )}
    </Stack>
  )
}
