import { Button, Flex, Heading, Icon, Stack } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { QueryClient } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Link as RouterLink, useLoaderData, useParams } from 'react-router-dom'

import { teamDetailsQuery } from '../../../api/get-team-details'
import { DetailValue } from '../../../components/detail-value'
import { HasMCAccess, MCRequiredLevelEnum } from '../../../helpers/auth'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    return await queryClient.ensureQueryData(teamDetailsQuery(params.teamId || ''))
  }

export default function TeamDetails() {
  const params = useParams()
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const { data: teamDetails } = useQuery({
    ...teamDetailsQuery(params.teamId || ''),
    initialData,
  })

  return (
    <>
      <Flex w="100%" justifyContent="space-between" alignItems="baseline">
        <Heading as="h2" size="lg">
          {teamDetails.name}
        </Heading>

        <HasMCAccess minLevel={MCRequiredLevelEnum['clsp-superadmin']}>
          <Button
            as={RouterLink}
            to={`/teams/${teamDetails.id}/edit`}
            variant="primary"
            minW={32}
            leftIcon={<Icon as={FontAwesomeIcon} icon={['fad', 'pen-to-square']} />}
          >
            Edit
          </Button>
        </HasMCAccess>
      </Flex>
      <Stack w="100%" mt={6} spacing={6}>
        <DetailValue label="CLS+ ID" value={teamDetails.id} />
        <DetailValue label="SDS ID" value={teamDetails.integrationIds.SDS} />
        <DetailValue label="Organisation" value={teamDetails.organisation.name} />
        <DetailValue label="Short name" value={teamDetails.shortName} />
        <DetailValue label="Default match team name" value={teamDetails.defaultMatchTeamName} />
        <DetailValue label="Default match team short name" value={teamDetails.defaultMatchTeamShortName} />
        <DetailValue label="Description" value={teamDetails.description} />
      </Stack>
    </>
  )
}
