import { Box, Flex, Grid, Heading, Tag, TagLabel, Tooltip } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import type { MatchFeedsProps } from '../../types'

export const MatchFeeds = ({ subscribers }: MatchFeedsProps) => {
  return (
    <Box width="100%">
      <Heading as="h2" size="md" textAlign="center">
        Feeds
      </Heading>
      <Grid templateColumns="repeat(3, 1fr)" gap={2} mt={3}>
        {subscribers?.map(feed => (
          <Box w="100%" key={feed.subscriberId}>
            <Tooltip label={feed.feedTypes.join(', ')} aria-label="A tooltip" placement="top">
              <Box borderRadius="6px" bg="primary.700" p={2}>
                <Heading size="sm" as="h4" textAlign="center">
                  {feed.subscriberName}
                </Heading>
                <Tag
                  size="md"
                  borderRadius="full"
                  variant="solid"
                  colorScheme={feed.matchSubscribed ? 'green' : 'red'}
                  my={2}
                >
                  <Flex direction="row" alignItems="center">
                    <FontAwesomeIcon
                      icon={['fas', feed.matchSubscribed ? 'check-circle' : 'exclamation-circle']}
                      size="sm"
                      style={{ marginRight: '8px', fontSize: '12px' }}
                    />
                    <TagLabel>Match</TagLabel>
                  </Flex>
                </Tag>
                <Tag
                  size="md"
                  borderRadius="full"
                  variant="solid"
                  colorScheme={feed.compSubscribed ? 'green' : 'red'}
                  marginBottom="3px"
                >
                  <Flex direction="row" alignItems="center">
                    <FontAwesomeIcon
                      icon={['fas', feed.compSubscribed ? 'check-circle' : 'exclamation-circle']}
                      size="sm"
                      style={{ marginRight: '8px', fontSize: '12px' }}
                    />
                    <TagLabel>Competition</TagLabel>
                  </Flex>
                </Tag>
              </Box>
            </Tooltip>
          </Box>
        ))}
      </Grid>
    </Box>
  )
}
