import { Code } from '@chakra-ui/react'
import { useRouteError } from 'react-router-dom'

import type { NonIdealStateProps } from '../non-ideal-state'
import { NonIdealState } from '../non-ideal-state'

type ErrorBoundaryProps = Omit<NonIdealStateProps, 'title' | 'description' | 'iconColor' | 'icon'>

export const ErrorBoundary = ({ ...props }: ErrorBoundaryProps) => {
  const error = useRouteError() as Error

  // eslint-disable-next-line no-console
  console.error(error)

  return (
    <NonIdealState
      title="An unexpected error occurred"
      description="Here's what we know:"
      iconColor="red.600"
      icon={['fad', 'triangle-exclamation']}
      {...props}
    >
      <Code mt={4} px={6} py={4} borderRadius="6px" bg="primary.600" color="white">
        {error.message}
      </Code>
    </NonIdealState>
  )
}
