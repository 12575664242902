import 'react-datepicker/dist/react-datepicker.min.css'

import { ChakraProvider, ColorModeScript, createLocalStorageManager } from '@chakra-ui/react'
import { Global } from '@emotion/react'
import { createRoot } from 'react-dom/client'

import { App } from './App'
import { theme } from './theme'
import { IconLibrary } from './ui/icons'

// Load Font Awesome icon library
IconLibrary()

const colorModeManager = createLocalStorageManager('cls-plus-admin-chakra-ui-color-mode')

const container = document.getElementById('root')
if (!container) throw new Error('Failed to find the root element')

createRoot(container).render(
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} storageKey="cls-plus-admin-chakra-ui" />
    <ChakraProvider theme={theme} colorModeManager={colorModeManager}>
      <Global
        styles={{
          html: {
            backgroundColor: theme.colors.primary['800'],
          },
        }}
      />
      <App />
    </ChakraProvider>
  </>
)
