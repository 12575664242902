import { Button, Flex, Heading, Icon, Link, List, ListIcon, ListItem, Stack, Text } from '@chakra-ui/react'
import type { EntityReference } from '@clsplus/api-types/api-admin'
import { faLink } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { QueryClient } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Link as RouterLink, useLoaderData, useParams } from 'react-router-dom'

import { competitionPoolDetailsQuery } from '../../../../../api/get-competition-pool-details'
import { DetailValue } from '../../../../../components/detail-value'
import { HasMCAccess, MCRequiredLevelEnum } from '../../../../../helpers/auth'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    return await queryClient.ensureQueryData(
      competitionPoolDetailsQuery(params.competitionId || '', params.stageId || '', params.poolId || '')
    )
  }

export default function CompetitionStagePoolDetails() {
  const params = useParams()
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const { data: competitionStagePool } = useQuery({
    ...competitionPoolDetailsQuery(params.competitionId || '', params.stageId || '', params.poolId || ''),
    initialData,
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const generateStagePoolSubLinks = (items: EntityReference[] | undefined) => {
    const listItems = items?.map(subItem => {
      const itemContents = (
        <Link
          fontWeight={'normal'}
          as={RouterLink}
          to={`competitions/${competitionStagePool.competition.id}/team/${subItem.id}`}
        >
          {subItem.name}
        </Link>
      )
      return (
        <ListItem key={subItem.id}>
          <ListIcon as={FontAwesomeIcon} icon={faLink} color="gray.400" />
          {itemContents}
        </ListItem>
      )
    })
    return <List>{listItems}</List>
  }

  return (
    <>
      <Flex w="100%" justifyContent="space-between" alignItems="baseline">
        <Heading as="h2" size="lg">
          {competitionStagePool.name}
        </Heading>

        <HasMCAccess minLevel={MCRequiredLevelEnum['clsp-superadmin']}>
          <Button
            as={RouterLink}
            // eslint-disable-next-line max-len
            to={`/competitions/${competitionStagePool.competition.id}/stage/${competitionStagePool.competitionStage.id}/pool/${competitionStagePool.id}/edit`}
            variant="primary"
            minW={32}
            leftIcon={<Icon as={FontAwesomeIcon} icon={['fad', 'pen-to-square']} />}
          >
            Edit
          </Button>
        </HasMCAccess>
      </Flex>
      <Stack w="100%" mt={6} spacing={6}>
        <DetailValue label="CLS+ ID" value={competitionStagePool.competition.id} />
        <DetailValue label="SDS ID" value={competitionStagePool.integrationIds?.SDS} />
        <DetailValue
          label="Competition Teams in Pool"
          value={competitionStagePool.competitionTeams?.map(team => (
            <Text key={team.id}>{team.name}</Text>
          ))}
        />
        {/*TODO - if required: check with Liam if teams need to link to some sort of team  <DetailValue label="Teams" value={generateStagePoolSubLinks(competitionStagePool.competitionTeams)} /> */}
      </Stack>
    </>
  )
}
