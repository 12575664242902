import { PowerPlayTypes } from '../reference'
import type { Inning, InningNotes, MatchPlayer, MatchTeam, PowerPlay } from '../types/match'

export function getActivePowerPlay(inning: Inning) {
  return inning.powerPlays?.find(powerPlay => powerPlay.end === null)
}

export function getBatterPerformance(id: string | undefined, inning: Inning) {
  if (!id) return undefined
  return inning.battingPerformances.find(perf => perf.playerMp === id)
}

export function getInningsBowlingTeam(inning: Inning, matchTeams: MatchTeam[]) {
  return matchTeams?.find(team => team.id !== inning.battingTeamId)
}

export function getInningsBattingTeam(inning: Inning, matchTeams: MatchTeam[]) {
  return matchTeams?.find(team => team.id === inning.battingTeamId)
}

export function getInningsBattingTeamShortName(inning: Inning, matchTeams: MatchTeam[]) {
  return getInningsBattingTeam(inning, matchTeams)?.shortName
}

export function getPlayersInBattingOrder(players: MatchPlayer[] | undefined) {
  if (!players) return []

  return players.sort((a, b) => {
    if (a.selectionNumber === b.selectionNumber) return 0
    if (a.selectionNumber === null) return -1
    if (b.selectionNumber === null) return 1
    return a.selectionNumber - b.selectionNumber
  })
}

export function getInningNotesInOrder(inningNotes: InningNotes) {
  return inningNotes.notes.sort((a, b) => a.order - b.order)
}

export function getPowerPlayShortName(powerPlay: PowerPlay) {
  if (powerPlay.powerPlayTypeId === null) return 'PP'

  const powerPlayType = PowerPlayTypes[powerPlay.powerPlayTypeId]
  if (powerPlayType === 'POWER_PLAY_1') return 'PP1'
  if (powerPlayType === 'POWER_PLAY_2') return 'PP2'
  if (powerPlayType === 'POWER_PLAY_3') return 'PP3'

  return 'PP'
}

export function getCurrentBowlerPerformances(inning: Inning) {
  return inning.bowlingPerformances
    .filter(performance => performance.isBowling)
    .sort((a, b) => (b.onStrike === a.onStrike ? 0 : b.onStrike ? -1 : 1))
}

export function getCurrentBatterPerformances(inning: Inning) {
  return inning.battingPerformances.filter(performance => performance.notOut).sort((a, b) => a.order - b.order)
}

export function getTotalExtras(inning: Inning) {
  return (
    (inning.progressiveScores.byes || 0) +
    (inning.progressiveScores.legByes || 0) +
    (inning.progressiveScores.noBalls || 0) +
    (inning.progressiveScores.penaltyRuns || 0) +
    (inning.progressiveScores.wides || 0)
  )
}
