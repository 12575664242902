import { getTokens } from '../../helpers/auth'
import type { Match } from '../../types/match'

type GetMatchCentreMatchesParams = {
  startdate: string
  enddate: string
}

function getMatchCentreMatches(params: GetMatchCentreMatchesParams): Promise<Match[]> {
  const tokens = getTokens()
  const url = new URL(`${import.meta.env.VITE_API_URL}matches`)

  if (params.startdate) url.searchParams.append('startdate', params.startdate)
  if (params.enddate) url.searchParams.append('enddate', params.enddate)

  return fetch(url.href, {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const matchCentreMatchesQuery = (params: GetMatchCentreMatchesParams) => ({
  queryKey: ['match-centre-matches', params],
  queryFn: () => getMatchCentreMatches(params),
})
