import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  NumberInput,
  NumberInputField,
  Select,
  Stack,
  Switch,
} from '@chakra-ui/react'
import type { MatchDetailed, UpdateMatchRequest } from '@clsplus/api-types/api-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import type { QueryClient } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getTimeZones } from '@vvo/tzdb'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { useLoaderData, useNavigate, useParams, useRevalidator } from 'react-router-dom'
import * as z from 'zod'

import { matchDetailsQuery } from '../../../api/get-match-details'
import { venuesRefDataQuery } from '../../../api/get-venues-ref-data'
import { updateMatchDetails } from '../../../api/update-match-details'
import { addUserOffset, applyTimeZoneOffset, subtractUserOffset } from '../../../helpers/datetime'
import { CoverageLevels, TimeOfDay } from '../../../reference'
import type { ComboBoxOption } from '../../../ui/combo-box'
import { ComboBox } from '../../../ui/combo-box'
import { DatePicker } from '../../../ui/date-picker'
import { Input } from '../../../ui/input'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const [initialVenuesRefData, initialMatchDetails] = await Promise.all([
      queryClient.ensureQueryData(venuesRefDataQuery),
      queryClient.ensureQueryData(matchDetailsQuery(params.matchId || '')),
    ])

    return {
      initialMatchDetails,
      initialVenuesRefData,
    }
  }

const timeZoneOptions: ComboBoxOption[] = getTimeZones()
  .sort((a, b) => a.alternativeName.localeCompare(b.alternativeName))
  .map(timeZone => ({
    label: `${timeZone.alternativeName} (${timeZone.name})`,
    value: timeZone.name,
  }))

function mapMatchDatesToFormData(dates: MatchDetailed['dates'], timeZone?: string) {
  if (!dates) return []

  return dates
    .map(date => ({
      id: date.id,
      matchDayNum: date.matchDayNum,
      startDateTime: applyTimeZoneOffset({
        date: subtractUserOffset(date.startDateTime),
        currentTimeZone: undefined,
        newTimeZone: timeZone,
      }),
      endDateTime: date.endDateTime
        ? applyTimeZoneOffset({
            date: subtractUserOffset(date.endDateTime),
            currentTimeZone: undefined,
            newTimeZone: timeZone,
          })
        : null,
    }))
    .sort((a, b) => {
      if (a.startDateTime < b.startDateTime) return -1
      if (a.startDateTime > b.startDateTime) return 1
      return 0
    })
}

const matchDateSchema = z.object({
  endDateTime: z.nullable(z.date()),
  id: z.optional(z.string()),
  matchDayNum: z.preprocess(
    value => (value === '' ? null : Number(value)),
    z.number().min(1, { message: 'Match day must be 1 or greater' })
  ),
  startDateTime: z.date({
    required_error: 'Please enter a start date/time',
    invalid_type_error: 'Please enter a valid start date/time',
  }),
})

const matchEditSchema = z.object({
  dates: z.array(matchDateSchema),
  description: z.string(),
  matchNumber: z.nullable(z.number().min(0, { message: 'Match number must be 0 or greater' })),
  timeOfDay: z.preprocess(value => (value === '' ? null : Number(value)), z.nullable(z.number())),
  timeZone: z.string(),
  title: z.string(),
  venue: z.nullable(z.string()),
  config: z.object({
    ballsPerOver: z.nullable(z.number().min(1, { message: 'Balls per over must be 1 or greater' })),
    bowlerConsecutiveOvers: z.boolean(),
    coverageLevelOverrideId: z.preprocess(value => (value === '' ? undefined : Number(value)), z.number().optional()),
    maxBattingReviewsPerInnings: z.nullable(
      z.number().min(0, { message: 'Max batting reviews per innings must be 0 or greater' })
    ),
    maxBowlingReviewsPerInnings: z.nullable(
      z.number().min(0, { message: 'Max bowling reviews per innings must be 0 or greater' })
    ),
    maxOvers: z.nullable(z.number().min(0, { message: 'Max overs must be 0 or greater' })),
    noBallRuns: z.nullable(z.number().min(0, { message: 'No ball runs must be 0 or greater' })),
  }),
})

type MatchEditFormData = z.infer<typeof matchEditSchema>

export default function MatchEdit() {
  const params = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const revalidator = useRevalidator()
  const { initialMatchDetails, initialVenuesRefData } = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >

  const matchId = params.matchId || ''

  const { data: matchDetails } = useQuery({
    ...matchDetailsQuery(matchId),
    initialData: initialMatchDetails,
  })

  const { data: venuesRefData } = useQuery({
    ...venuesRefDataQuery,
    initialData: initialVenuesRefData,
  })

  const updateMatchMutation = useMutation({
    mutationFn: (formData: UpdateMatchRequest) => updateMatchDetails(matchId, formData),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['matches'] }),
        queryClient.invalidateQueries({ queryKey: ['match', matchId] }),
      ])
      revalidator.revalidate()
      navigate(`/matches/${matchId}`)
    },
  })

  const {
    control,
    getValues,
    register,
    handleSubmit,
    formState: { dirtyFields, errors, isDirty },
  } = useForm<MatchEditFormData>({
    defaultValues: {
      dates: mapMatchDatesToFormData(matchDetails.dates, matchDetails.timeZone),
      description: matchDetails.description ?? '',
      matchNumber: matchDetails.matchNumber ?? null,
      timeOfDay: matchDetails.timeOfDay?.id ?? null,
      timeZone: matchDetails.timeZone ?? '',
      title: matchDetails.title ?? '',
      venue: matchDetails.venue?.id ?? null,
      config: {
        ballsPerOver: matchDetails.config?.ballsPerOver ?? null,
        bowlerConsecutiveOvers: matchDetails.config?.bowlerConsecutiveOvers,
        coverageLevelOverrideId: matchDetails.config?.coverageLevelOverride?.id,
        maxBattingReviewsPerInnings: matchDetails.config?.maxBattingReviewsPerInnings ?? null,
        maxBowlingReviewsPerInnings: matchDetails.config?.maxBowlingReviewsPerInnings ?? null,
        maxOvers: matchDetails.config?.maxOvers ?? null,
        noBallRuns: matchDetails.config?.noBallRuns ?? null,
      },
    },
    resolver: zodResolver(matchEditSchema),
  })

  const {
    fields: matchDatesFields,
    append: appendMatchDatesField,
    remove: removeMatchDatesField,
    replace: replaceMatchDates,
  } = useFieldArray({
    control,
    name: 'dates',
  })

  const handleSubmitClick = (formData: MatchEditFormData) => {
    const changedFields: Partial<MatchEditFormData> = Object.fromEntries(
      Object.entries(formData).filter(([key]) => key in dirtyFields)
    )

    const { dates, ...requestData } = changedFields

    updateMatchMutation.mutate({
      ...('dates' in changedFields && {
        dates: dates?.map(({ endDateTime, startDateTime, ...date }) => ({
          ...date,
          // Convert dates back to UTC from the selected timezone
          startDateTime: applyTimeZoneOffset({
            date: addUserOffset(startDateTime),
            currentTimeZone: formData.timeZone,
            newTimeZone: undefined, // UTC
          }).toISOString(),
          endDateTime: endDateTime
            ? applyTimeZoneOffset({
                date: addUserOffset(endDateTime),
                currentTimeZone: formData.timeZone,
                newTimeZone: undefined, // UTC
              }).toISOString()
            : null,
        })),
      }),
      ...requestData,
    })
  }

  const selectedTimeZone = timeZoneOptions.find(option => option.value === getValues('timeZone'))
  const venuesOptions = venuesRefData.map(venue => ({ value: venue.id, label: venue.name }))

  return (
    <Flex direction="column" w="100%">
      <Heading as="h2" size="lg">
        {matchDetails.teams
          ?.map(team => team.name)
          .sort()
          .join(' vs ') ?? 'TBC vs TBC'}
      </Heading>
      <Stack
        as="form"
        noValidate
        id="match-edit"
        spacing={4}
        maxW="container.sm"
        mt={8}
        onSubmit={handleSubmit(handleSubmitClick)}
      >
        <FormControl isInvalid={!!errors.title}>
          <FormLabel>Title</FormLabel>
          <Input {...register('title')} />
          {errors.title && <FormErrorMessage>{errors.title.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.description}>
          <FormLabel>Description</FormLabel>
          <Input {...register('description')} />
          {errors.description && <FormErrorMessage>{errors.description.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.matchNumber}>
          <FormLabel>Match number</FormLabel>
          <Controller
            control={control}
            name="matchNumber"
            render={({ field: { onChange, value } }) => (
              <NumberInput
                min={0}
                maxW={36}
                onChange={value => onChange(value ? Number(value) : null)}
                value={value ?? ''}
              >
                <NumberInputField />
              </NumberInput>
            )}
          />
          {errors.matchNumber && <FormErrorMessage>{errors.matchNumber.message}</FormErrorMessage>}
        </FormControl>
        <FormControl>
          <FormLabel>Venue</FormLabel>
          <Controller
            control={control}
            name="venue"
            render={({ field: { onChange, value } }) => (
              <ComboBox
                isSingleSelect
                options={venuesOptions}
                onChange={value => onChange(value || null)}
                selectedOptions={venuesOptions.filter(option => option.value === value)}
              />
            )}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Time of day</FormLabel>
          <Select w={48} {...register('timeOfDay')}>
            <option value="">-</option>
            {Object.entries(TimeOfDay).map(([key, value]) => (
              <option key={key} value={`${value}`}>
                {key}
              </option>
            ))}
          </Select>
        </FormControl>
        <Heading size="md" pt={8}>
          Match dates
        </Heading>
        <FormControl>
          <FormLabel>Timezone</FormLabel>
          <Controller
            control={control}
            name="timeZone"
            render={({ field: { onChange, value: currentTimeZone } }) => (
              <ComboBox
                isSingleSelect
                options={timeZoneOptions}
                onChange={newTimeZone => {
                  replaceMatchDates(
                    getValues('dates').map(date => ({
                      ...date,
                      startDateTime: applyTimeZoneOffset({
                        date: date.startDateTime,
                        currentTimeZone,
                        newTimeZone,
                      }),
                      endDateTime: date.endDateTime
                        ? applyTimeZoneOffset({
                            date: date.endDateTime,
                            currentTimeZone,
                            newTimeZone,
                          })
                        : null,
                    }))
                  )
                  onChange(newTimeZone)
                }}
                selectedOptions={timeZoneOptions.filter(option => option.value === currentTimeZone)}
                suggestionLimit={15}
              />
            )}
          />
        </FormControl>
        <FormControl isInvalid={!!errors.dates}>
          <FormLabel>Match dates</FormLabel>
          <Stack spacing={4} mt={2}>
            {matchDatesFields.map((field, index) => (
              <Box key={field.id ?? `dates.${index}`}>
                <Input type="hidden" value={field.id} />
                <Stack direction="row" spacing={4}>
                  <FormControl isRequired isInvalid={!!errors.dates?.[index]?.matchDayNum} maxW={24}>
                    {index === 0 && <FormLabel fontSize="sm">Match day</FormLabel>}
                    <Controller
                      control={control}
                      name={`dates.${index}.matchDayNum`}
                      render={({ field: { onChange, value } }) => (
                        <NumberInput onChange={value => onChange(value ? Number(value) : null)} value={value ?? ''}>
                          <NumberInputField />
                        </NumberInput>
                      )}
                    />
                  </FormControl>
                  <FormControl isRequired isInvalid={!!errors.dates?.[index]?.startDateTime} flex={1}>
                    {index === 0 && <FormLabel fontSize="sm">Start date</FormLabel>}
                    <Controller
                      control={control}
                      name={`dates.${index}.startDateTime`}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputGroup isolation="auto">
                            <Input
                              as={DatePicker}
                              showTimeInput
                              selected={value}
                              onChange={onChange}
                              dateFormat="dd/MM/yyyy h:mm aa"
                            />
                            <InputRightElement pointerEvents="none">
                              <Icon as={FontAwesomeIcon} icon={['fad', 'calendar-days']} color="primary.400" />
                            </InputRightElement>
                          </InputGroup>
                        )
                      }}
                    />
                  </FormControl>
                  <FormControl isInvalid={!!errors.dates?.[index]?.endDateTime} flex={1}>
                    {index === 0 && <FormLabel fontSize="sm">End date</FormLabel>}
                    <Controller
                      control={control}
                      name={`dates.${index}.endDateTime`}
                      render={({ field: { onChange, value } }) => (
                        <InputGroup isolation="auto">
                          <Input
                            as={DatePicker}
                            showTimeInput
                            selected={value}
                            onChange={onChange}
                            dateFormat="dd/MM/yyyy h:mm aa"
                          />
                          <InputRightElement pointerEvents="none">
                            <Icon as={FontAwesomeIcon} icon={['fad', 'calendar-days']} color="primary.400" />
                          </InputRightElement>
                        </InputGroup>
                      )}
                    />
                  </FormControl>
                  <IconButton
                    variant="warning"
                    alignSelf="flex-end"
                    aria-label="Remove date"
                    icon={<Icon as={FontAwesomeIcon} icon={['fad', 'trash-xmark']} />}
                    onClick={() => removeMatchDatesField(index)}
                  />
                </Stack>
                {errors.dates?.[index]?.matchDayNum?.message && (
                  <FormErrorMessage>{errors.dates?.[index]?.matchDayNum?.message}</FormErrorMessage>
                )}
                {errors.dates?.[index]?.startDateTime?.message && (
                  <FormErrorMessage>{errors.dates?.[index]?.startDateTime?.message}</FormErrorMessage>
                )}
                {errors.dates?.[index]?.endDateTime?.message && (
                  <FormErrorMessage>{errors.dates?.[index]?.endDateTime?.message}</FormErrorMessage>
                )}
              </Box>
            ))}
          </Stack>
          <FormHelperText color="primary.400">All dates shown in {selectedTimeZone?.label || 'UTC'}</FormHelperText>
          <Button
            variant="secondary"
            size="sm"
            mt={4}
            leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'plus']} />}
            onClick={() =>
              appendMatchDatesField({
                matchDayNum: 1,
                startDateTime: new Date(),
                endDateTime: null,
              })
            }
          >
            Add date
          </Button>
        </FormControl>
        <Heading size="md" pt={8}>
          Match config
        </Heading>
        <FormControl>
          <FormLabel>Coverage level</FormLabel>
          <Select w={48} {...register('config.coverageLevelOverrideId')} isDisabled={matchDetails.wasLiveScored}>
            {!initialMatchDetails.config?.coverageLevelOverride && <option value="">-</option>}
            {Object.entries(CoverageLevels).map(([key, value]) => (
              <option key={key} value={`${value}`}>
                {key}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl isInvalid={!!errors.config?.ballsPerOver}>
          <FormLabel>Balls per over</FormLabel>
          <Controller
            control={control}
            name="config.ballsPerOver"
            render={({ field: { onChange, value } }) => (
              <NumberInput
                min={0}
                maxW={36}
                onChange={value => onChange(value ? Number(value) : null)}
                value={value ?? ''}
              >
                <NumberInputField />
              </NumberInput>
            )}
          />
          {errors.config?.ballsPerOver && <FormErrorMessage>{errors.config.ballsPerOver.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.config?.bowlerConsecutiveOvers}>
          <FormLabel>Bowler consecutive overs</FormLabel>
          <Switch variant="primary" {...register('config.bowlerConsecutiveOvers')} />
          {errors.config?.bowlerConsecutiveOvers && (
            <FormErrorMessage>{errors.config?.bowlerConsecutiveOvers.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors.config?.maxBattingReviewsPerInnings}>
          <FormLabel>Max batting reviews per innings</FormLabel>
          <Controller
            control={control}
            name="config.maxBattingReviewsPerInnings"
            render={({ field: { onChange, value } }) => (
              <NumberInput
                min={0}
                maxW={36}
                onChange={value => onChange(value ? Number(value) : null)}
                value={value ?? ''}
              >
                <NumberInputField />
              </NumberInput>
            )}
          />
          {errors.config?.maxBattingReviewsPerInnings && (
            <FormErrorMessage>{errors.config?.maxBattingReviewsPerInnings.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors.config?.maxBowlingReviewsPerInnings}>
          <FormLabel>Max bowling reviews per innings</FormLabel>
          <Controller
            control={control}
            name="config.maxBowlingReviewsPerInnings"
            render={({ field: { onChange, value } }) => (
              <NumberInput
                min={0}
                maxW={36}
                onChange={value => onChange(value ? Number(value) : null)}
                value={value ?? ''}
              >
                <NumberInputField />
              </NumberInput>
            )}
          />
          {errors.config?.maxBowlingReviewsPerInnings && (
            <FormErrorMessage>{errors.config?.maxBowlingReviewsPerInnings.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors.config?.maxOvers}>
          <FormLabel>Max overs</FormLabel>
          <Controller
            control={control}
            name="config.maxOvers"
            render={({ field: { onChange, value } }) => (
              <NumberInput
                min={0}
                maxW={36}
                onChange={value => onChange(value ? Number(value) : null)}
                value={value ?? ''}
              >
                <NumberInputField />
              </NumberInput>
            )}
          />
          {errors.config?.maxOvers && <FormErrorMessage>{errors.config?.maxOvers.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.config?.noBallRuns}>
          <FormLabel>No ball runs</FormLabel>
          <Controller
            control={control}
            name="config.noBallRuns"
            render={({ field: { onChange, value } }) => (
              <NumberInput
                min={0}
                maxW={36}
                onChange={value => onChange(value ? Number(value) : null)}
                value={value ?? ''}
              >
                <NumberInputField />
              </NumberInput>
            )}
          />
          {errors.config?.noBallRuns && <FormErrorMessage>{errors.config?.noBallRuns.message}</FormErrorMessage>}
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={4} mt={8}>
        <Button
          type="submit"
          form="match-edit"
          variant="primary"
          isDisabled={!isDirty}
          isLoading={updateMatchMutation.isLoading}
        >
          Save changes
        </Button>
        <Button
          variant="secondary"
          onClick={() => navigate(`/matches/${matchId}`)}
          isDisabled={updateMatchMutation.isLoading}
        >
          Cancel
        </Button>
      </Stack>
    </Flex>
  )
}
