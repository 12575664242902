import {
  Button,
  Flex,
  IconButton,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Tag,
  TagCloseButton,
  TagLabel,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCombobox, useMultipleSelection } from 'downshift'
import { useMemo, useState } from 'react'

import { Input } from '../input'

export type ComboBoxOption = {
  label: string
  value: string
}

type ComboBoxProps = (
  | {
      isSingleSelect: true
      maxSelectedItems?: never
      onChange: (value: string) => void
    }
  | {
      isSingleSelect?: never
      maxSelectedItems?: number
      onChange: (value: string[]) => void
    }
) & {
  options: ComboBoxOption[]
  selectedOptions: ComboBoxOption[]
  suggestionLimit?: number
}

export function ComboBox({
  isSingleSelect,
  maxSelectedItems = 0,
  onChange,
  options,
  selectedOptions,
  suggestionLimit = 10,
}: ComboBoxProps) {
  const [inputValue, setInputValue] = useState('')

  const items = useMemo(
    () =>
      options.filter(
        option =>
          !selectedOptions.some(val => val.value === option.label) &&
          option.label.toLowerCase().includes(inputValue.toLowerCase())
      ),
    [options, selectedOptions, inputValue]
  )

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } = useMultipleSelection({
    selectedItems: selectedOptions,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          if (isSingleSelect) {
            onChange(newSelectedItems?.[0]?.value ?? '')
          } else {
            onChange((newSelectedItems || []).map(item => item.value))
          }
          break
        default:
          break
      }
    },
  })

  const { isOpen, getMenuProps, getInputProps, getItemProps, highlightedIndex, closeMenu } = useCombobox({
    items,
    inputValue,
    selectedItem: null,
    itemToString: item => (item ? item.label : ''),
    stateReducer(_state, actionAndChanges) {
      const { changes, type } = actionAndChanges

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            ...(changes.selectedItem && { isOpen: true, highlightedIndex: 0 }),
          }
        default:
          return changes
      }
    },
    onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (newSelectedItem) {
            if (isSingleSelect) {
              onChange(newSelectedItem.value)
              setInputValue('')
              closeMenu()
              break
            }

            const newSelectedItems = [...selectedOptions, newSelectedItem].map(item => item.value)
            onChange(newSelectedItems)

            if (maxSelectedItems && newSelectedItems.length >= maxSelectedItems) {
              setInputValue('')
              closeMenu()
            }
          }
          break
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue ?? '')
          break
        default:
          break
      }
    },
  })

  return (
    <Flex direction="column" w="100%" pos="relative">
      {selectedOptions.length ? (
        <Flex alignItems="flex-start" minH={10}>
          <Flex flex={1} wrap="wrap" rowGap={2} mb={selectedOptions.length ? 2 : 0}>
            {selectedOptions.map(function renderSelectedItem(selectedItemForRender, index) {
              return (
                <Tag
                  key={`${selectedItemForRender.value}-tag`}
                  size="lg"
                  mr={2}
                  {...getSelectedItemProps({
                    selectedItem: selectedItemForRender,
                    index,
                  })}
                >
                  <TagLabel>{selectedItemForRender.label}</TagLabel>
                  <TagCloseButton
                    onClick={e => {
                      e.stopPropagation()
                      removeSelectedItem(selectedItemForRender)
                    }}
                  />
                </Tag>
              )
            })}
          </Flex>
          <Button
            size="sm"
            variant="link"
            aria-label="Reset selection"
            mt={1}
            color="blue.500"
            leftIcon={<FontAwesomeIcon icon={['fas', 'xmark-large']} size="sm" />}
            onClick={() => (isSingleSelect ? onChange('') : onChange([]))}
          >
            Clear
          </Button>
        </Flex>
      ) : null}
      <Flex
        alignItems="center"
        display={
          selectedOptions.length < maxSelectedItems || (isSingleSelect && !selectedOptions.length) ? 'flex' : 'none'
        }
      >
        <InputGroup>
          <Input
            {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
            placeholder="Start typing to search..."
          />
          <InputRightElement>
            {inputValue ? (
              <IconButton
                size="xs"
                variant="ghost"
                aria-label="Clear text input"
                icon={<FontAwesomeIcon icon={['fas', 'xmark-large']} />}
                onClick={() => setInputValue('')}
              />
            ) : null}
          </InputRightElement>
        </InputGroup>
      </Flex>
      <List
        pos="absolute"
        zIndex={20}
        top="calc(100% + 8px)"
        w="280px"
        py={2}
        border="1px solid"
        borderColor="primary.600"
        borderRadius="6px"
        bg="primary.700"
        visibility={isOpen ? 'visible' : 'hidden'}
        {...getMenuProps()}
      >
        {isOpen && items.length ? (
          items.slice(0, suggestionLimit).map((item, index) => (
            <ListItem
              key={`${item}${index}`}
              py={1.5}
              px={3}
              cursor="pointer"
              bg={highlightedIndex === index ? 'primary.600' : 'primary.700'}
              _hover={{
                bg: 'primary.600',
              }}
              {...getItemProps({ item, index })}
            >
              {item.label}
            </ListItem>
          ))
        ) : (
          <ListItem py={1.5} px={3} cursor="default" color="primary.400">
            No results
          </ListItem>
        )}
      </List>
    </Flex>
  )
}
